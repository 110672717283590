import Axios from 'axios'
import Cookies from 'universal-cookie'
import {
  CODING_ASSIGNMENT_TYPE,
  AUTHENTICATION_COOKIES,
  PATH,
  DOMAIN,
} from '../constants/constant'

import { DateRange } from '@mui/icons-material'

const cookies = new Cookies()
const configs = require('../config/configs.js')

var BASE_URL = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL = configs.routes.BASE_URL_DEVELOPMENT;
var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_TEST_SERVER
//var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_DEVELOPMENT_TEST_SERVER;
var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY
// var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY_TESTING;;
var TEST_SERVER_SECRET = configs.routes.TEST_SERVER_SECRET
// var TEST_SERVER_SECRET = configs.routes.TEST_SERVER_SECRET_TESTING

let expireOlderCookies = (name, paths) => {
  for (var i = 0, l = paths.length; i < l; i++) {
    cookies.remove(name, paths[i])
  }
}
let updateURLParameter = (url, param, paramVal) => {
  var newAdditionalURL = ''
  var tempArray = url.split('?')
  var baseURL = tempArray[0]
  var additionalURL = tempArray[1]
  var temp = ''
  if (additionalURL) {
    tempArray = additionalURL.split('&')
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i]
        temp = '&'
      }
    }
  }

  var tokn = temp + '' + param + '=' + paramVal
  return baseURL + '?' + newAdditionalURL + tokn
}
let removeAuthCookies = () => {
  console.log('\n:::::::: Removing Auth Cookies :::::::: ')
  for (let i = 0; i < AUTHENTICATION_COOKIES.length; i++) {
    cookies.remove(AUTHENTICATION_COOKIES[i], {
      path: PATH,
      domain: DOMAIN,
    })
    console.log(
      '\t Removed - Cookie : ',
      AUTHENTICATION_COOKIES[i],
      ', Domain : ',
      DOMAIN,
      ', Path : ',
      PATH
    )
  }
  console.log(':::::::: Removed Auth Cookies ::::::::\n')
}

export const renewAccessToken = (cb) => {
  console.log('g_at_w here::', cookies.get('at'))
  return new Promise((resolve, reject) => {
    let opts = {
      method: 'post',
      url: BASE_URL + '/account/g_at_w',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        a_id: cookies.get('aid'),
        d_id: window.fingerprint.md5hash,
        at: cookies.get('at'),
        cl: 'W',
        rt: cookies.get('rt'),
        k_ey: cookies.get('secret'),
      },
    }
    console.log({ opts })
    console.log({ opts: JSON.stringify(opts) })
    Axios(opts)
      .then((res) => {
        console.log({ data: res.data.data })
        var at = res.data?.data?.at

        if (at)
          cookies.set('at', at, {
            path: PATH,
            domain: DOMAIN,
          })
        return resolve(at)
      })
      .catch((err) => {
        console.error({ err })
        resolve(err)
        removeAuthCookies()
        window.location.reload()
        // return reject('')
      })
  })
}
export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    console.log(`logoutUser::::`)
    var data = {
      headers: {
        'Content-Type': 'application/json',
      },
      a_id: cookies.get('aid'),
      d_id: window.fingerprint.md5hash,
      at: cookies.get('at'),
      cl: 'W',
      rt: cookies.get('rt'),
      // k_ey	: utils.getSecret()
    }
    Axios.post(BASE_URL + '/auth/logout', data)
      .then((res) => {
        removeAuthCookies()
        return resolve()
        // window.location.reload()
      })
      .catch((err) => {
        console.log({ err })
        return reject(err)
      })
  })
}
Axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
export var FetchCourseChapter = (courseChapter = {}) => {
  return {
    type: 'FETCH_COURSE_CHAPTER',
    courseChapter,
  }
}

export var FetchValidHostIdZoom = (validHostId = {}) => {
  return {
    type: 'VALID_HOST_ID',
    validHostId,
  }
}

export var FetchAllCourseChapter = (coursechapters = {}) => {
  return {
    type: 'FETCH_ALL_COURSE_CHAPTER',
    coursechapters,
  }
}
export var FetchAllCourseElectiveLevels = (elective = {}) => {
  return {
    type: 'FETCH_ALL_COURSE_ELECTIVE_LEVELS',
    elective,
  }
}
export var FetchAllCourseContent = (courseContent = {}) => {
  return {
    type: 'FETCH_ALL_COURSE_CONTENT',
    courseContent,
  }
}
export var FetchLevelElecChapterMap = (levelElecChapterMap = {}) => {
  return {
    type: 'FETCH_LEVEL_ELEC_CHAPER_MAP',
    levelElecChapterMap,
  }
}

export var startFetchAdptCourseSubject = (data = {}) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/r_sbj',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // localStorage.setItem('gcode', data.data.group[0].code);
          cookies.set('gcode', data.data.group[0].code)
          dispatch(FetchCourseChapter(data.data.courseChapters))
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(data)
        // dispatch(errorFetchingData())
      })
  }
}

export var startFetchBatchCourse = (data = {}) => {
  console.log({ data })
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelCourse/r_li_btch',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      crs_pg_id: data.crs_id,
      limit: data.limit,
      skip: data.skip,
      p_id: cookies.get('pid'),
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        // localStorage.setItem('gcode', data.data.group[0].code);
        // cookies.set('gcode', data.group[0].code);
        //dispatch(FetchCourseChapter(data.data.courseChapters));
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(data)
      // dispatch(errorFetchingData())
    })
}

export var startFetchCourseChapter = (data = {}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/r_ch_v3'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: data.crs_pg_id,
        btch_id: data.btch_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchCourseChapter data!!', res.data)
          if (res.data.data.group[0]) {
            cookies.set('gcode', res.data.data.group[0].code)
          } else {
            window.alert(
              'It seems there is no group set for this batch. Please go back and do it.'
            )
          }
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var startFetchBatchDataView = (data = {}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/levelContent'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.btch_id,
        crs_pg_id: data.crs_pg_id,
        levelId: data.levelId || 'undefined',
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchBatchDataView data!!', res.data)
          if (res.data.data.group[0]) {
            cookies.set('gcode', res.data.data.group[0].code)
          } else {
            // window.alert(
            //   'It seems there is no group set for this batch. Please go back and do it.'
            // )
          }
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var startAddBatchElective = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/ad_elec'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        elec_level_map: data.elec_level_map,
        levelId: data.parentLevelId ? data.parentLevelId : undefined,
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startAddBatchElective data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var startFetchEnrolledUser = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/rd_enrl_stu'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchEnrolledUser data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var startAssignElectiveToStudent = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/ad_elec_stu'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        stu_elec_map: data.stu_elec_map || [],
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchBatchDataView data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var startRemoveElectiveToStudent = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/d_elec_stu'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        stu_elec_map: data.stu_elec_map || [],
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchBatchDataView data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var startEditElectiveToStudent = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/ed_elec_stu'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        stu_elec_map: data.stu_elec_map || [],
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchBatchDataView data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var startAssignElectiveToStudentInBulk = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL =
      BASE_URL +
      `/cfpanelBatch/ad_elec_stu_bulk?crs_id=${data.crs_id}&crs_pg_id=${data.crs_pg_id}`

    const AssignElectiveBulkUpload = new FormData()
    AssignElectiveBulkUpload.append('pid', cookies.get('pid'))
    AssignElectiveBulkUpload.append('aid', cookies.get('aid'))
    AssignElectiveBulkUpload.append('file', data.file)

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: AssignElectiveBulkUpload,
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchBatchDataView data!!', res.data)
          return resolve(res.data)
        } else {
          console.log('API call failed!', res.data)
          return resolve(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var startFetchAptvCourseChapter = (data = {}) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/r_ch_v2',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // localStorage.setItem('gcode', data.data.group[0].code);
          cookies.set('gcode', data.data.group[0].code)
          dispatch(FetchCourseChapter(data.data.courseChapters))
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(data)
        // dispatch(errorFetchingData())
      })
  }
}

export var startEditChapter = (data = {}) => {
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        name: data.name,
        desc: data.desc,
        etim: data.etim,
        preRequisite: data.preRequisite,
        tz: data.tz,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          window.location.reload()
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startEditLevel = ({ level_id, crs_id, name, desc, crs_pg_id }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/e_level'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, level_id, crs_id, name, desc, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startEditLevel data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var startDeleteLevel = ({ level_id, crs_id, crs_pg_id }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/del_level'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, level_id, crs_id, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startDeleteLevel data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var createLevel = ({
  crs_id,
  name,
  desc,
  parent_node_id,
  // node,
  seq,
  maxLevel,
  crs_pg_id,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/create_level'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        pid,
        crs_id: crs_id,
        name,
        desc,
        parent_node_id,
        // node,
        seq,
        maxLevel,
        crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('createLevel data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        // console.log(err)
        // console.log(err.response)
        // return resolve(err.response.data)
      })
  })
}

export var setLevelInfo = ({ cid, levels, crs_pg_id, elec_level_index }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/set_level_info'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, crs_id: cid, levels, crs_pg_id, elec_level_index },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startEditLevel data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var startDeleteChapter = (data = {}) => {
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          // window.location.reload();
          return Promise.resolve(data)
        } else {
          console.log('API call failed!!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddAdptSubject = (data = {}) => {
  console.log('In add dispatch', data)
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/a_sbj',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        name: data.name,
        desc: data.desc,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          // window.location.reload();
          return Promise.resolve()
        } else {
          console.log('API call failed', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddChapter = (data = {}) => {
  console.log('In add dispatch', data)
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        name: data.name,
        desc: data.desc,
        parent_node_id: data.parent_node_id,
        node: data.node,
        etim: data.etim,
        tz: data.tz,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          // window.location.reload();
          return Promise.resolve(data)
        } else {
          console.log('API call failed', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var ReorderLevel = (data = {}) => {
  console.log('reorder Levels', data)
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/e_ch_level?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        pid,
        crs_id: data.crs_id,
        levels: data.levels,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_new_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var startReorderChapter = (data = {}) => {
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_seq',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        n_idx: data.n_idx,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startFetchCourseChapterContent = (data = {}) => {
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/r_ch_cntnt',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course_content data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startFetchCourseChapterLiveContent = (data = {}) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/r_ch_liv_lec',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course_content data liv lec', data.data.livelecture)
          dispatch(FetchValidHostIdZoom(data.data.validHostId))
          return Promise.resolve(data.data.livelecture)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var startFetchGroupCourse = () => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelGeneric/r_grp_crs',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startFetchGroupCourse  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startFetchGroupAdptCourse = () => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/r_grp_crs',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startFetchGroupAdptCourse  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddPluginsToCourse = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_crs_plg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: data.crs_pg_id,
        crs_id: data.crs_id,
        plid_v: data.plid_v,
        plid_q: data.plid_q,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Plugins added!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var startDeleteCourseFeature = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_cr_ft',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Feature Deletion!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var startCreateGraduationCriteria = (data) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelBatch/a_grd',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      crs_id: data.crs_id,
      crs_pg_id: data.crs_pg_id,
      d_id: data.d_id,
      content_type_map: data.content_type_map,
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('Course Graduation data!', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var startDeleteGraduationCriteria = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_grd',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        d_id: data.d_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Graduation Deletion!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var startFetchGraduationCriteria = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/r_grad',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Graduation data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}
export var AddPlacemnetCriteria = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/set_plc_cri',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        grdPlacCatg: data.grdPlacCatg,
        placementEnable: data.placementEnable,
        showLrnPlcStatus: data.showLrnPlcStatus,
        showDefaultPlcStatus: data.showDefaultPlcStatus,
        plcStatusDescForYes: data.showLrnPlcStatus
          ? data.plsStatusDescForYes
          : 'Congratulations! You are eligible for Placement Criteria',
        plsStatusDescForNo: data.showLrnPlcStatus
          ? data.plsStatusDescForNo
          : 'You are not eligible for the Placement Criteria',
        plcPassStatusText: data.plcPassStatusText
          ? data.plcPassStatusText
          : 'PASS',
        plcFailStatusText: data.plcFailStatusText
          ? data.plcFailStatusText
          : 'FAIL',
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course grades Weight data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var fetchSkillTags = (crs_pg_id, cid) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelCourse/r_skilltag',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      crs_pg_id: crs_pg_id,
      cid: cid,
      aid: cookies.get('aid'),
      pid: cookies.get('pid'),
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('Course grades Weight data!!', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var startFetchGradesWeight = (data) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelBatch/r_asgnmnt_quiz_weight',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      crs_id: data.cid,
      crs_pg_id: data.crs_pg_id,
      aid: cookies.get('aid'),
      pid: cookies.get('pid'),
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('Course grades Weight data!!', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}
export var startAddGradebookCategories = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/ad_grd_catg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        grdPlacCatg: data.grdPlacCatg,
        enableCategory: data.enabelCategory,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course grades category data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var setPublishStatus = (data) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelBatch/publish_asgnmnt_quiz',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      crs_id: data.cid,
      crs_pg_id: data.crs_pg_id,
      did: data.did,
      type: data.type,
      aid: cookies.get('aid'),
      pid: cookies.get('pid'),
      publish: data.status,
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('Course grades Publish Status data!!', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var setCertiPublishStatus = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/publish_certi',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        publish: data.status,
        elective_id: data.elective_id,
        is_elec: data.is_elec,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Certi Publish Status data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var setCoursePace = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/manage_course_gradebook_settings',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        gradebook_auto_publish: data.pace,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        visibility: data.visibility,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Pace type data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var setCourseCertiAutoPublish = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/set_certi_auto_publish',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        certi_auto_publish: data.certi_auto_publish,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Certi auto-publish data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var editGradesWeight = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/set_weight',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        // assignments: data.assignments,
        // quizzes: data.quizzes,
        contentByElec: data.contentByElec || {},
        gradbookType: data.gradbookType,
        showWeightage: data.showWeightage,
        showPassMarks: data.showPassMarks,
        totalWeightMap: data.totalWeightMap,
        enableCategory: data.enableCategory,
        skill_tag_enabled: data.skill_tag_enabled,
        showContentStatus: data.showContentStatus,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course grades Weight data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var startFetchTiers = (data) => {
  console.log('inside action:', data)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/gt_tiers',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        a_id: cookies.get('aid'),
        p_id: cookies.get('pid'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Course Tiers data!!', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var saveTiers = (data) => {
  console.log('inside action:', data)
  let status
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/set_tiers',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        tiers: data.tiers,
        certi_auto_publish: data.certi_auto_publish,
        certiStatus: data.certiStatus,
        certi_ques: data.certi_ques,
        tierEnabled: data.tierEnabled,
        referEarnStatus: data.referEarnStatus,
        referral_nudges: data.referral_nudges,
      },
    })
      .then((res) => {
        // console.log('res of set tiers call')
        // console.log({res: res})
        // return {...res.json(), status: res.status}
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('Course tiers data!!', data)
          return Promise.resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return Promise.resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}

export var saveCertiTemplates = (data) => {
  console.log('inside action:', data)
  let status
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/set_certi_temp',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
        links: data.links,
      },
    })
      .then((res) => {
        // console.log('res of set tiers call')
        // console.log({res: res})
        // return {...res.json(), status: res.status}
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('saveCertiTemplates data!!', data)
          return Promise.resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return Promise.resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}
export var getCertiTemplates = (data) => {
  console.log('inside action:', data)
  let status
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/get_certi_temp',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.cid,
        crs_pg_id: data.crs_pg_id,
        aid: cookies.get('aid'),
        pid: cookies.get('pid'),
      },
    })
      .then((res) => {
        // console.log('res of set tiers call')
        // console.log({res: res})
        // return {...res.json(), status: res.status}
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('getCertiTemplates data!!', data)
          return Promise.resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return Promise.resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}
export var uploadStudentData = ({
  crs_pg_id,
  cid,
  file,
  name,
  finalCourseAccessDate,
  links,
}) => {
  let status
  // const studentImportdata = new FormData()
  // studentImportdata.append('file', file)
  // studentImportdata.append('name', name)
  // studentImportdata.append('cid', cid)
  // studentImportdata.append('crs_pg_id', crs_pg_id)
  // studentImportdata.append('aid', cookies.get('aid'))
  console.log('final course access date is ', finalCourseAccessDate)
  const studentImportdata = new FormData()
  studentImportdata.append('name', name)
  studentImportdata.append('finalCourseAccessDate', finalCourseAccessDate)
  if (cookies.get('aid')) studentImportdata.append('aid', cookies.get('aid'))
  studentImportdata.append('file', file)
  return Axios({
    method: 'POST',
    url:
      BASE_URL +
      `/cfpanelBatch/createImportJob?crs_id=${cid}&crs_pg_id=${crs_pg_id}`,
    headers: {
      //   "Content-Type": "multipart/form-data",
      'x-access-token': cookies.get('at'),
    },
    data: studentImportdata,
  })
    .then((res) => {
      status = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('uploadStudentData data!!', { ...data, status })
        return Promise.resolve({ ...data, status })
      } else {
        console.log('API call failed!', { ...data, status })
        return Promise.resolve({ ...data, status })
      }
    })
    .catch((err) => {
      return Promise.reject(err.response.data)
    })
}

export var uploadStudentDataSingle = ({
  crs_pg_id,
  cid,
  data,
  finalCourseAccessDate,
}) => {
  let status
  const singleStudentImportdata = {
    crs_id: cid,
    crs_pg_id: crs_pg_id,
    aid: cookies.get('aid'),
    data: data,
    finalCourseAccessDate: finalCourseAccessDate,
  }
  console.log(singleStudentImportdata)
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelBatch/createImportJobSingle',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: singleStudentImportdata,
  })
    .then((res) => {
      status = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('uploadSingleStudentData data!!', { ...data, status })
        return Promise.resolve({ ...data, status })
      } else {
        console.log('API call failed!', { ...data, status })
        return Promise.resolve({ ...data, status })
      }
    })
    .catch((err) => {
      return Promise.reject(err.response.data)
    })
}

export var getIncompleteRequests = (size, delta) => {
  let status
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/get_created_import_requests',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid: cookies.get('aid'),
      size: size,
      delta: delta,
    },
  })
    .then((res) => {
      status = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('get_created_import_requests data!!', data)
        return Promise.resolve({ ...data, status })
      } else {
        console.log('API call failed!', data)
        return Promise.resolve({ ...data, status })
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var getRequestsForApprover = (size, delta) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelGeneric/get_import_requests_for_approver',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid: cookies.get('aid'),
        size: size,
        delta: delta,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('get_import_requests_for_approver data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var getUserDataByRequest = (id) => {
  let status
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/get_import_request_userdata',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid: cookies.get('aid'),
      id,
    },
  })
    .then((res) => {
      status = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('get_import_request_userdata data!!', data)
        return Promise.resolve({ ...data, status })
      } else {
        console.log('API call failed!', data)
        return Promise.resolve({ ...data, status })
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}
export var sendImportRequestForApproval = ({ id, status }) => {
  let statusCode
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/set_request_for_approval',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid: cookies.get('aid'),
      id,
      status,
    },
  })
    .then((res) => {
      statusCode = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('sendImportRequestForApproval data!!', data)
        return Promise.resolve({ ...data, status: statusCode })
      } else {
        console.log('API call failed!', data)
        return Promise.resolve({ ...data, status: statusCode })
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var approveRequestAndImport = ({ id }) => {
  let statusCode
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/approve_request_and_import',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid: cookies.get('aid'),
      req_id: id,
    },
  })
    .then((res) => {
      statusCode = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('approveRequestAndImport data!!', data)
        return Promise.resolve({ ...data, status: statusCode })
      } else {
        console.log('API call failed!', data)
        return Promise.resolve({ ...data, status: statusCode })
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}
export var rejectRequest = ({ id }) => {
  let statusCode
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/reject_import_request',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid: cookies.get('aid'),
      id,
    },
  })
    .then((res) => {
      statusCode = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('rejectRequest data!!', data)
        return Promise.resolve({ ...data, status: statusCode })
      } else {
        console.log('API call failed!', data)
        return Promise.resolve({ ...data, status: statusCode })
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
    })
}

export var unSubscribeUser = (aid, cid, crs_pg_id) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/un_subscribe_user',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        aid,
        crs_id: cid,
        crs_pg_id,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('un_subscribe_user data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var unSubscribeAllUsers = (param, cid, crs_pg_id) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/bulk_unsubscribe_user',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        subUsersArray: param,
        crs_id: cid,
        crs_pg_id,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('un_subscribe_user data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var bulkExtendUsers = (
  subArray,
  cid,
  crs_pg_id,
  formdata,
  maxValidDate
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/bulk_extend_user_subscription',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        subUsersArray: subArray,
        crs_id: cid,
        crs_pg_id,
        formdata,
        maxValidDate,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('un_subscribe_user data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var bulkResubscribeUsers = (
  unsubArray,
  cid,
  crs_pg_id,
  formdata,
  maxValidDate
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url:
        BASE_URL + '/cfpanelBatch/bulk_extend_unsubscribed_user_subscription',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        unsubUsersArray: unsubArray,
        crs_id: cid,
        crs_pg_id,
        formdata,
        maxValidDate,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('un_subscribe_user data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var deleteUserCertificate = (
  aid,
  cid,
  crs_pg_id,
  elec_id,
  isElec = false
) => {
  let status
  return new Promise((resolve, reject) => {
    let statusCode
    let data = {
      aid,
      crs_id: cid,
      crs_pg_id,
      is_elec: isElec,
    }
    if (isElec) {
      data['elec_id'] = elec_id
    }
    return Axios.post(`${BASE_URL}/cfpanelBatch/del_user_certi`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('del_user_certi data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('del_user_certi API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  })
}

export var checkBatchCreationPermission = (crs_pg_id, loc_id) => {
  return new Promise((resolve, reject) => {
    let statusCode
    return Axios.post(
      `${BASE_URL}/cfpanelCourse/crt_btch_permission`,
      {
        crs_pg_id,
        loc_id,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
      }
    )
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  })
}

export var editLaunchDate = (course) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/edit_launch_date',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid: cookies.get('aid'),
        course,
        crs_pg_id: course.crs_pg_id,
        crs_id: course._id,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('un_subscribe_user data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}
export var editCourseCode = (crs_pg_id, code) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelCourse/set_code_coursepage',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid: cookies.get('aid'),
        crs_pg_id,
        code,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('editCourseCode data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var editCourseAccessBuffer = (crs_pg_id, acc_buffer) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelCourse/set_coursebuffer_coursepage',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid: cookies.get('aid'),
        crs_pg_id,
        acc_buffer,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('editCourseAccessBuffer data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var editCourseDuration = (crs_pg_id, d_unit, d_base) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelCourse/edit_courseduration_coursepage',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid: cookies.get('aid'),
        crs_pg_id,
        d_unit,
        d_base,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('editCourseDuration data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var startCreateCoursePlugin = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_cr',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: data.crs_pg_id,
        crs_id: data.crs_id,
        g_id: data.g_id,
        // p_id: localStorage.getItem('pid')
        p_id: cookies.get('pid'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startCreateCoursePlugin  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startMakeActive = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/act_btch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.btch_id,
        crs_pg_id: data.crs_pg_id,
        //p_id      : cookies.get('pid'),
        //aid         : cookies.get('aid')
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startMakeActive  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddBatch = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_btch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        btch_name: data.btch_name,
        btch_desc: data.btch_desc,
        g_id: data.btch_grp,
        crs_pg_id: data.crs_pg_id,
        trgt_btch_id: data.trgt_btch_id,
        mk_it_act: data.mk_it_act,
        l_date_n: data.l_date_n,
        l_date: data.l_date,
        loc_id: data.loc_id,
        p_id: cookies.get('pid'),
        crs_id: data.trgt_btch_id,
        if_schdl_enbld: data.if_schdl_enbld,
        if_lg_add_livelec: data.if_lg_add_livelec,
        if_shw_attndnce: data.if_shw_attndnce,
        livelec_prsnt_thrshhold: data.livelec_prsnt_thrshhold
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startAddBatch  data', data)
        } else {
          console.log('API call failed!', data)
        }
        return Promise.resolve(data)
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var getBatchesData = (crs_pg_id) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/ls_btch'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        a_id: cookies.get('aid'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getBatchesData data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export const getAllSuperAdmins = async () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_all_super_admins?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query = 'aid=' + aid + '&pid=' + pid
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllSuperAdmins data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getAllPossibleLocations = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_all_course_locations?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query+= 'crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_all_course_locations data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var saveCourseLocation = (location, cid, crs_pg_id) => {
  console.log({ location, cid })
  return new Promise(async (resolve, reject) => {
    if (location) {
      var loc_id = location._id
    }
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/set_course_location?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { loc_id, crs_id: cid, aid, pid, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var startCourseReplicate = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/repl_btch',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: data.crs_pg_id,
        trgt_btch_id: data.trgt_btch_id,
        crs_id: data.crs_id,
        pid: cookies.get('pid'),
        aid: cookies.get('aid'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startCourseReplicate  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startCreateCourseAdptPlugin = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/a_cr',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        g_id: data.g_id,
        // p_id: localStorage.getItem('pid')
        p_id: cookies.get('pid'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startCreateCourseAdptPlugin  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startCreatePlugin = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelGeneric/cr',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        g_id: data.g_id,
        // p_id: localStorage.getItem('pid'),
        p_id: cookies.get('pid'),
        nm: data.type == 2 ? 'Quiz plugin' : 'Video Plugin',
        dsc:
          data.type == 2
            ? 'Quiz plugin description'
            : 'Video Plugin description',
        typ: data.type,
        t_url: '',
        s_url: '',
        p_url: '',
        pd: '',
        pkg: '',
        // tokn: localStorage.getItem('at')
        tokn: cookies.get('at'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startCreatePlugin  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startActivatePlugin = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanel/act_plg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        g_id: data.g_id,
        plid_v: data.plid_v,
        plid_q: data.plid_q,
        crs_pg_id: data.crs_pg_id,
        crs_id: data.crs_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Activation data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startFetchCourse = (category = false) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/r_cr',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'x-access-token': localStorage.getItem('at')
      'x-access-token': cookies.get('at'),
      // 'x-access-token': "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjViMDZhMTBlOTNiMDI3YTcwMzg2MDAyZiIsImNsIjoiVyIsImhjb2RlIjo2NTM0NTg5NzEzNDExODY3LCJpYXQiOjE1Mjk0MTU5NTEsImV4cCI6MTUyOTYzMTk1MX0.L7GAdc4e_WrgDCZr1mU8aVLxBYUs0CeBvs-R2mjimSI"
    },
    data: {
      category,
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('course data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
      // dispatch(errorFetchingData())
    })
}
export var startFetchCourseAll = () => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelGeneric/r_cr_all',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'x-access-token': localStorage.getItem('at')
      'x-access-token': cookies.get('at'),
      // 'x-access-token': "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjViMDZhMTBlOTNiMDI3YTcwMzg2MDAyZiIsImNsIjoiVyIsImhjb2RlIjo2NTM0NTg5NzEzNDExODY3LCJpYXQiOjE1Mjk0MTU5NTEsImV4cCI6MTUyOTYzMTk1MX0.L7GAdc4e_WrgDCZr1mU8aVLxBYUs0CeBvs-R2mjimSI"
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('course data all', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
      // dispatch(errorFetchingData())
    })
}

export var startFetchAdptCourse = () => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/adptcfpanel/r_cr',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
        // 'x-access-token': "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjViMDZhMTBlOTNiMDI3YTcwMzg2MDAyZiIsImNsIjoiVyIsImhjb2RlIjo2NTM0NTg5NzEzNDExODY3LCJpYXQiOjE1Mjk0MTU5NTEsImV4cCI6MTUyOTYzMTk1MX0.L7GAdc4e_WrgDCZr1mU8aVLxBYUs0CeBvs-R2mjimSI"
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddCourseExtra = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_cr_extra',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        typ: data.typ,
        nm: data.nm,
        dsc: data.dsc,
        url: data.url,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          return Promise.resolve(data)
        } else {
          console.log(data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var startDeleteLecture = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch_lc',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        lc_id: data.lc_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startDeleteLecture  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startDeleteLiveLecture = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch_liv_lc',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        lc_id: data.lc_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('startDeleteLiveLecture  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var fetchQuiz = (data) => {
  console.log("IN fetch quiz : ", data)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL_TEST_SERVER + '/api/group_data',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        g_id: data.g_id,
        key: TEST_SERVER_KEY,
        secret: TEST_SERVER_SECRET,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          let result = []
          if (data.data) {
            result = data.data
          }
          console.log('returning ::', result)
          return Promise.resolve(result)
        } else {
          return Promise.reject(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var fetchQuizData = async (data) => {
  console.log("IN fetch quiz : ", data)
  try {
    let response = await Axios({
      method: 'POST',
      url: BASE_URL_TEST_SERVER + '/api/group_data',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        g_id: data.g_id,
        key: TEST_SERVER_KEY,
        secret: TEST_SERVER_SECRET,
      },
    })
    return response
  } catch (err){
    console.log("Error :: ", err)
    return false
  }
}

export var fetchAdmissionQuiz = (data) => {
  return Axios({
    method: 'POST',
    url: BASE_URL_TEST_SERVER + '/api/admission_group_data',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      g_id: data.g_id,
      blockId: data.blockId,
      key: TEST_SERVER_KEY,
      secret: TEST_SERVER_SECRET,
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        let result = []
        if (data.data) {
          result = data.data
        }
        console.log('returning ::', result)
        return Promise.resolve(result)
      } else {
        return Promise.reject(data)
      }
    })
    .catch((e) => {
      console.log(e)
    })
}

export var addQuizToChap = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch_qz',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        name: data.name,
        desc: data.desc,
        test_id: data.test_id,
        etim: data.etim,
        isLocked: data.isLocked,
        tz: data.tz,
        skillTags: data.skillTags,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          return Promise.resolve(data)
        } else {
          console.log(data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var editQuizToChap = (data) => {
  console.log({ data })
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_qz',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        qz_id: data.qz_id,
        name: data.name,
        desc: data.desc,
        test_id: data.test_id,
        etim: data.etim,
        isLocked: data.isLocked,
        tz: data.tz,
        skillTags: data.skillTags,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          return Promise.resolve(data)
        } else {
          console.log(data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var addFeedbackToChap = (data) => {
  console.log('data.scheduleType', data.scheduleType)
  if (data.scheduleType == 2) {
    data.tim = new Date().getTime()
  }
  return (dispatch) => {
    console.log('-----------------data----------------', data)
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch_fdbk',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        name: data.name,
        desc: data.desc,
        test_id: data.test_id,
        tim: data.tim,
        fd_nm: data.fd_nm,
        publishType: data.publishType,
        dism: data.dism,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          return Promise.resolve(data)
        } else {
          console.log(data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var startReorderChapterContent = (data = {}) => {
  return (dispatch) => {
    //  dispatch(startFetchingData());
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_cntnt_seq',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        f_id: data.f_id,
        n_idx: data.n_idx,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('course data', data)
          return Promise.resolve(data)
        } else {
          console.log('bad', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
        return Promise.reject('error')
      })
  }
}

export var startAddVideo = (data) => {
  var obj = {
    crs_id: data.crs_id,
    crs_pg_id: data.crs_pg_id,
    ch_id: data.ch_id,
    name: data.name,
    desc: data.desc,
    url: data.url,
    vimeoFile: data.vimeoFile,
    videotype: data.videotype,
    feedback_obj: data.feedback_obj,
    feedbackEnable: data.feedbackEnable,

    etim: data.etim,
    isLocked: data.isLocked,
    tz: data.tz,
  }

  const formdata = new FormData()
  formdata.append('ch_id', data.ch_id)
  formdata.append('desc', data.desc)
  formdata.append('name', data.name)
  formdata.append('url', data.url)
  formdata.append('file', data.vimeoFile)
  formdata.append('videotype', data.videotype)
  formdata.append('etim', data.etim)
  formdata.append('isLocked', data.isLocked)
  formdata.append('tz', data.tz)

  if (data.videotype == 1 && data.vimeoFile) {
    formdata.append('isurl', false)
  }
  if (data.feedbackEnable) {
    formdata.append('feedback_compulsory', data.feedback_obj.dism)
    formdata.append('feedback_nm', data.feedback_obj.nm)
    formdata.append('feedback_test_id', data.feedback_obj._id)

    formdata.append('feedback_desc', data.feedback_obj.desc)
  }
  if (data.videotype == 2 && data.url) {
    formdata.append('isurl', true)
  }

  if (data.link != '') {
    obj['link'] = data.link
    formdata.append('link', data.link)
  }
  // console.log(obj)
  console.log('-----------------startAddVideo', formdata)
  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelBatch/a_ch_lc?crs_id=${data.crs_id}&crs_pg_id=${data.crs_pg_id}`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Add video response: ', data)
          return Promise.resolve(data)
        } else {
          console.log('bad', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startAddScorm = ({
  crs_id,
  ch_id,
  name,
  desc,
  zip_file,
  extras,
  crs_pg_id,
  etim,
  isLocked,
  tz,
}) => {
  const data = new FormData()
  data.append('ch_id', ch_id)
  data.append('name', name)
  data.append('desc', desc)
  data.append('zip_file', zip_file)
  data.append('etim', etim)
  data.append('isLocked', isLocked)
  data.append('tz', tz)

  if (extras != undefined) {
    for (let e = 0; e < extras.length; e++) {
      data.append('extras', extras[e])
    }
  }

  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelBatch/a_ch_scorm?crs_id=${crs_id}&crs_pg_id=${crs_pg_id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        console.log(response.data)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

export var startEditScorm = ({
  crs_id,
  ch_id,
  scorm_id,
  name,
  desc,
  zip_file,
  extras,
  crs_pg_id,
  etim,
  isLocked,
  tz,
}) => {
  const data = new FormData()
  data.append('ch_id', ch_id)
  data.append('scorm_id', scorm_id)
  data.append('name', name)
  data.append('desc', desc)
  data.append('zip_file', zip_file)
  data.append('etim', etim)
  data.append('isLocked', isLocked)
  data.append('tz', tz)

  if (extras != undefined) {
    for (let e = 0; e < extras.length; e++) {
      data.append('extras', extras[e])
    }
  }

  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelBatch/e_ch_scorm?crs_id=${crs_id}&crs_pg_id=${crs_pg_id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        console.log(response.data)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

export var startAddLiveClass = (data) => {
  console.log('---------data', data)
  var obj = {
    crs_id: data.crs_id,
    crs_pg_id: data.crs_pg_id,
    ch_id: data.ch_id,
    name: data.name,
    desc: data.desc,
    url: data.url,
    stm: data.stm,
    meet_id: data.meet_id,
    meet_pwd: data.meet_pwd,
    host_id: data.host_id,
    etm: data.etm,
    tz: data.tz,
    // feedback_obj : data.feedback_obj,
    // feedbackEnable:data.feedbackEnable,
    deadline: data.deadline,
    isLocked: data.isLocked,
    mode: data.mode,
    classroom: data.classroom,
    faculty: data.faculty,
    createOnZoom: data.createOnZoom
  }
  if (data.link != '') {
    obj['link'] = data.link
  }
  if (data.feedbackEnable) {
    //obj.feedback_obj = data.feedback_obj
    obj.feedback_test_id = data.feedback_obj._id
    obj.feedback_nm = data.feedback_obj.nm
    obj.feedback_desc = data.feedback_obj.desc
    obj.feedback_compulsory = data.feedback_obj.dism
  }
  console.log(obj)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch_liv_lc',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: obj,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Add video response: ', data)
          return Promise.resolve(data)
        } else {
          console.log('bad', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var DeleteInVideoQuestions = (data) => {
  console.log(data)
  var obj = {
    lec_id: data.ques.cueTime.lec_id,

    qus_id: data.ques.cueTime.ivq_id,
    lecivq_id: data.ques.cueTime._id,
    crs_id: data.ques.crs_id,
    crs_pg_id: data.ques.crs_pg_id,
  }
  return (dispatch) => {
    console.log(obj)
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/delete_in_video_question',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: obj,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('in video question response', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var AddInVideoQuestions = (data) => {
  console.log(data)
  var obj = {
    lec_id: data.ques.cueTime.lec_id,
    crs_pg_id: data.ques.crs_pg_id,
    cuetime: data.ques.cueTime,
    crs_id: data.ques.crs_id,
    ivQues: {
      text: data.ques.question,
      correctAnswer: data.ques.correctAnswer,
      hint: data.ques.hint,
      options: data.ques.options,
      type: data.ques.type,
    },
  }
  return (dispatch) => {
    console.log(obj)
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/add_in_video_question',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: obj,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('in video question response', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var EditInVideoQuestions = (data) => {
  console.log(data)
  var obj = {
    // lec_id  : data.ques.cueTime.lec_id,
    // cuetime : data.ques.cueTime,
    lec_id: data.ques.cueTime.lec_id,

    qus_id: data.ques.cueTime.ivq_id,
    crs_id: data.ques.crs_id,
    crs_pg_id: data.ques.crs_pg_id,
    ivQues: {
      text: data.ques.question,
      correctAnswer: data.ques.correctAnswer,
      hint: data.ques.hint,
      options: data.ques.options,
      type: data.ques.type,
    },
  }
  return (dispatch) => {
    console.log(obj)
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/edit_in_video_question',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: obj,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('in video question response', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var startFetchVideoQues = (lecture) => {
  console.log(lecture)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/get_ivq',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: lecture,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('API success', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var startEditVideoLecture = (data) => {
  console.log('starcretaeCourseplugin', data, localStorage.getItem('pid'))
  var obj = {
    crs_id: data.crs_id,
    crs_pg_id: data.crs_pg_id,
    ch_id: data.ch_id,
    lec_id: data.lec_id,
    name: data.name,
    desc: data.desc,
    url: data.url,
    cuetime: data.cuePoints,
    ivQues: data.ques,
    feedback_obj: data.feedback_obj,
    feedbackEnable: data.feedbackEnable,
    etim: data.etim,
    isLocked: data.isLocked,
    tz: data.tz,
  }

  const formdata = new FormData()
  formdata.append('ch_id', data.ch_id)
  formdata.append('desc', data.desc)
  formdata.append('name', data.name)
  formdata.append('url', data.url)
  formdata.append('file', data.vimeoFile)
  formdata.append('videotype', data.videotype)
  formdata.append('cuetime', data.cuetime)
  formdata.append('ivQues', data.ivQues)
  formdata.append('lec_id', data.lec_id)
  formdata.append('etim', data.etim)
  formdata.append('isLocked', data.isLocked)
  formdata.append('tz', data.tz)

  if (data.link !== '') {
    obj['link'] = data.link
    formdata.append('link', data.link)
  } else if (data.link === '') {
    formdata.append('link', [])
    obj['link'] = []
  }
  if (data.stm) {
    obj.stm = data.stm
    formdata.append('stm', data.stm)
  }

  if (data.videotype == 1 && data.vimeoFile) {
    formdata.append('isurl', false)
  }
  if (data.videotype == 2 && data.url) {
    formdata.append('isurl', true)
  }
  if (data.feedbackEnable) {
    formdata.append('feedback_compulsory', data.feedback_obj.dism)
    formdata.append('feedback_nm', data.feedback_obj.nm)
    formdata.append('feedback_test_id', data.feedback_obj._id)

    formdata.append('feedback_desc', data.feedback_obj.desc)
  }

  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelBatch/e_ch_lc?crs_id=${data.crs_id}&crs_pg_id=${data.crs_pg_id}`,
      formdata,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('edit video res  data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed!', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var startEditLiveLecture = (data) => {
  var obj = {
    crs_id: data.crs_id,
    crs_pg_id: data.crs_pg_id,
    ch_id: data.ch_id,
    lec_id: data.lec_id,
    name: data.name,
    desc: data.desc,
    url: data.url,
    stm: data.stm,
    meet_id: data.meet_id,
    meet_pwd: data.meet_pwd,
    host_id: data.host_id,
    etm: data.etm,
    tz: data.tz,
    deadline: data.deadline,
    isLocked: data.isLocked,
    mode: data.mode,
    classroom: data.classroom,
    faculty: data.faculty
  }
  if (data.feedbackEnable) {
    //obj.feedback_obj = data.feedback_obj
    obj.feedback_test_id = data.feedback_obj._id
    obj.feedback_nm = data.feedback_obj.nm
    obj.feedback_desc = data.feedback_obj.desc
    obj.feedback_compulsory = data.feedback_obj.dism
  }
  if (data.link !== '') {
    obj['link'] = data.link
  } else if (data.link === '') {
    obj['link'] = []
  }
  console.log(obj)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_liv_lc',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: obj,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log('Add video response: ', data)
          return Promise.resolve(data)
        } else {
          console.log('bad', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        // dispatch(errorFetchingData())
      })
  }
}

export var addDocumentAsLectureToChapter = (data) => {
  var chapterid = data.ch_id
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch_doc_lec',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        name: data.name,
        desc: data.desc,
        file: data.file,
        links: data.links,
        etim: data.etim,
        tz: data.tz,
        isLocked: data.isLocked,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data)

        if (data.success) {
          // if(data.data.typ == CODING_ASSIGNMENT_TYPE){
          //     dispatch({
          //         type: "CODING_ASSIGNMENT_DETAILS",
          //         payload: {
          //             assignmentdetails : data.data,
          //             chpter_id         : chapterid
          //         }
          //     });
          // }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
//ppt upload function
export const uploadPPTAsLectureData = ({
  file,
  name,
  desc,
  crs_id,
  ch_id,
  links,
  doc_id,
  crs_pg_id,
  isLocked,
  etim,
  tz,
}) => {
  const data = new FormData()
  data.append('file', file)
  data.append('name', name)
  data.append('desc', desc)
  data.append('ch_id', ch_id)
  data.append('links', links)
  data.append('doc_id', doc_id)
  data.append('isLocked', isLocked)
  data.append('etim', etim)
  data.append('tz', tz)

  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelBatch/upload_doc?crs_pg_id=${crs_pg_id}&crs_id=${crs_id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        console.log(response.data)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

//upload thumbnail function
export const uploadThumbData = ({ file, crs_id }) => {
  const data = new FormData()
  data.append('file', file)
  return (dispatch) => {
    return Axios.post(
      `${BASE_URL}/cfpanelCourse/setup_course_thumb?crs_pg_id=${crs_id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

export var editDocAsLectureData = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_doc_lec',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        doc_lec_id: data.doc_lec_id,
        name: data.name,
        desc: data.desc,
        file: data.file,
        links: data.links,
        etim: data.etim,
        tz: data.tz,
        isLocked: data.isLocked,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const updatecoursename = ({ crs_name, crs_pg_id }) => {
  const data = {}
  data['crs_name'] = crs_name
  data['crs_pg_id'] = crs_pg_id
  return (dispatch) => {
    return Axios.post(`${BASE_URL}/cfpanelCourse/update_course_name`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

//fetch coursepage data
export const fetchCoursePageData = ({ crs_id }) => {
  const data = {}
  data['crs_pg_id'] = crs_id
  return (dispatch) => {
    return Axios.post(`${BASE_URL}/cfpanelCourse/fetch_course_detail`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

//delete skilltag
export const deleteSelectedSkillTag = ({ crs_pg_id, skill_tag_id }) => {
  const data = {}
  data['crs_pg_id'] = crs_pg_id
  data['skill_id'] = skill_tag_id
  return (dispatch) => {
    return Axios.post(`${BASE_URL}/cfpanelCourse/d_skilltag`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        if (err.response) return Promise.reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          return Promise.reject('Server error')
        }
      })
  }
}

export var deleteDocAsLectureData = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch_doc_lec',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        crs_pg_id: data.crs_pg_id,
        doc_lec_id: data.doc_lec_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var addAssignmentToChapter = (data) => {
  var chapterid = data.ch_id
  console.log('-------------sub parameter------', data.sub_prm)
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/a_ch_asgnmt',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        name: data.name,
        desc: data.desc,
        type: data.type,
        url: data.url,
        file: data.file,
        max_marks: data.max_marks,
        pass_marks: data.pass_marks,
        lst_dt: data.lst_dt,
        plg_chk: data.plgrsm_chk != undefined ? data.plgrsm_chk : null,
        wrd_cnt: data.wrd_cnt != undefined ? data.wrd_cnt : null,
        tz: data.tz != undefined ? data.tz : null,
        links: data.links,
        sub_prm: data.sub_prm,
        isLocked: data.isLocked,
        skillTags: data.skillTags,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data)

        if (data.success) {
          if (data.data.typ == CODING_ASSIGNMENT_TYPE) {
            dispatch({
              type: 'CODING_ASSIGNMENT_DETAILS',
              payload: {
                assignmentdetails: data.data,
                chpter_id: chapterid,
              },
            })
          }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var editAssignmentData = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/e_ch_asgnmt',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        asgnmt_id: data.asgnmt_id,
        name: data.name,
        desc: data.desc,
        type: data.type,
        url: data.url,
        file: data.file,
        max_marks: data.max_marks,
        pass_marks: data.pass_marks,
        lst_dt: data.lst_dt,
        plg_chk: data.plgrsm_chk != undefined ? data.plgrsm_chk : null,
        wrd_cnt: data.wrd_cnt != undefined ? data.wrd_cnt : null,
        tz: data.tz != undefined ? data.tz : null,
        links: data.links || [],
        sub_prm: data.sub_prm,
        isLocked: data.isLocked,
        skillTags: data.skillTags,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var fetchAssignmentDetails = (data) => {
  var chapterid = data.ch_id
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/r_ch_asgnmt',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        asgnmt_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          dispatch({
            type: 'CODING_ASSIGNMENT_DETAILS',
            payload: {
              assignmentdetails: data.data[0],
              chpter_id: chapterid,
            },
          })

          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var deleteAssignmentData = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch_asgnmt',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        asgnmt_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var deleteQuizData = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/d_ch_qz',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'x-access-token': localStorage.getItem('at')
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        crs_pg_id: data.crs_pg_id,
        ch_id: data.ch_id,
        qz_id: data.qz_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var deleteScormData = (data) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/d_ch_scorm'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        scorm_id: data.scorm_id,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('removeScorm data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var removeFeedbackData = (data) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/d_ch_fdbk'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        qz_id: data.qz_id,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('removeFeedbackData data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var publishFeedbackData = (data) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/publish_fdbk'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        qz_id: data.qz_id,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('publishFeedbackData data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var unpublishFeedbackData = (data) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/unpublish_fdbk'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        qz_id: data.qz_id,
        crs_pg_id: data.crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('unpublishFeedbackData data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export const setCodingAssignmentId = (assgnmentid, chapterid) => (dispatch) => {
  dispatch({
    type: 'SET_CODING_ASSIGNMENT_ID',
    payload: {
      asgnmt_id: assgnmentid,
      chapter_id: chapterid,
    },
  })
}

export const setAssignmentDetails = () => (dispatch) => {
  dispatch({
    type: 'CODING_ASSIGNMENT_DETAILS',
    payload: {
      assignmentdetails: '',
      chpter_id: '',
    },
  })
}

export const setCourseDetailsToEmpty = () => (dispatch) => {
  dispatch({
    type: 'SET_NULL_STATE',
  })
}

export var fetchProgrammingLanguage = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/r_lang',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          dispatch({
            type: 'PROGRAMMING_LANGUAGES',
            payload: {
              languages: data.data.languages,
            },
          })
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var addCodingAssignment = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/ad_cod_asg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        prob_desc: data.problem_statement,
        prob_lang: data.language,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var editCodingAssignment = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/ed_cod_asg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        prob_desc: data.problem_statement,
        prob_lang: data.language,
        pre_filled_code: data.editorvalue,
        src_code: data.src_code,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var fetchCodingProblemDetails = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/rd_cod_asg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data)
        if (data.success) {
          if (data.data.codingproblem) {
            dispatch({
              type: 'CODING_PROBLEM_DETAILS',
              payload: {
                codingproblem_details: data.data.codingproblem,
                problem_language: data.data.language,
              },
            })
          }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var fetchTestJudges = () => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/testcase_jdg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          dispatch({
            type: 'TEST_CASE_JUDGES',
            payload: {
              judges: data.data.judges,
            },
          })
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var addProblemTestCase = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/ad_codasgn_tstcse',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        tc_inp: data.testcaseinput,
        tc_out: data.testcaseoutput,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var editProblemTestCase = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/ed_codasgn_tstcse',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        tstcse_id: data.testcaseid,
        tc_inp: data.testcaseinput,
        tc_out: data.testcaseoutput,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var fetchProblemTestCases = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/rd_codasgn_tstcse',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          if (data.data.testcases) {
            dispatch({
              type: 'CODING_PROBLEM_TESTCASES',
              payload: {
                testcases: data.data.testcases,
              },
            })
          }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var deleteProblemTestCase = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/dlt_tstcse',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        tstcse_id: data.testcaseid,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export var testSourceCode = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/test_src_code',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
        src_code: data.src_code,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          if (data.data.codingsubmission) {
            console.log('hlo')
            dispatch({
              type: 'CODING_PROBLEM_SUBMISSION_RESULT',
              payload: {
                problemsubmission: data.data.codingsubmission,
              },
            })
          }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const fetchAdminPreviousSubmission = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/rd_cod_sub',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        // console.log(data);
        if (data.success) {
          if (data.data.codingsubmission) {
            dispatch({
              type: 'CODING_PROBLEM_SUBMISSION_RESULT',
              payload: {
                problemsubmission: data.data.codingsubmission,
              },
            })
          }
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const editPublishCodingAssignment = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/pub_cod_asg',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const resetEditorCode = (data) => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelBatch/rst_editor_cod',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: data.crs_id,
        ch_id: data.ch_id,
        asg_id: data.asgnmt_id,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
export var getLocationById = (loc_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_loc_by_id?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'loc_id=' + loc_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_loc_by_id data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const loadImpartusStatus = async (course_id, crs_pg_id) => {
  try {
    const response = await Axios.get(
      `${BASE_URL}/cfpanelBatch/gt_impartus_status?crs_id=${course_id}&crs_pg_id=${crs_pg_id}`,
      {
        headers: {
          'x-access-token': cookies.get('at'),
        },
      }
    )
    const { impartus_enabled } = response.data.data
    return impartus_enabled
  } catch (err) {
    if (err.response)
      if (err.response) return Promise.reject(err.response.data)
      else {
        console.log({ err })
        console.log(err.status)
        return Promise.reject('Server error')
      }
  }
}
export const updateImpartusValueHelper = async (
  course_id,
  impartusValue,
  crs_pg_id
) => {
  try {
    const response = await Axios.put(
      `${BASE_URL}/cfpanelBatch/updt_impartus_status?crs_id=${course_id}&crs_pg_id=${crs_pg_id}`,

      {
        enableImpartus: impartusValue == 'enabled',
      },
      {
        headers: {
          'x-access-token': cookies.get('at'),
        },
      }
    )
    return response.data
  } catch (err) {
    if (err.response)
      if (err.response) return Promise.reject(err.response.data)
      else {
        console.log({ err })
        console.log(err.status)
        return Promise.reject('Server error')
      }
  }
}
export const loadPlacementStatus = async (course_id, crs_pg_id) => {
  try {
    const response = await Axios.get(
      `${BASE_URL}/cfpanelBatch/gt_plcmnt_status?crs_id=${course_id}&crs_pg_id=${crs_pg_id}`,
      {
        headers: {
          'x-access-token': cookies.get('at'),
        },
      }
    )
    const { plcmnt_enable } = response.data.data
    return plcmnt_enable
  } catch (err) {
    if (err.response)
      if (err.response) return Promise.reject(err.response.data)
      else {
        console.log({ err })
        console.log(err.status)
        return Promise.reject('Server error')
      }
  }
}
export const updatePlacementStatusHelper = async (
  course_id,
  placementValue,
  crs_pg_id
) => {
  try {
    const response = await Axios.post(
      `${BASE_URL}/cfpanelBatch/updt_plcmnt_status`,
      {
        crs_id: course_id,
        crs_pg_id,
        enablePlacement: placementValue == 'enabled' ? true : false,
      },
      {
        headers: {
          'x-access-token': cookies.get('at'),
        },
      }
    )
    return response.data.data
  } catch (err) {
    if (err.response)
      if (err.response) return Promise.reject(err.response.data)
      else {
        console.log({ err })
        console.log(err.status)
        return Promise.reject('Server error')
      }
  }
}
export var getCourseSubscribers = (cid, crs_pg_id, chapters) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/gt_subs?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query +=
      'crs_id=' + cid + '&crs_pg_id=' + crs_pg_id + '&chapters=' + chapters
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('gt_subs data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getRestrictedUsers = (chapters, cid, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/gt_obst_subs?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'crs_id=' + cid + '&crs_pg_id=' + crs_pg_id
    query += '&chapters=' + JSON.stringify(chapters)
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('gt_obst_subs data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var removeRestrictedUser = (crs_id, aid, chapters, crs_pg_id, pid) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/rem_rest_user'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id, chapters, aid, pid, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('removeRestrictUser data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var saveRestrictedModules = (cid, users, chapters, crs_pg_id) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let URL = BASE_URL + '/cfpanelBatch/obst_modules'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id: cid, users, chapters, aid, pid, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('saveRestrictedModules data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export const updatePublishStatusHelper = async (
  course_id,
  chapter_ids,
  publishType,
  timestamp,
  crs_pg_id
) => {
  try {
    const response = await Axios.put(
      `${BASE_URL}/cfpanelBatch/updt_chapter_pblsh_type/`,
      {
        crs_id: course_id,
        crs_pg_id,
        chapter_ids,
        publishType,
        timestamp,
      },
      {
        headers: {
          'x-access-token': cookies.get('at'),
        },
      }
    )
    return response.data
  } catch (err) {
    console.log(err)
    return err.response.data
  }
}
export var getZoomReport = (lecId, crs_id, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/gt_zoom_rep?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'lecId=' + lecId + '&crs_id=' + crs_id + '&crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('gt_zoom_rep data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getBatchLiveLectures = (cid, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/gt_live_lecs?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'cid=' + cid
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'post',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        crs_id: cid,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('gt_live_lecs data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getUID = ({ gid, plgnid, crs_pg_id, type }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/usr_plg_idnt?'
    // let query = ''
    // let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { gid, pid, plgnid, crs_pg_id, type },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUID data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getQuizPluginsByGroupId = (gid, crs_id, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/grp_plugin_quiz?'
    let query = ''
    let pid = cookies.get('pid')

    query += 'gid=' + gid
    query += '&crs_id=' + crs_id
    query += '&crs_pg_id=' + crs_pg_id
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('grp_plugin_quiz data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var editAssignmentDeadlineBatch = ({
  cid,
  asgnmt_id,
  lst_dt,
  tz,
  timeStamp,
  crs_pg_id,
}) => {
  console.log({ cid, asgnmt_id, lst_dt, tz, timeStamp })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/asgn_deadline'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: cid,
        asgnmt_id,
        lst_dt,
        tz,
        timeStamp,
        crs_pg_id: crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('editAssignmentDeadlineBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getChaptersSyncStatus = (
  content,
  cid,
  childId,
  levelId,
  crs_pg_id
) => {
  console.log({ content, cid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/get_chp_sync_status'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { content, crs_id: cid, childId, levelId, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getChaptersSyncStatus data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getChapterContentSyncStatus = (
  content,
  cid,
  childId,
  chapterId,
  crs_pg_id
) => {
  console.log({ content, cid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/get_chp_content_sync_status'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { content, crs_id: cid, childId, chapterId, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getChapterContentSyncStatus data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var syncChapterContent = (
  content,
  cid,
  selectedChildBatchId,
  chapterId,
  crs_pg_id
) => {
  console.log({ content, cid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_chapter_content'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        content,
        crs_id: cid,
        childBatchId: selectedChildBatchId,
        chapterId,
        crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('syncContent data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var syncChapters = (
  chapterIds,
  cid,
  selectedChildBatchId,
  parentLevelId,
  crs_pg_id
) => {
  console.log({ chapterIds, cid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_chapters'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        chapterIds,
        crs_id: cid,
        childBatchId: selectedChildBatchId,
        parentLevelId,
        crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('syncContent data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getLevelSyncStatus = (content, cid, childId, crs_pg_id) => {
  console.log({ content, cid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/get_lvl_sync_status'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { content, crs_id: cid, childId, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getLevelSyncStatus data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var syncLevels = (
  levelIds,
  cid,
  childBatchId,
  parentLevelId,
  crs_pg_id
) => {
  console.log({ levelIds, cid, parentLevelId })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_levels'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { childBatchId, crs_id: cid, levelIds, parentLevelId, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getLevelSyncStatus data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export const getAllChildBatches = async (cid, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/get_all_child_batches?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query =
      'aid=' +
      aid +
      '&pid=' +
      pid +
      '&crs_id=' +
      cid +
      '&crs_pg_id=' +
      crs_pg_id
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllChildBatches data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export const getChildBatchSettingsSyncStatus = async (
  cid,
  childCid,
  crs_pg_id
) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/get_child_batch_settings_sync?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query =
      'aid=' +
      aid +
      '&pid=' +
      pid +
      '&crs_id=' +
      cid +
      '&childCid=' +
      childCid +
      '&crs_pg_id=' +
      crs_pg_id
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getChildBatchSettingsSyncStatus data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var syncGenericSettings = (cid, childCid, attribute, crs_pg_id) => {
  console.log({ cid, childCid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_generic_settings'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id: cid, childCid, attribute, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('syncGenericSettings data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var syncSpecificSettings = (cid, childCid, attribute, crs_pg_id) => {
  console.log({ cid, childCid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_specific_settings'
    // let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, crs_id: cid, childCid, attribute, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('syncSpecificSettings data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var syncWeightPublishSetting = (
  cid,
  childCid,
  attribute,
  crs_pg_id,
  weight,
  publish
) => {
  console.log({ cid, childCid })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/sync_specific_settings'
    // let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        pid,
        crs_id: cid,
        childCid,
        attribute,
        crs_pg_id,
        weight,
        publish,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('syncSpecificSettings data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var reprocessLiveLecture = ({ cid, lecId, chpId, crs_pg_id }) => {
  console.log({ cid, lecId, chpId })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/live_reprocess'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id: cid, lecId, chpId, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('reprocessLiveLecture data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var disableUrl = async ({ crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelCourse/disable_url'

  return await Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { crs_pg_id },
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in disabling url ::: ', e)
      Promise.reject(e)
    })
}

export var submitAddBatchEnd = ({ cid, endDate, crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelBatch/sbmt_btch_e_dt'

  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id: cid, endDate, crs_pg_id },
    })
      .then(({ data }) => {
        console.log({ DataInAxiosThen: data })
        if (data.success) {
          console.log('Response data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log('error in addind bacth end date action ::: ', e)
        Promise.reject(e)
      })
  }
}

export var changeBatchNSDCStatus = ({ cid, nsdc_status, crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelBatch/chnge_nsdc_status'

  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id: cid, nsdc_status, crs_pg_id },
    })
      .then(({ data }) => {
        console.log({ DataInAxiosThen: data })
        if (data.success) {
          console.log('Response data', data)
          return Promise.resolve(data)
        } else {
          console.log('API call failed', data)
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log('error in addind bacth end date action ::: ', e)
        Promise.reject(e)
      })
  }
}

export var updateMobVerificationPopupStatus = ({ status, crs_pg_id }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/updt_mob_vrfy_privacy_setting'
    let at = cookies.get('at')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: { status, crs_pg_id },
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('updateMobVerificationPopupStatus API data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log(
            'updateMobVerificationPopupStatus API call failed!',
            res.data
          )
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var updateCoursePageDetailInDB = (data) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let crs_pg_id = data.crs_pg_id
    let URL = BASE_URL + '/cfpanelCourse/update_crs_page_detail'
    let at = cookies.get('at')
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: { crs_pg_id, data },
      // crs_pg_id : { crs_pg_id }
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('updateCoursePageDetailInDB API data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('updateCoursePageDetailInDB API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchAllBrands = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/r_all_brands`

    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAllBrands data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

//Brand Management APIS
export var fetchAllBrandsAllInfo = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/r_all_brands_info`

    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAllBrandsAllInfo data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var createNewBrand = ({
  pic,
  bgimg,
  name,
  utag,
  facebookAllowed,
  googleAllowed,
  mobileAllowed,
  usernameEnabled,
  createAccAllowed,
  getHelpEnabled,
  exploreEnabled,
  editProfileEnabled,
  poweredByEnabled,
  hyperlinkEnabled,
  whiteBackground,
  textColor,
  loaderColorIndex,
  themeColorIndex,
  ctaColorIndex,
  samlLoginEnabled,
  samlCertificate,
  samlEntity,
  samlSsoLoginUrl,
  samlRequireSessionIndex,
  samlAllowUnencryptedAssertion,
  sendTemporaryPassword,
  domain,
}) => {
  let URL = BASE_URL + '/cfpanelGeneric/crt_new_brand'
  let fd = new FormData()
  if (pic != undefined) fd.set('pic', pic)
  if (bgimg != undefined) fd.set('bgimg', bgimg)
  if (utag != undefined) fd.set('utag', utag)
  if (name != undefined) fd.set('name', name)
  if (facebookAllowed != undefined) fd.set('facebookAllowed', facebookAllowed)
  if (googleAllowed != undefined) fd.set('googleAllowed', googleAllowed)
  if (mobileAllowed != undefined) fd.set('mobileAllowed', mobileAllowed)
  if (usernameEnabled != undefined) fd.set('usernameEnabled', usernameEnabled)
  if (createAccAllowed != undefined)
    fd.set('createAccAllowed', createAccAllowed)
  if (getHelpEnabled != undefined) fd.set('getHelpEnabled', getHelpEnabled)
  if (exploreEnabled != undefined) fd.set('exploreEnabled', exploreEnabled)
  if (editProfileEnabled != undefined)
    fd.set('editProfileEnabled', editProfileEnabled)
  if (poweredByEnabled != undefined)
    fd.set('poweredByEnabled', poweredByEnabled)
  if (textColor != undefined) fd.set('textColor', textColor)
  if (hyperlinkEnabled != undefined)
    fd.set('hyperlinkEnabled', hyperlinkEnabled)
  if (whiteBackground != undefined) fd.set('whiteBackground', whiteBackground)
  if (loaderColorIndex != undefined)
    fd.set('loaderColorIndex', loaderColorIndex)
  if (themeColorIndex != undefined) fd.set('themeColorIndex', themeColorIndex)
  if (ctaColorIndex != undefined) fd.set('ctaColorIndex', ctaColorIndex)
  if (samlLoginEnabled != undefined)
    fd.set('samlLoginEnabled', samlLoginEnabled)
  if (samlCertificate != undefined) fd.set('samlCertificate', samlCertificate)
  if (samlEntity != undefined) fd.set('samlEntity', samlEntity)
  if (samlSsoLoginUrl != undefined) fd.set('samlSsoLoginUrl', samlSsoLoginUrl)
  if (samlRequireSessionIndex != undefined)
    fd.set('samlRequireSessionIndex', samlRequireSessionIndex)
  if (samlAllowUnencryptedAssertion != undefined)
    fd.set('samlAllowUnencryptedAssertion', samlAllowUnencryptedAssertion)
  if (sendTemporaryPassword != undefined)
    fd.set('sendTemporaryPassword', sendTemporaryPassword)
  if (domain) fd.set('domain', domain)
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'x-access-token': cookies.get('at'),
    },
    data: fd,
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in adding brand action ::: ', e)
      Promise.reject(e)
    })
}

export var updateExistingBrand = (
  brand_id,
  {
    pic,
    bgimg,
    name,
    utag,
    facebookAllowed,
    googleAllowed,
    mobileAllowed,
    usernameEnabled,
    createAccAllowed,
    getHelpEnabled,
    exploreEnabled,
    editProfileEnabled,
    poweredByEnabled,
    hyperlinkEnabled,
    whiteBackground,
    textColor,
    loaderColorIndex,
    themeColorIndex,
    ctaColorIndex,
    samlLoginEnabled,
    samlCertificate,
    samlEntity,
    samlSsoLoginUrl,
    samlRequireSessionIndex,
    samlAllowUnencryptedAssertion,
    sendTemporaryPassword,
    domain,
  }
) => {
  let data = {
    pic,
    bgimg,
    name,
    utag,
    facebookAllowed,
    googleAllowed,
    mobileAllowed,
    usernameEnabled,
    createAccAllowed,
    getHelpEnabled,
    exploreEnabled,
    editProfileEnabled,
    poweredByEnabled,
    whiteBackground,
    textColor,
    brand_id,
    hyperlinkEnabled,
    loaderColorIndex,
    themeColorIndex,
    ctaColorIndex,
    samlLoginEnabled,
    samlCertificate,
    samlEntity,
    samlSsoLoginUrl,
    samlRequireSessionIndex,
    samlAllowUnencryptedAssertion,
    sendTemporaryPassword,
    domain,
  }
  let URL =
    BASE_URL +
    `/cfpanelGeneric/update_existing_brand${
      !(typeof pic == 'string') || (bgimg && !(typeof bgimg == 'string'))
        ? '_pic'
        : ''
    }`
  let fd = new FormData()
  fd.set('brand_id', brand_id)
  if (pic != undefined) fd.set('pic', pic)
  if (bgimg != undefined) fd.set('bgimg', bgimg)
  if (utag != undefined) fd.set('utag', utag)
  if (name != undefined) fd.set('name', name)
  if (facebookAllowed != undefined) fd.set('facebookAllowed', facebookAllowed)
  if (googleAllowed != undefined) fd.set('googleAllowed', googleAllowed)
  if (mobileAllowed != undefined) fd.set('mobileAllowed', mobileAllowed)
  if (usernameEnabled != undefined) fd.set('usernameEnabled', usernameEnabled)
  if (createAccAllowed != undefined)
    fd.set('createAccAllowed', createAccAllowed)
  if (getHelpEnabled != undefined) fd.set('getHelpEnabled', getHelpEnabled)
  if (exploreEnabled != undefined) fd.set('exploreEnabled', exploreEnabled)
  if (editProfileEnabled != undefined)
    fd.set('editProfileEnabled', editProfileEnabled)
  if (poweredByEnabled != undefined)
    fd.set('poweredByEnabled', poweredByEnabled)
  if (whiteBackground != undefined) fd.set('whiteBackground', whiteBackground)
  if (textColor != undefined) fd.set('textColor', textColor)
  if (loaderColorIndex != undefined)
    fd.set('loaderColorIndex', loaderColorIndex)
  if (themeColorIndex != undefined) fd.set('themeColorIndex', themeColorIndex)
  if (ctaColorIndex != undefined) fd.set('ctaColorIndex', ctaColorIndex)
  if (samlLoginEnabled != undefined)
    fd.set('samlLoginEnabled', samlLoginEnabled)
  if (samlCertificate != undefined) fd.set('samlCertificate', samlCertificate)
  if (samlEntity != undefined) fd.set('samlEntity', samlEntity)
  if (samlSsoLoginUrl != undefined) fd.set('samlSsoLoginUrl', samlSsoLoginUrl)
  if (samlRequireSessionIndex != undefined)
    fd.set('samlRequireSessionIndex', samlRequireSessionIndex)
  if (samlAllowUnencryptedAssertion != undefined)
    fd.set('samlAllowUnencryptedAssertion', samlAllowUnencryptedAssertion)
  if (sendTemporaryPassword != undefined)
    fd.set('sendTemporaryPassword', sendTemporaryPassword)
  if (domain) fd.set('domain', domain)
  let contentType =
    !(typeof pic == 'string') || (bgimg && !(typeof bgimg == 'string'))
      ? 'multipart/form-data'
      : 'application/json'
  console.log({ contentType })
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      'x-access-token': cookies.get('at'),
    },
    data:
      !(typeof pic == 'string') || (bgimg && !(typeof bgimg == 'string'))
        ? fd
        : data,
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in updating brand action ::: ', e)
      Promise.reject(e)
    })
}

export var toggleActiveBrand = (brand_id) => {
  let URL = BASE_URL + '/cfpanelGeneric/toggle_active_brand'
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      brand_id,
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in addind bacth end date action ::: ', e)
      Promise.reject(e)
    })
}

export var removeBrandFromCourse = async ({ crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelCourse/remove_course_brand'

  return await Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { crs_pg_id },
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in disabling url ::: ', e)
      Promise.reject(e)
    })
}

export var updateBrandFromCourse = async ({ crs_pg_id, brand_id }) => {
  let URL = BASE_URL + '/cfpanelCourse/update_course_brand'

  return await Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { crs_pg_id, brand_id },
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in disabling url ::: ', e)
      Promise.reject(e)
    })
}

export var getUserGroupTag = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/r_all_brand_utag`

    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAllBrands data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getUsernameData = (props) => {
  console.log({ props })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/r_username_detail`

    Axios({
      method: 'POST',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        userGroup: !props.selectedOption ? '' : props.selectedOption.value,
        skip: props.page || 0,
        limit: props.rowsPerPage || 10,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAllBrands data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var createSingleUsername = (name, password, group) => {
  let URL = BASE_URL + '/cfpanelGeneric/create_single_username'
  console.log({ name }, { password }, group.value)
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      name,
      userGroup: group.value,
      password,
      aid: cookies.get('aid'),
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in creating single username ::: ', e)
      Promise.reject(e)
    })
}

export var autoCreateUsername = (size, group) => {
  let URL = BASE_URL + '/cfpanelGeneric/auto_create_username'
  console.log({ size }, group.value)
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      size,
      userGroup: group.value,
      aid: cookies.get('aid'),
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in auto creating username ::: ', e)
      Promise.reject(e)
    })
}

export var multipleCreateUsername = ({ file, name, links }) => {
  let status
  const usernameImportdata = new FormData()
  usernameImportdata.append('name', name)
  usernameImportdata.append('aid', cookies.get('aid'))
  usernameImportdata.append('file', file)
  return Axios({
    method: 'POST',
    url: BASE_URL + `/cfpanelGeneric/multiple_create_username`,
    headers: {
      //   "Content-Type": "multipart/form-data",
      'x-access-token': cookies.get('at'),
    },
    data: usernameImportdata,
  })
    .then((res) => {
      status = res.status
      return res.data
    })
    .then((data) => {
      console.log({ resData: data })
      if (data.success) {
        console.log('uploadStudentData data!!', { ...data, status })
        return Promise.resolve({ ...data, status })
      } else {
        console.log('API call failed!', { ...data, status })
        return Promise.resolve({ ...data, status })
      }
    })
    .catch((err) => {
      return Promise.reject(err.response.data)
    })
}
// export var multipleCreateUsername = (size,group) => {
//   let URL = BASE_URL + '/cfpanelGeneric/multiple_create_username'
//   console.log({size},group.value);
//   return Axios({
//     method: 'POST',
//     url: URL,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       'x-access-token': cookies.get('at'),
//     },
//     data: {
//       size
//     },
//   })
//     .then(({ data }) => {
//       console.log({ DataInAxiosThen: data })
//       if (data.success) {
//         console.log('Response data', data)
//         return Promise.resolve(data)
//       } else {
//         console.log('API call failed', data)
//         return Promise.resolve(data)
//       }
//     })
//     .catch((e) => {
//       console.log('error in auto creating username ::: ', e)
//       Promise.reject(e)
//     })
// }

export var editPassword = (aid, password) => {
  let URL = BASE_URL + '/cfpanelGeneric/edit_password'
  console.log({ aid })
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid,
      password,
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in auto creating username ::: ', e)
      Promise.reject(e)
    })
}

export var deleteUsername = (aids) => {
  let URL = BASE_URL + '/cfpanelGeneric/delete_username'
  console.log({ aids })
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aids,
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in auto creating username ::: ', e)
      Promise.reject(e)
    })
}
//Mobile-app-management apis

export var getAppBuildAndVersionNumber = (props) => {
  console.log({ props })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelGeneric/get_version_and_build`

    Axios({
      method: 'GET',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetch all version and build number data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setAppBuildAndVersionNumber = async (
  type,
  current_build_version,
  description
) => {
  let aid = cookies.get('aid')
  let URL = BASE_URL + '/cfpanelGeneric/set_version_and_build'
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid,
      type,
      current_build_version,
      description,
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in setting build or version number try again ::: ', e)
      Promise.reject(e)
    })
}

export var updateMinimumBuildAndVersionNumber = async (
  type,
  current_build_version,
  minimum_build_version,
  soft_update
) => {
  let aid = cookies.get('aid')
  let URL = BASE_URL + '/cfpanelGeneric/update_minimum_version_and_build'
  return Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: {
      aid,
      type,
      current_build_version,
      minimum_build_version,
      soft_update,
    },
  })
    .then(({ data }) => {
      console.log({ DataInAxiosThen: data })
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in setting build or version number try again ::: ', e)
      Promise.reject(e)
    })
}
//Admission Module APIS
export var getAdmissionSchema = (id) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `${BASE_URL}/cfpanelGeneric/gt_adm_process`,
      {
        id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        let appSchema = response.data.data.appSchema || {}
        resolve(appSchema)
      })
      .catch((err) => {
        if (err.response) return reject(err.response.data)
        else {
          console.log({ err })
          console.log(err.status)
          reject('Server error')
        }
      })
  })
}

export var getCourseFeeStructure = (cid, package_id, installment = false) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/course/checkout_v2'
    let at = cookies.get('at')
    let aid = cookies.get('aid')
    console.log({ at })

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: { cid, aid: aid, package_id, dev: true, installment },
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getCourse data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var makeNewPseudoBatch = (crs_pg_id, name, loc_id, l_date) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/c_pseudo_batch'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_pg_id, name, loc_id, l_date },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('makeNewPseudoBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var editPseudoBatchDate = ({ crs_pg_id, batchId, name, l_date }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/e_pseudo_batch_launch_date'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_pg_id, batchId, name, l_date },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('makeNewPseudoBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchApplications = (crs_pg_id, page, per_page) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL =
      BASE_URL +
      `/cfpanelCourse/r_application_progress?crs_pg_id=${crs_pg_id}&page=${page}&per_page=${per_page}`
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchApplications data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchNewPseudoBatch = (crs_pg_id, loc_id, pageNo, pageSize) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelCourse/r_pseudo_batch?crs_pg_id=${crs_pg_id}`
    if (loc_id) URL += `&loc_id=${loc_id}`
    if (pageNo != undefined) URL += `&pageNo=${pageNo}`
    if (pageSize != undefined) URL += `&pageSize=${pageSize}`
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchNewPseudoBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchOrphans = (crs_pg_id, skip) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + `/cfpanelCourse/r_orphans?crs_pg_id=${crs_pg_id}`
    if (skip != undefined) URL += `&skip=${skip}`
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchOrphans data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchPaymentsWithoutApplication = (crs_pg_id, skip) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL =
      BASE_URL +
      `/cfpanelCourse/r_trans_without_approved_app?crs_pg_id=${crs_pg_id}`
    if (skip != undefined) URL += `&skip=${skip}`
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchPaymentsWithoutApplication data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var assignPseudoBatch = (crs_pg_id, batchId, transactions) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/assign_pseudo_batch'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_pg_id, batchId, transactions },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('assignPseudoBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getTransactionDetails = (crs_pg_id, aid) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/gt_transaction_details'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_pg_id, aid },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getTransactionDetailsAPI data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var assignToRealBatchAPI = (
  crs_pg_id,
  pseudoBatchId,
  selectedRealBatchId,
  checkedStudents
) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/subscribe_real_from_pseudo_batch'
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        pseudoBatchId,
        selectedRealBatchId,
        aids: checkedStudents,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('assignToRealBatchAPI data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var editPackage = (pkg_id, package_obj, crs_pg_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/edit_course_package'
    let at = cookies.get('at')
    let aid = cookies.get('aid')
    console.log({ at })

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: { pkg_id, package_obj, crs_pg_id },
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('editPackage data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var enablePayment = async ({ crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelCourse/enable_payment'

  return await Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { crs_pg_id },
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in disabling url ::: ', e)
      Promise.reject(e)
    })
}

export var enableApplication = async ({ crs_pg_id }) => {
  let URL = BASE_URL + '/cfpanelCourse/enable_applications'

  return await Axios({
    method: 'POST',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { crs_pg_id },
  })
    .then(({ data }) => {
      if (data.success) {
        console.log('Response data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log('error in disabling url ::: ', e)
      Promise.reject(e)
    })
}

export var fetchLoanPartners = (crs_pg_id, curr, packageId) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL =
      BASE_URL +
      `/cfpanelCourse/getLoanPartners?crs_pg_id=${crs_pg_id}&curr=${curr}&packageId=${packageId}`
    // if(loc_id) URL+=`&loc_id=${loc_id}`
    // let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    // query += 'gids=' + gids
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchLoanPartners data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setLoanPartners = (crs_pg_id, changedMap, packageId) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/setLoanPartners'
    let at = cookies.get('at')
    let aid = cookies.get('aid')
    console.log({ at })

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: { crs_pg_id, changedMap, packageId },
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('setLoanPartners data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setPaymentCollectionPreferences = ({
  crs_pg_id,
  paymentCollectionType,
  imarticusSharePerc,
  discountDistributionStrategy,
  installmentCollectionType,
  enableScholarshipDiscount,
  enableCodeDiscount,
  collectRegistrationFirst,
  multipleInvoices,
  partnerPaymentLink
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/setPaymentCollectionPreferences'
    let at = cookies.get('at')
    let aid = cookies.get('aid')
    console.log({ at, discountDistributionStrategy })

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': at,
      },
      data: {
        crs_pg_id,
        paymentCollectionType,
        imarticusSharePerc,
        discountDistributionStrategy,
        installmentCollectionType,
        enableScholarshipDiscount,
        enableCodeDiscount,
        collectRegistrationFirst,
        multipleInvoices,
        partnerPaymentLink
      },
    })
      .then((res) => {
        console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('setPaymentCollectionPreferences data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getReferralData = (crs_id, crs_pg_id) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/gt_referral_dt'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { crs_id, crs_pg_id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Referral data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var updateReferralData = ({
  courseId,
  referralEnabled,
  referralNudges,
  crs_pg_id,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/updt_referral_dt'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_id: courseId,
        crs_pg_id,
        referral_enabled: referralEnabled,
        referral_nudges: referralNudges,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Referral data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var fetchSchemasAndProductsApi = ({ crs_pg_id }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL =
      BASE_URL +
      `/cfpanelGeneric/fetchSchemasAndProducts?crs_pg_id=${crs_pg_id}`
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchSchemasAndProductsApi data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setLeadsquarePreferences = ({
  crs_pg_id,
  leadsquareProductId,
  programSchemaId,
  websiteRedirectionEnabled,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/setCourseLeadSquareParams'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        leadsquareProductId,
        programSchemaId,
        websiteRedirectionEnabled,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Referral data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setPreAssessmentMaxAttempts = ({ crs_pg_id, maxAttempts }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/setPreAssessmentMaxAttempts'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        maxAttempts,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Referral data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getCourseProductLink = ({ crs_pg_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/get_course_product_configs'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Product Link data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var setCourseProductLink = ({
  crs_pg_id,
  productLink,
  productDeclarationStatement,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/set_course_product_configs'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        productLink,
        productDeclarationStatement,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('Data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var verifyDomain = (url) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = url
    // if(url.at(-1)=='/') URL+= `api/api/ping`
    // else URL+= `/api/api/ping`

    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        return resolve({ ...res.data, status: statusCode })
        // if (res.data.success) {
        //   console.log('fetchAllBrandsAllInfo data!!', res.data)
        //   return resolve({
        //     ...res.data.data,
        //     status: statusCode,
        //     success: true,
        //   })
        // } else {
        //   console.log('API call failed!', res.data)
        //   return resolve({ ...res.data, status: statusCode })
        // }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchCourseCategories = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/r_all_categories'
    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchCourseCategories data!!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var createNewCourseCategory = (name) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/createCourseCategory'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { name },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('createNewCourseCategory data!!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({
            ...res.data,
            status: statusCode,
            success: res.data.success,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}


const TYPE = {
  "1": "LECTURE",
  "2": "ASSIGNMENT",
  "3": "QUIZ",
  "4": "LECTUREQUIZ",
  "5": "DOC_AS_LECTURE",
  "6": "NO_SUBMISSION_ASSIGNMENT",
  "7": "LIVE_LECTURE",
  "8": "SCORM"
}
export const downloadSampleCSVAPI = ({coursePageId, courseId, chapid, contType}) => {
  return new Promise((resolve, reject) => {
  
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/dwnld_smple_csv'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: coursePageId,
        crs_id: courseId,
        coursePageId,
        courseId,
        chapid,
        contType
      },
      responseType: 'blob',
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data) {
          console.log('Data!!', res.data)
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `BulkUploadContent-${TYPE[contType]}.csv`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          console.log('API call failed!', res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const submitBulkUpload = async ({coursePageId, courseId, chapid, contType, file}) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('coursePageId', coursePageId);
    formData.append('courseId', courseId);
    formData.append('chapid', chapid);
    formData.append('contType', contType);

    let statusCode
    let URL = BASE_URL + `/cfpanelBatch/blk_upld?crs_pg_id=${coursePageId}&crs_id=${courseId}`
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: formData
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.status) {
          console.log('Data in blk_upld!!', res.data)
        } else {
          console.log('API call failed!', res.data)
        }
        return resolve(res)
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
