import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { Col, Row } from 'react-bootstrap'
import '../../LabsManagement.css'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { history } from '../../../..'
import { Loader } from 'semantic-ui-react'
import moment from 'moment-timezone'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton, Tooltip } from '@mui/material'
const configs = require('../../../../config/configs.js')

const CreateLabs = () => {
  const [cloud, setCloud] = useState('')
  const [region, setRegion] = useState('')
  const [ram, setRam] = useState('')
  const [minDisk, setMinDisk] = useState(0)
  const [image, setImage] = useState([])
  const [currentISOTime, setCurrentISOTime] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [labsMinNum, setLabsMinNum] = useState(0)
  const [minCRdiff, setMinCRdiff] = useState(0)
  const [minACdiff, setMinACdiff] = useState(0)
  const [vCPU, setVCPU] = useState(0)
  const timezones = moment.tz.names()
  const [formdata, setFormdata] = useState({
    lab_name: '',
    cloud_provider: 0,
    image_id: '',
    ram: '',
    no_of_machine: '',
    disk_size: 0,
    start_time: null,
    end_time: null,
    activeperiod_start_time: null,
    activeperiod_end_time: null,
    time_out: 0,
    vcpu: 0,
    timezone: 'Asia/Calcutta',
  })

  const cookies = new Cookies()

  const submitHanlder = (e) => {
    e.preventDefault()
    try {
      const createLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const [hours, minutes] = formdata.activeperiod_start_time.split(':')
        const sTime = hours * 60 + minutes * 1

        const [ehours, eminutes] = formdata.activeperiod_end_time.split(':')
        const eTime = ehours * 60 + eminutes * 1

        const data = {
          ...formdata,
          lab_name: formdata.lab_name.trim(),
          start_time: moment
            .tz(formdata.start_time, formdata.timezone)
            .valueOf(),
          end_time: moment.tz(formdata.end_time, formdata.timezone).valueOf(),
          admin_aid: cookies.get('aid'),
          activeperiod_start_time: sTime,
          activeperiod_end_time: eTime,
        }
        setIsLoading(true)
        const res = await Axios.post(
          `${baseURL}/cfpanellabs/createRequest`,
          data,
          config
        )

        if (res) {
          if (res.status === 200) {
            history.push('/labs-management')
          } else {
            alert(res.data.message)
          }
        }
        setIsLoading(false)
      }
      createLabsRequest()
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)

    const fetchConstants = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          admin_aid: cookies.get('aid'),
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getConstants`,
          data,
          config
        )

        if (res.data.data) {
          const constants = res.data.data
          setCloud(constants.cloudProvider)
          setRegion(constants.CLOUD_PROVIDER_REGION)
          setRam(constants.RAM)

          setLabsMinNum(constants.labsRequestConstants.LABS_MAX_NUMBER)
          setMinCRdiff(
            constants.labsRequestConstants.LABS_CREATION_TIME_DIFF_FRONTEND
          )
          setMinACdiff(
            constants.labsRequestConstants.LABS_ACTIVE_TIME_DIFF_FRONTEND
          )
          setVCPU(constants.vCPU)
        }
      } catch (error) {
        console.error(error.message)
      }
    }
    fetchConstants()

    const currentDate = new Date()
    const isoTime = currentDate.toISOString()
    const date = isoTime.slice(0, 10)
    const time = isoTime.slice(11, 16)
    setCurrentISOTime(`${date}T${time}`)
    setIsLoading(false)
  }, [])

  const upadteFormdata = (e) => {
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
  }

  const handleRegionChange = (e) => {
    if (e.target.value !== '') {
      try {
        const getRegionImages = async () => {
          const baseURL = configs.routes.BASE_URL_PRODUCTION
          const config = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-access-token': cookies.get('at'),
            },
          }

          const data = {
            region: e.target.value,
          }
          setIsLoading(true)
          const res = await Axios.post(
            `${baseURL}/cfpanellabs/getRegionImages`,
            data,
            config
          )

          if (res) {
            setImage(res.data?.data?.image || '')
            if (res.status !== 200) {
              const u_data = {
                ...formdata,
                image_id: '',
                region: '',
                disk_size: '',
              }
              setFormdata(u_data)
              setMinDisk(0)
              alert(res.data.message)
            }
          }
          setIsLoading(false)
        }
        getRegionImages()
      } catch (error) {
        console.error(error.message)
      }
    } else {
      const u_data = {
        ...formdata,
        image_id: '',
        region: '',
        disk_size: '',
      }
      setFormdata(u_data)
      setMinDisk(0)
    }
  }

  const handleImageChange = (e) => {
    if (e.target.value !== '') {
      try {
        const getMinDisk = async () => {
          const baseURL = configs.routes.BASE_URL_PRODUCTION
          const config = {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-access-token': cookies.get('at'),
            },
          }

          const data = {
            image_id: e.target.value,
          }
          setIsLoading(true)
          const res = await Axios.post(
            `${baseURL}/cfpanellabs/getMinDisk`,
            data,
            config
          )

          if (res) {
            res.data?.data ? setMinDisk(res.data.data.min_disk) : setMinDisk(0)

            if (res.status !== 200) {
              alert(res.data.message)
            }
          }
          setIsLoading(false)
        }
        getMinDisk()
      } catch (error) {
        console.error(error.message)
      }
    } else {
      const u_data = {
        ...formdata,
        image_id: '',
        region: '',
        disk_size: '',
      }
      setFormdata(u_data)
      setMinDisk(0)
    }
  }

  return (
    <>
      <Sidebar active={1} />
      {isLoading ? (
        <div
          style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
        >
          <Loader active inline />
        </div>
      ) : (
        <div className="labs-main" style={{ paddingBottom: '10vh' }}>
          <TopBar />
          <div className="labs-content">
            <div>
              <div className="labs-create-new-header">Create New Labs</div>
              <br />
              {/* <Row>
                <Col md={6}>
                  <ul>
                    <li>
                      Lab Name should be <b> 50 characters</b> or less.
                    </li>
                    <li>
                      Number of allowed machines that can created are{' '}
                      <b>{labsMinNum} machines. </b>
                    </li>
                    <li>
                      {' '}
                      Creation and Deletion Time Difference can only be{' '}
                      <b>{minCRdiff} </b>{' '}
                    </li>
                    <li>
                      Keep the creation time/start time atleast <b>10 mins</b>{' '}
                      before the actual starting time
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <ul>
                    <li>
                      Active time for a day of each lab can only be for{' '}
                      <b>{minACdiff}</b>{' '}
                    </li>
                    <li>
                      Time out can be selected between{' '}
                      <b> 30 mins - 3 hours </b>
                    </li>
                    <li>vCPU is the number or cores of a machine</li>
                  </ul>
                </Col>
              </Row> */}
              <hr style={{ marginTop: '0px' }} />
              <form onSubmit={(e) => submitHanlder(e)}>
                <Row>
                  <Col md={6}>
                    <div className="labs-create-new-form">
                      <div>
                        Lab Name
                        <Tooltip
                          title=" Lab Name should be 50 characters or less."
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>

                      <input
                        type="text"
                        name="lab_name"
                        onChange={upadteFormdata}
                        value={formdata.lab_name}
                        className="labs-create-new-form-text_input"
                        required={true}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Cloud Provider{' '}
                        <Tooltip
                          title="Please select from the given options"
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        value={formdata.cloud_provider}
                        name="cloud_provider"
                        onChange={upadteFormdata}
                      >
                        <option value=""> Cloud Provider</option>
                        {cloud
                          ? Object.keys(cloud).map((key, i) => {
                              return (
                                <option value={cloud[key]} key={i}>
                                  {key}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Number of machines{' '}
                        <Tooltip
                          title={`Max machines that can be created are ${labsMinNum}`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>{' '}
                      </div>
                      <input
                        type="number"
                        className="labs-create-new-form-text_input"
                        name="no_of_machine"
                        value={formdata.no_of_machine}
                        required={true}
                        min={1}
                        onChange={upadteFormdata}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Region{' '}
                        <Tooltip
                          title="Choose region which is neartest to the users"
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        value={formdata.region}
                        name="region"
                        onChange={(e) => {
                          upadteFormdata(e)
                          handleRegionChange(e)
                        }}
                      >
                        <option value=""> Region</option>
                        {region
                          ? Object.keys(region).map((key, i) => {
                              return (
                                <option
                                  value={key}
                                  key={i}
                                  className="lab-capital"
                                >
                                  {key.toLowerCase()}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Image{' '}
                        <Tooltip
                          title="Images will contain pre-installed softwares"
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="image_id"
                        value={formdata.image_id}
                        onChange={(e) => {
                          upadteFormdata(e)
                          handleImageChange(e)
                        }}
                        disabled={image.length > 0 ? false : true}
                      >
                        <option value=""> Image </option>
                        {image
                          ? image.map((elem, i) => {
                              return (
                                <option value={elem._id} key={i}>
                                  {elem.name}
                                </option>
                              )
                            })
                          : null}
                        {/* <option value="Python">Python</option> */}
                      </select>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        RAM (gb)
                        <Tooltip
                          title="RAM will determine the speed of your machines"
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="ram"
                        value={formdata.ram}
                        onChange={upadteFormdata}
                      >
                        <option value=""> RAM</option>
                        {ram
                          ? Object.keys(ram).map((key, i) => {
                              return (
                                <option value={key} key={i}>
                                  {ram[key]}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Disk Size (gb)
                        <Tooltip
                          title={`Disk Size should be between ${minDisk}gb - 100gb, please choose accordingly`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <input
                        type="number"
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="disk_size"
                        value={formdata.disk_size}
                        onChange={upadteFormdata}
                        min={minDisk}
                        max={100}
                        disabled={minDisk > 0 ? false : true}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Creation Date-Time{' '}
                        <Tooltip
                          title={`The creation of all the labs machine will start after this time. MAX Allowed time: (${minCRdiff})`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <input
                        type="datetime-local"
                        className="labs-create-new-form-text_input"
                        name="start_time"
                        value={formdata.start_time}
                        required={true}
                        onChange={upadteFormdata}
                        min={currentISOTime}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Deletion Date-Time{' '}
                        <Tooltip
                          title={`All the machines will get destroyed and the data cannot be restored after this. MAX Allowed time: (${minCRdiff})`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <input
                        type="datetime-local"
                        className="labs-create-new-form-text_input"
                        name="end_time"
                        required={true}
                        value={formdata.end_time}
                        onChange={upadteFormdata}
                        min={formdata.start_time}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Start Time{' '}
                        <Tooltip
                          title={`Starting time of the lab. MAX Allowed time: (${minACdiff})`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                        <br />
                      </div>
                      <input
                        type="time"
                        name="activeperiod_start_time"
                        value={formdata.activeperiod_start_time}
                        className="labs-create-new-form-text_input"
                        required={true}
                        onChange={upadteFormdata}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        End Time{' '}
                        <Tooltip
                          title={`A lab will get shutdown after this. MAX Allowed time: (${minACdiff})`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>{' '}
                        <br />
                      </div>
                      <input
                        type="time"
                        name="activeperiod_end_time"
                        className="labs-create-new-form-text_input"
                        required={true}
                        value={formdata.activeperiod_end_time}
                        onChange={upadteFormdata}
                        min={formdata.activeperiod_start_time}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        vCPU{' '}
                        <Tooltip title="No. of CPU(s) for the machine" arrow>
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="vcpu"
                        value={formdata.vcpu}
                        onChange={upadteFormdata}
                      >
                        <option value=""> vCPU</option>
                        {vCPU
                          ? Object.keys(vCPU).map((key, i) => {
                              return (
                                <option value={vCPU[key]} key={i}>
                                  {key}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Labs Time-out(mins){' '}
                        <Tooltip
                          title={`Value can be between 30 mins to 180 mins, after the select value the student will get automatically logged out if he/she is inactive`}
                          arrow
                        >
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <input
                        type="number"
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="time_out"
                        value={formdata.time_out}
                        onChange={upadteFormdata}
                        min={30}
                        max={180}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>
                        Timezone{' '}
                        <Tooltip title="Timezone for the labs" arrow>
                          <IconButton sx={{ m: -0.7 }}>
                            <InfoIcon sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <select
                        className="labs-create-new-form-text_input"
                        required={true}
                        name="timezone"
                        value={formdata.timezone}
                        onChange={upadteFormdata}
                      >
                        <option value="Asia/Calcutta">Asia/Calcutta</option>
                        {timezones
                          ? timezones.map((elem, i) => {
                              return (
                                <option value={elem} key={i}>
                                  {elem}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </Col>

                  <Col md={12}>
                    <Col md={2}>
                      <button
                        className="labs-table-btn-1"
                        type="submit"
                        disabled={isLoading}
                      >
                        Create labs
                      </button>
                    </Col>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CreateLabs
