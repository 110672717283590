import React, { useEffect, useState } from 'react'
import PlacementHeader from './Components/TopBar'
import Sidebar from './Components/Sidebar'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { COUNTRY_CODES, JOB_STATUS, REVIEW_STATUS, JOB_RELEVANCY } from "../../constants/constant";
import Select from "react-select"
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import {
  createPlacementCompany,
  getAllCompanies,
  deleteJob
} from '../../actions/jobAction.js'
import zIndex from '@mui/material/styles/zIndex.js'
const configs = require('../../config/configs.js')

const PlacementCompany = () => {
  const [requests, setRequests] = useState([])
  const [locationArray, setLocationArray] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState('')
  const [selectedDDLocId, setSelectedDDLocId] = useState('')
  const [selectedLocationNumPosition, setSelectedLocationNumPosition] = useState(0)
  const [locationDropdown, setLocationDropdown] = useState([])
  const [locations, setLocations] = useState([])
  const [locationData, setLocationData] = useState([])
  const [allLocations, setAllLocations] = useState([])
  const [validFrom, setValidFrom] = useState('')
  const [validTill, setValidTill] = useState('')
  const [oppWonDate, setOppWonDate] = useState('')
  const [opportunityId, setOpportunityId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyShortCode, setCompanyShortCode] = useState('')
  const [hrname, setHrname] = useState('')
  const [hremail, setHremail] = useState('')
  const [hrccod, setHrccod] = useState('91')
  const [hrmobile, setHrmobile] = useState('')
  const [totalPositions, setTotalPositions] = useState(0)
  const [searchJobKey, setSearchJobKey] = useState('')
  const [locationName, setLocationName] = useState('ALL')
  const [locationId, setLocationId] = useState('ALL')
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [hoverJobStatus, setHoverJobStatus] = useState(false)
  const [openJobCreate, setOpenJobCreate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [reviewStatus, setReviewStatus] = useState('ALL')
  const [listExtraLocations, setListExtraLocations] = useState([]);
  const [reviewStatuses, setReviewStatuses] = useState(['ALL', 'UNDER_REVIEW',
    'APPROVED',
    'REJECTED',
    'FLAGGED',])
  const [jobStatus, setJobStatus] = useState('ALL')
  const [jobStatuses, setJobStatuses] = useState(['ALL', 'PENDING',
    'NOT_PUBLISHED',
    'LIVE',
    'HIRING_COMPLETE',
    'CANCELLED',])
  const [data, setData] = useState([])
  const [searchOptions, setSearchOptions] = useState([{
    value: 'company_id',
    label: 'Search Via Company ID'
  }, { value: 'company_name', label: 'Search Via Company Name' }, { value: 'company_shortcode', label: 'Search Via Company Short Code' }])
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')
  const [applicationSearchValue, setApplicationSearchValue] = useState('')

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    let searchField = selectedSearchOptions ? selectedSearchOptions.value : ''
    getAllCompanies(searchField, applicationSearchValue, page,rowsPerPage)
      .then(({ success, data, message }) => {
        setIsLoading(false)
        console.log({ success: success })
        if (!success) {
          if (message) {
            window.alert(message);
          } else {
            window.alert('Something went wrong. Please try again.');
          }
        } else {
          setData(data.placement_companies);
          setTotalCount(data.totalPlacementCompanies)
          // setAllLocations(data.allLocations)

        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        // Handle any errors that occurred during the async operation
      });

  }, [jobStatus,refresh,page,rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const fetchSearchedJobs = () => {
    const cookies = new Cookies()
    setIsLoading(true)
    let searchField = selectedSearchOptions ? selectedSearchOptions.value : ''
    getAllCompanies(searchField, applicationSearchValue,page,rowsPerPage)
      .then(({ success, data, message }) => {
        setIsLoading(false)
        console.log({ success: success })
        if (!success) {
          if (message) {
            window.alert(message);
          } else {
            window.alert('Something went wrong. Please try again.');
          }
        } else {
          setData(data.placement_companies);
          setTotalCount(data.totalPlacementCompanies)
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  const handleModalClose = () => {
    setOpenJobCreate(false)
    setOppWonDate('')
    setOpportunityId('')
    setCompanyName('')
    setCompanyShortCode('')
    setHrname('')
    setHremail('')
    setHrmobile('')
    setTotalPositions(0)
    setSelectedLocationDropdown('')
  }

  const handleCompanyCreation = async () => {

    if(companyName == ""){
      window.alert("Company Name can't be blank")
    }

    if(companyShortCode == ""){
      window.alert("Company Short Code can't be blank")
    }

    setIsLoading(true)
    let { success, message } = await createPlacementCompany({
      companyName: companyName,
      companyShortCode: companyShortCode,
    })

    // console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.alert('Success')
      setOpenJobCreate(false)
      setOppWonDate('')
      setOpportunityId('')
      setCompanyName('')
      setCompanyShortCode('')
      setHrname('')
      setHremail('')
      setHrmobile('')
      setTotalPositions(0)
      setSelectedLocationDropdown('')
      setSelectedDDLocId('')
      setRefresh(!refresh)
    }

    // load()
    setIsLoading(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  let serialNum = page*rowsPerPage+1

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal' style={{overflow: "hidden"}}>
      <>
        <Sidebar active={8} />
        <div className="labs-main">
          <PlacementHeader breadCrumbs={[
            { name: "Career Services", link: "/" },
          ]}
            active={8}
          />

          <div className="labs-content">
            <div style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
              <Row style={{ marginTop: '2rem' }}>
                <Col md={8} className="labs-table-header">
                  <p>All Companies</p>
                </Col>
                <Col md={2}>
                </Col>
                <Col md={2}>
                  <Button className="labs-table-btn-1"
                    onClick={() => (openJobCreate ? setOpenJobCreate(false) : setOpenJobCreate(true))}
                  >Add Company +
                  </Button>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: '999' }}>
                <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                  <Select
                    options={searchOptions}
                    placeholder="Select Search By "
                    value={selectedSearchOptions}
                    onChange={(event) => {
                      setSelectedSearchOptions(event)
                    }}
                    isSearchable={true}
                  />

                </div>
                <div className="btch-search">
                  <input
                    placeholder="Search Companies"
                    value={applicationSearchValue}
                    onChange={(e) => setApplicationSearchValue(e.target.value)}
                    className="form-control as-dl-search"
                    ref={(input) =>
                      input && searchJobKey && input.focus()
                    }
                  />
                </div>
                <Col md={2}>
                  <button
                    onClick={() => fetchSearchedJobs()}
                    className="labs-table-btn-3"
                  >
                    Search
                  </button>
                </Col>
                <Col md={1}>
                </Col>
              </div>
            </Row>
            {data.length > 0 ? (
              <Row style={{ marginTop: '1rem' }}>
                <TableContainer component={Paper} style={{ height: '600px' }}>
                  <Table aria-label="custom pagination table" id="batch-data-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="btch-dt-th-2"
                          scope="col"
                        >
                          S No.
                        </TableCell>
                        <TableCell
                          className="btch-dt-th-3"
                          scope="col"
                        >
                          Company ID
                        </TableCell>
                        <TableCell
                          className="btch-dt-th-3"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Company Name
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Company Short Code
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th-3"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Created By
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell className="btch-dt-th-3" scope="col">
                          Created On
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.map((dt, key) => {
                          return (
                            <TableRow>
                              <TableCell
                                className="btch-dt-chk "
                                scope="row"
                              >{serialNum++}</TableCell>
                              <TableCell
                                className="btch-dt-td "
                                scope="row"
                              >{dt._id}</TableCell>
                              <TableCell
                                className="btch-dt-td "
                                scope="row"
                              >{dt.company_name}</TableCell>
                              <TableCell
                                className="btch-dt-td "
                                scope="row"
                              >{dt.company_shortcode}</TableCell>
                              <TableCell
                                className="btch-dt-td "
                                scope="row"
                              >{dt.creatorName}</TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {moment(dt.createdAt).format("DD-MM-YYYY HH:mm")}
                              </TableCell>
                            </TableRow>
                          )
                        })}

                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginRight: '256px' }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Row>

            ) :
              <div className="job-not-found">
                <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                <div style={{ marginBottom: '1rem' }}>
                  <br></br>
                  <h5>It's empty here!</h5>
                </div>
                <div>
                  <p style={{ marginLeft: '1rem' }}>First create a job by clicking on</p>
                  <p>"Add Company" to view companies here</p>
                </div>
              </div>
            }
          </div>
          <Modal isOpen={openJobCreate} centered>
            <ModalHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Create a Company{' '}
              </div>
            </ModalHeader>
            <ModalBody
              className="thumbModal"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <li className="row">
                <div className="col-md-6">
                  <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                    Enter Company Short Code
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  </h5>
                  <FormGroup>
                    <Input
                      type="text"
                      name="text"
                      id="opportunityID"
                      value={companyShortCode}
                      onChange={(event, oppID) => {
                        setCompanyShortCode(event.target.value)
                      }}
                      style={{ width: '98%' }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <h5 class="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                    Enter Company Name
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  </h5>
                  <FormGroup>
                    <Input
                      type="text"
                      name="text"
                      id="companyName"
                      value={companyName}
                      onChange={(event, oppID) => {
                        setCompanyName(event.target.value)
                      }}
                      style={{ width: '98%' }}
                    />
                  </FormGroup>
                </div>
              </li>
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={() => handleCompanyCreation()}
              >
                Create
              </Button>
              <Button
                color="danger"
                onClick={() => handleModalClose()
                }
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    </div>
  )

}


export default PlacementCompany