import React , {useEffect} from 'react'
import { history } from '../../..'

const Sidebar3 = ({ active,jobid }) => {
  // const { jobId } = useParams();

  // useEffect(() => {
  //   if (!jobId) {
  //     history.push('/');
  //   }
  // }, []);
  return (
    <div className="jobs-sidebar">
        <div
        className={
          active === 0
            ? 'jobs-sidebar-elem-0 jobs-sidebar-active'
            : `jobs-sidebar-elem-0`
        }
        onClick={() => {
            history.push(`/career-module/preview-job-description/${jobid}`)
        }}
      >
        {"<"}&nbsp;&nbsp;&nbsp;Back
      </div>
      <div
        className={
          active === 1
            ? 'jobs-sidebar-elem jobs-sidebar-active'
            : `jobs-sidebar-elem`
        }
        onClick={() => {
          history.push(`/career-module/create-job-description/${jobid}`)
        }}
      >
        Add Job Rollout Filters
      </div>
      <div
        className='jobs-sidebar-elem'
      >
      </div>
    </div>
  )
}

export default Sidebar3