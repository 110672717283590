import React, { useEffect, useState } from 'react'
import PlacementHeader from './Components/TopBar'
import Select from "react-select"
import Sidebar from './Components/Sidebar'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    FormGroup,
  } from 'reactstrap'
import { COUNTRY_CODES } from "../../constants/constant";

import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'

const configs = require('../../config/configs.js')

const ExceptionApprovalRequests = () => {
  const [requests, setRequests] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState('')
  const [locationDropdown, setLocationDropdown] = useState([])
  const [locations, setLocations] = useState([])
  const [validFrom, setValidFrom] = useState('')
  const [validTill, setValidTill] = useState('')
  const [oppWonDate, setOppWonDate] = useState('')
  const [opportunityId, setOpportunityId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyShortCode, setCompanyShortCode] = useState('')
  const [hrname, setHrname] = useState('')
  const [hremail, setHremail] = useState('')
  const [hrccod, setHrccod] = useState('91')
  const [hrmobile, setHrmobile] = useState('')
  const [totalPositions, setTotalPositions] = useState(0)
  const [searchJobKey, setSearchJobKey] = useState('')
  const [locationName, setLocationName] = useState('ALL')
  const [locationId, setLocationId] = useState('') 
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [openJobCreate, setOpenJobCreate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [reviewStatus, setReviewStatus] = useState('ALL')  
  const [reviewStatuses, setReviewStatuses] = useState(['ALL','UNDER_REVIEW',
    'APPROVED',
    'REJECTED',
    'FLAGGED',])
  const [jobStatus, setJobStatus] = useState('ALL')   
  const [jobStatuses, setJobStatuses] = useState(['ALL','PENDING',
    'NOT_PUBLISHED',
    'LIVE',
    'HIRING_COMPLETE',
    'CANCELLED',]) 
  const [data, setData] = useState([])
  const [searchOptions,setSearchOptions] = useState([{value :'job_id',
  label:'Search Via Job ID'} ,{value:'company_name', label:'Search Via Company Name'}, { value:'job_title',label:'Search Via Job Title'}])
  const [selectedSearchOptions,setSelectedSearchOptions] = useState('')

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
            'aid'
          )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            let resultDropdown = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
                resultDropdown.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            
            setLocationDropdown(resultDropdown)
            result.unshift({ name: 'ALL', _id: null })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()
    

  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleModalClose = () => {
    setOpenJobCreate(false)
    setOppWonDate('')
    setOpportunityId('')
    setCompanyName('')
    setCompanyShortCode('')
    setHrname('')
    setHremail('')
    setHrmobile('')
    setTotalPositions(0)
    setSelectedLocationDropdown('')
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
  }
  const dropdownlocationChanged = (locationName, locationId) => {
    setSelectedLocationDropdown(locationName)
    // setLocationId(locationId)
  }
  const jobStatusChanged = (jobStatus) => {
    setJobStatus(jobStatus)
  }
  const reviewStatusChanged = (reviewStatus) => {
    setReviewStatus(reviewStatus)
  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
    <>
      <Sidebar active={4} />
      <div className="labs-main">
        <PlacementHeader  breadCrumbs={[
          { name: "Career Services", link: "/" },
        ]}
        active={4}
        />
        <div className="labs-content">
            <div style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
          <Row>
            {/* Courses */}
            <Col md={2} className="labs-table-header">    
            <Autocomplete
            style={{ width: '200px' }}
            disableClearable
            value={locationName}
            onChange={(event, location) => {
                locationChanged(location.name,location._id)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={locations}
            disabled={locations.length === 0 ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    label="Select Company"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col>
            <Col md={2} className="labs-table-header">
            <Autocomplete
            style={{ width: '200px' }}
            disableClearable
            value={reviewStatus}
            onChange={(event, reviewStatus) => {
              reviewStatusChanged(reviewStatus)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={reviewStatuses}
            disabled={reviewStatuses.length === 0 ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    label="Select Job ID"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col>
            {/* <Col md={2} className="labs-table-header">
            <Autocomplete
            style={{ width: '200px' }}
            disableClearable
            value={jobStatus}
            onChange={(event, jobStatus) => {
              jobStatusChanged(jobStatus)
            }}
            getOptionLabel={(batch) => {
                return batch.name || batch
            }}
            getOptionSelected={(option, test) => option.name == test}
            id="batchSelector"
            options={jobStatuses}
            disabled={jobStatuses.length === 0 ? true : false}
            renderInput={(params) => {
                return (
                <TextField
                    label="Select Job Status"
                    {...params}
                    variant="outlined"
                    fullWidth
                />
                )
            }}
            />
            </Col> */}
            <Col md={3} >
              <div className="row">
                <p style={{ fontSize: '10px'}}>From</p>
                <input
                  type="datetime-local"
                  className="labs-create-new-form-date_input"
                  name="validTill"
                  value={validFrom}
                  required={true}
                  // onChange={}
                  // min={this.state.currentFormattedDate}
                  // max={this.state.finalFormattedDate}
              />
              </div>
            
            </Col>
            <Col md={3} >   
            <div className="row">
                <p style={{ fontSize: '10px'}}>To</p>
            <input
                type="datetime-local"
                className="labs-create-new-form-date_input"
                name="validTill"
                value={validTill}
                required={true}
                // onChange={this.handleDateInputChnage}
                // min={this.state.currentFormattedDate}
                // max={this.state.finalFormattedDate}
            />
            </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
          <Col md={8} className="labs-table-header"> 
            <p>All Exception Applicants</p>
          </Col>
          </Row>
          </div>
          <Row style={{marginTop: '1rem', display: 'flex',justifyContent:'space-between'}}>
            <div style={{display: 'flex',justifyContent:'space-between'}}>
            <div className="btch-search" style={{marginRight: '1rem', width:'250px'}}>
                <Select
                  options={searchOptions}
                  placeholder="Select Search By "
                  value={selectedSearchOptions}
                  onChange={(event) => {
                    setSelectedSearchOptions(event)
                  }}
                  isSearchable={true}
                />
                
              </div>
            <div className="btch-search">
              <input
                placeholder="Search Application"
                value={''}
                // onChange={(e) => onSearchNameInputChange(e)}
                className="form-control as-dl-search"
                ref={(input) =>
                  input && searchJobKey && input.focus()
                }
              />
            </div>
            <Col md={2}>
              <button
                onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                className="labs-table-btn-3"
              >
                Search
              </button>
            </Col>
            <Col md={1}>
              </Col>
            </div>
            {/* <div style={{display: 'flex',justifyContent:'space-between'}}>
              <Col md={12} style={{width: '100%'}}>
                <button
                  onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                  className="labs-table-btn-4"
                >
                  Cancel Job
                </button>
              </Col>
            </div> */}
          </Row>
          {data.length > 0 ? (
          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper} style={{ height: '600px' }}>
                <Table aria-label="custom pagination table" id="batch-data-table">
                <TableHead>
                    <TableRow>
                    <TableCell
                    className="btch-dt-chk sticky-header-both-zero"
                    scope="col"
                  >
                    S No.
                    </TableCell>
                    <TableCell
                  className="btch-dt-th sticky-header-both-first"
                  scope="col"
                >
                  Opportunity ID
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header-both-second"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Job ID
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header-both-third"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Created By
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell
                  className="btch-dt-th sticky-header"
                  scope="col"
                  style={{ cursor: 'pointer' }}
                >
                  Company Name
                  <span
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  >
                  </span>
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Company HR Name
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Company HR Email ID
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Company HR Movile No.
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Training Center
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Created On
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Job Status
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                Review Status
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Relevancy
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Remarks
                </TableCell>
                <TableCell className="btch-dt-th sticky-header" scope="col">
                 Action
                </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell
                    className="btch-dt-chk sticky-first"
                    scope="row"
                  ></TableCell>
                  <TableCell
                    className="btch-dt-td sticky-second"
                    scope="row"
                  ></TableCell>
                  <TableCell
                    className="btch-dt-td sticky-third"
                    scope="row"
                  ></TableCell>
                  <TableCell
                    className="btch-dt-td sticky-fourth"
                    scope="row"
                  ></TableCell>
                  <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                    </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                    </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                <TableCell className="btch-dt-td" scope="row">
                </TableCell>
                  </TableRow>
                  </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
          ) : 
          <div className="job-not-found">
            <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
            <div style={{ marginBottom: '1rem' }}> 
            <br></br>
            <h5>It's empty here!</h5>
            </div>
            <div>
            <p style={{ marginLeft: '1rem' }}>Add exception applications to view them here</p>
            {/* <p>"Create a Job" to view jobs here</p> */}
            </div>
          </div>
          }
        </div>
      </div>
    </>
    </div>
  )

}


export default ExceptionApprovalRequests