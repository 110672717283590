import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useEffect, useState } from 'react'
import "./assets/styles.scss"
import { getCalendarData, zoomSignature } from '../ScheduleManagementHelper'

const localizer = momentLocalizer(moment)

const CalendarView = (props) => {
  const [ifLoading, setIfLoading] = useState(false)
  const [eventList, setEventList] = useState([])

  console.log({props})
  let {typeof: typeOf, id} = props.match.params

  useEffect(() => {
    console.log("This is the useEffect!", {typeOf, id})
    // setIfLoading(true)
    getCalendarData({typeOf, id}).then(res => {
      console.log(res)
      setIfLoading(false)
      if(res){
        for(let i=0; i<res.length; i++){
          res[i].start = new Date(res[i].start)
          res[i].end = new Date(res[i].end)
        }
        console.log({res})
        setEventList(res)
      }
    }).catch(err => {
      console.log("error in getData :: ", err)
      setIfLoading(false)
    })
  }, [])
  console.log({eventList})

  const handleSelectedEvent = (event) => {
    console.log("In selected event :: ", event);
    if(event.type !== 3){
      joinZoomMeeting(event._id)
    }

    // window.open(event.url, "_blank");
  };
  const joinZoomMeeting = async function (liv_lec_id) {
    console.log("inside joinZoomMeeting");
    console.log(liv_lec_id, " >>>> liv_lec_id");

    if (!navigator.onLine) {
      return alert("You are offline. Please check your internet connection.");
    }
    setIfLoading(true);
    zoomSignature(liv_lec_id, '')
      .then(function (res) {
        console.log(res, "signature response");
        const authArr = res.data.data.auth.split(",");
        const meeting_number = atob(authArr[0]);
        const meeting_password = atob(authArr[1]);

        var meetingConfig = {};
        meetingConfig["signature"] = res.data.data.signature;
        meetingConfig["meetingNumber"] = meeting_number;
        meetingConfig["password"] = meeting_password;
        meetingConfig["apiKey"] = res.data.data.api_key;
        meetingConfig["userName"] = res.data.data.user_name;
        meetingConfig["userEmail"] = res.data.data.user_email;
        meetingConfig["leaveUrl"] = window.location.href;
        meetingConfig["isSupportAV"] = true;
        var joinUrl = "/zoom_meeting?" +
          serialize(meetingConfig);
        console.log(joinUrl);
        window.open(joinUrl);
      })
      .catch(function (err) {
        console.log(err);
        // if (err.status && err.status == 498 && err.data.code == 4100) {
        //   authenticateCourse(function () {
        //     joinZoomMeeting(liv_lec_id);
        //   });
        // } else {
          if (err.response.data && err.response.data.code == 204035) {
            alert(err.response.data.message);
          } else {
            alert("Something went wrong. Please try again!");
          }
        // }
        setIfLoading(false);
      });
  };

  const serialize = function (obj) {
    // eslint-disable-next-line no-shadow
    var keyOrderArr = [
      "name",
      "mn",
      "email",
      "pwd",
      "role",
      "lang",
      "signature",
      "china",
    ];

    Array.intersect = function () {
      var result = new Array();
      var obj = {};
      for (var i = 0; i < arguments.length; i++) {
        for (var j = 0; j < arguments[i].length; j++) {
          var str = arguments[i][j];
          if (!obj[str]) {
            obj[str] = 1;
          } else {
            obj[str]++;
            if (obj[str] == arguments.length) {
              result.push(str);
            }
          }
        }
      }
      return result;
    };

    if (!Array.prototype.includes) {
      Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        value: function (obj) {
          var newArr = this.filter(function (el) {
            return el === obj;
          });
          return newArr.length > 0;
        },
      });
    }

    var tmpInterArr = Array.intersect(keyOrderArr, Object.keys(obj));
    var sortedObj = [];
    keyOrderArr.forEach(function (key) {
      if (tmpInterArr.includes(key)) {
        sortedObj.push([key, obj[key]]);
      }
    });
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        if (!tmpInterArr.includes(key)) {
          sortedObj.push([key, obj[key]]);
        }
      });
    var tmpSortResult = (function (sortedObj) {
      var str = [];
      for (var p in sortedObj) {
        if (typeof sortedObj[p][1] !== "undefined") {
          str.push(
            encodeURIComponent(sortedObj[p][0]) +
              "=" +
              encodeURIComponent(sortedObj[p][1])
          );
        }
      }
      return str.join("&");
    })(sortedObj);
    return tmpSortResult;
  };

  return <div className="main-body">
    <div style={{padding: "25px"}}>
      <h4>My Calendar</h4>
      <br/>
      <Calendar
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        onSelectEvent={e => handleSelectedEvent(e)}
      />
    </div>
  </div>
}

export default CalendarView