import React, { useEffect, useState } from 'react'
import PlacementHeader from './Components/TopBar'
import Sidebar from './Components/Sidebar'
import { connect } from 'react-redux'
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  FormText
} from 'reactstrap'
import { COUNTRY_CODES, JOB_STATUS, REVIEW_STATUS, JOB_RELEVANCY } from "../../constants/constant";
import Select from "react-select"
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import {
  createPlacementJob,
  getAllJobs,
  cloneJob,
  deleteJob
} from '../../actions/jobAction.js'
import zIndex from '@mui/material/styles/zIndex.js'
const configs = require('../../config/configs.js')

const JobPostings = (props) => {
  const [requests, setRequests] = useState([])
  const [locationArray, setLocationArray] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState('')
  const [selectedDDLocId, setSelectedDDLocId] = useState('')
  const [selectedLocationNumPosition, setSelectedLocationNumPosition] = useState(0)
  const [locationDropdown, setLocationDropdown] = useState([])
  const [locations, setLocations] = useState([])
  const [locationData, setLocationData] = useState([])
  const [allLocations, setAllLocations] = useState([])
  const [allCompanies, setAllCompanies] = useState([])
  const [companyDropdown, setCompanyDropdown] = useState([])
  const [changedJobStatus, setChangedJobStatus] = useState({})
  const [jobStatusDropdown, setJobStatusDropdown] = useState([{_id:4,name:'HIRING_COMPLETE'}])
  const [validFrom, setValidFrom] = useState('')
  const [validTill, setValidTill] = useState('')
  const [oppWonDate, setOppWonDate] = useState('')
  const [driveEndDate, setDriveEndDate] = useState('')
  const [opportunityId, setOpportunityId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyShortCode, setCompanyShortCode] = useState('')
  const [hrname, setHrname] = useState('')
  const [hremail, setHremail] = useState('')
  const [hrccod, setHrccod] = useState('91')
  const [hrmobile, setHrmobile] = useState('')
  const [totalPositions, setTotalPositions] = useState(0)
  const [searchJobKey, setSearchJobKey] = useState('')
  const [locationName, setLocationName] = useState('All')
  const [locationId, setLocationId] = useState('ALL')
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [hoverJobStatus, setHoverJobStatus] = useState(false)
  const [openJobCreate, setOpenJobCreate] = useState(false)
  const [openJobUpdate, setOpenJobUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [reviewStatus, setReviewStatus] = useState({ _id: 'ALL', name: 'All' })
  const [listExtraLocations, setListExtraLocations] = useState([]);
  const [jobIdArray, setJobIdArray] = useState([])
  const [jobToClone, setJobToClone] = useState('');
  const [shortCodeToClone, setShortCodeToClone] = useState('');
  const [newOppId, setNewOppId] = useState('');
  const [jobCancelRemarks, setJobCancelRemarks] = useState('');
  const [openCloneModal, setOpenCloneModal] = useState(false)
  const [openJobCancelModal, setOpenJobCancelModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openJobCloseModal, setOpenJobCloseModal] = useState(false);
  const [createdJobId, setCreatedJobId] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [reviewStatuses, setReviewStatuses] = useState([{ _id: 'ALL', name: 'All' }, { _id: 'UNDER_REVIEW', name: 'Under Review' },
  { _id: 'APPROVED', name: 'Approved' },
  { _id: 'REJECTED', name: 'Rejected' },
  { _id: 'FLAGGED', name: 'Flagged' },])
  const [jobStatus, setJobStatus] = useState({ _id: 'ALL', name: 'All' })
  const [jobStatuses, setJobStatuses] = useState([
    { _id: 'ALL', name: 'All' },
    { _id: 'PENDING', name: 'Pending' },
    { _id: 'NOT_PUBLISHED', name: 'Not Published' },
    { _id: 'LIVE', name: 'Live' },
    { _id: 'HIRING_COMPLETE', name: 'Hiring Complete' },
    { _id: 'CANCELLED', name: 'Cancelled' }
  ])
  const [data, setData] = useState([])
  const [searchOptions, setSearchOptions] = useState([{
    value: 'job_id',
    label: 'Search Via Job ID'
  }, { value: 'company_name', label: 'Search Via Company Name' }, { value: 'opp_id', label: 'Search Via Opportunity ID' }])
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')
  const [applicationSearchValue, setApplicationSearchValue] = useState('')
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [selectedJob, setSelectedJob] = useState('')
  const [selectedJobDetail, setSelectedJobDetail] = useState({})

  useEffect(() => {

    const cookies = new Cookies()
    setIsLoading(true)
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT
            }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
              'aid'
            )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            let resultDropdown = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
                resultDropdown.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }

            setLocationDropdown(resultDropdown)
            result.unshift({ name: 'All', _id: 'ALL' })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()


  }, [refresh, page, rowsPerPage])

  useEffect(() => {
    console.log({ abc2: props.permissions })
  }, [props])

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    let relevanceStatus = ''
    let searchVal = ''
    let searchField = selectedSearchOptions ? selectedSearchOptions.value : ''
    getAllJobs(locationId, reviewStatus._id, jobStatus._id, validFrom, validTill, relevanceStatus, searchField, applicationSearchValue,page,
      rowsPerPage )
      .then(({ success, data, message }) => {
        setIsLoading(false)
        console.log({ success: success })
        if (!success) {
          if (message) {
            window.alert(message);
          } else {
            window.alert('Something went wrong. Please try again.');
          }
        } else {

          let tmp_data =
            data && data.allCompanies
              ? data.allCompanies
              : []
          let result = []
          console.log({ tmp_data })
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].company_name) {
              result.push({
                name: tmp_data[d].company_name,
                _id: tmp_data[d]._id,
              })
            }
          }
          console.log({ result })
          setData(data.placement_jobs);
          setTotalCount(data.totalPlacementJobs)
          setAllLocations(data.allLocations)
          setCompanyDropdown(result)
          setAllCompanies(data.allCompanies)
          let jobs = data.placement_jobs
          const jobCodeArray = jobs.map(job => job.job_code);
          console.log({ jobCodeArray })
          setJobIdArray(jobCodeArray);
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        // Handle any errors that occurred during the async operation
      });

  }, [locationName, reviewStatus, validTill, jobStatus, refresh,page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOppIdChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (regex.test(value)) {
      setNewOppId(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Only alphanumeric characters are allowed');
    }
  };

  const handleCreateOppIdChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;

    if (regex.test(value)) {
      setOpportunityId(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Only alphanumeric characters are allowed');
    }
  };

  const checkVisibility = (resource, resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  const handleCloneModalClose = () => {
    setJobToClone('')
    setOpenCloneModal(false)
    setNewOppId('')
    setShortCodeToClone('')
    setErrorMessage('')
  }

  const handleClick = (id) => {
    // setOpenCancelModal(true);
    setOpenJobCancelModal(true)

    setSelectedJob(id);
    const selectedJob = data.find(job => job._id === id);
    console.log({selectedJob})
    setSelectedJobDetail(selectedJob);
  };

  const fetchSearchedJobs = () => {
    const cookies = new Cookies()
    setIsLoading(true)
    let relevanceStatus = ''
    let searchVal = ''
    let searchField = selectedSearchOptions ? selectedSearchOptions.value : ''
    getAllJobs(locationId, reviewStatus._id, jobStatus._id, validFrom, validTill, relevanceStatus, searchField, applicationSearchValue,page,
      rowsPerPage )
      .then(({ success, data, message }) => {
        setIsLoading(false)
        console.log({ success: success })
        if (!success) {
          if (message) {
            window.alert(message);
          } else {
            window.alert('Something went wrong. Please try again.');
          }
        } else {
          setData(data.placement_jobs);
          setTotalCount(data.totalPlacementJobs)
          setAllLocations(data.allLocations)
          let jobs = data.placement_jobs
          const jobCodeArray = jobs.map(job => job.job_code);
          console.log({ jobCodeArray })
          setJobIdArray(jobCodeArray);
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
        // Handle any errors that occurred during the async operation
      });
  }

  // const handleButtonClick = () => {
  //   history.push(`/placement-module/create-job-description/${createdJobId}`);
  // };

  const handleModalClose = () => {
    setOpenJobCloseModal(false)
    setOpenJobCreate(false)
    setOppWonDate('')
    setOpportunityId('')
    setCompanyName('')
    setCompanyShortCode('')
    setHrname('')
    setHremail('')
    setHrmobile('')
    setTotalPositions(0)
    setSelectedLocationDropdown('')
    setDriveEndDate('')
    setErrorMessage('')
  }

  const handleJobCreation = async () => {

    console.log({ totalPositions })
    const totalNumOpenings = listExtraLocations.reduce((total, item) => total + parseInt(item.num_openings), 0);
    console.log({ totalNumOpenings })

    if (opportunityId == "" || companyName == "" || companyShortCode == "" || hrname == "" || hremail == "" || hrccod == "" || totalPositions == "" || oppWonDate == "") {
      window.alert('Mandatory fields can not be left blank!')
      return
    }
    if (parseInt(totalPositions) != parseInt(totalNumOpenings)) {
      window.alert('Sum of openings in each location need to be equal to total openings')
      return
    } else {
      setIsLoading(true)
      let { success, createdJob, message } = await createPlacementJob({
        opportunityId: opportunityId,
        companyName: companyName,
        companyShortCode: companyShortCode,
        hrname: hrname,
        hremail: hremail,
        hrccod: hrccod,
        hrmobile: hrmobile,
        totalPositions: totalPositions,
        oppWonDate: oppWonDate,
        driveEndDate: '',
        selectedlocationDropdown: selectedDDLocId,
        selectedLocationNumPosition: selectedLocationNumPosition,
        locationArray: listExtraLocations
      })
      // console.log({ success, message })
      if (success == false) {
        if (message) {
          window.alert(message)
        } else {
          window.alert('Something went wrong. Please try again.')
        }
      } else {
        // window.alert('Success')
        console.log({ createdJob })
        setCreatedJobId(createdJob ? createdJob._id : '')
        setOpenSuccessModal(true)
        setOpenJobCreate(false)
        setOppWonDate('')
        setOpportunityId('')
        setCompanyName('')
        setCompanyShortCode('')
        setHrname('')
        setHremail('')
        setHrmobile('')
        setTotalPositions(0)
        setSelectedLocationDropdown('')
        setSelectedDDLocId('')
        setDriveEndDate('')
        setRefresh(!refresh)
      }
    }

    // load()
    setIsLoading(false)
  }

  const deleteSelectedJob = (jobid) => {
    if(jobCancelRemarks == ""){
      window.alert("Remarks can't be left blank")
      return
    }

    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/delete_placementjob`,
        method: 'POST',
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { jobid: jobid,
                remarks:jobCancelRemarks
         }
      }
      Axios(getData)
        .then((res) => {
          let resp = res && res.data
          if (resp.success) {
            window.alert('Successfully deleted the job')
            let result = []
            result.unshift({ name: 'ALL', _id: null })
            setIsLoading(false)
            setOpenCancelModal(false)
            resolve(result)
            window.location.reload()
          } else {
            window.alert(resp.message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleJobStatusChange = (jobid) => {
    if(changedJobStatus == null || changedJobStatus == undefined){
      window.alert('Please select Job Status to change to')
    }
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/change_job_status`,
        method: 'POST',
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { 
          jobid: jobid,
          driveEndDate:driveEndDate,
          job_status: changedJobStatus ? changedJobStatus._id : 4
         }
      }
      Axios(getData)
        .then((res) => {
          let resp = res && res.data
          if (resp.success) {
            window.alert('Successfully changed job status')
            let result = []
            result.unshift({ name: 'ALL', _id: null })
            setIsLoading(false)
            setOpenJobUpdate(false)
            setSelectedJob('')
            setChangedJobStatus({})
            setDriveEndDate('')
            resolve(result)
            window.location.reload()
          } else {
            window.alert(resp.message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
    console.log("changed LOcation")
  }

  const selectedCompanyChanged = (companyName, companyId) => {
    setCompanyName(companyName)
    const company = allCompanies.find(company => company._id === companyId);
    if (company) {
      const companyShortcode = company.company_shortcode;
      setCompanyShortCode(companyShortcode);
    }
  }

  const cloneAJob = async (
  ) => {
    setIsLoading(true)
    let resp = await cloneJob(
      jobToClone,
      newOppId,
      shortCodeToClone,
    )
    if (resp.success) {
      setRefresh(!refresh)
      window.alert('Job Cloned successfully')
      setIsLoading(false)
      setOpenCloneModal(false)
    } else {
      window.alert(resp.message)
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
    }
  }

  const dropdownlocationChanged = (locationName, locationId, index) => {
    console.log({ index })

    const locationObj = { index: index, id: locationId, loc_id: locationId, name: locationName, num_openings: 0 };
    const existingIndex = listExtraLocations.findIndex(item => String(item.index) === String(index));
    console.log({ existingIndex })
    // console.log({locationName})
    // console.log({locationId})
    // console.log({listExtraLocations})

    if (existingIndex !== -1) {
      const updatedLocationArray = [...listExtraLocations];
      updatedLocationArray[existingIndex] = locationObj;
      console.log({ updatedLocationArray })
      setListExtraLocations(updatedLocationArray);
    } else {
      setListExtraLocations([...listExtraLocations, locationObj])
    }
  }

  useEffect(() => {
    if (listExtraLocations.length == 0) {
      dropdownlocationChanged('', '', 0)
    }
  }, [])


  const dropdownNumOpeningsChanged = (value, index) => {
    console.log({ index })
    const existingIndex = listExtraLocations.findIndex(item => String(item.index) === String(index));
    console.log({ existingIndex })
    if (existingIndex !== -1) {
      const updatedLocationArray = [...listExtraLocations];
      console.log({ updatedLocationArray })
      updatedLocationArray[existingIndex] = { ...updatedLocationArray[existingIndex], num_openings: value };
      setListExtraLocations(updatedLocationArray);
    }
  }
  const jobStatusChanged = (jobStatus) => {
    setJobStatus(jobStatus)
  }
  const reviewStatusChanged = (reviewStatus) => {
    setReviewStatus(reviewStatus)
  }

  const jobToCloneChanged = (jobId) => {
    const jobToClone = data.find(job => job.job_code === jobId);

    if (jobToClone) {
      setJobToClone(jobId);
      setShortCodeToClone(jobToClone.company_shortcode);
    }

  }

  const addAdditionalLocation = () => {
    if (listExtraLocations.length <= 4) {
      if (listExtraLocations.length === 0) {
        setListExtraLocations([{
          id: 1,
          index: 1,
          name: '',
          loc_id: '',
          num_openings: 0
        }]);
      } else {
        const newListOption = {
          id: listExtraLocations[listExtraLocations.length - 1].index + 1,
          index: listExtraLocations[listExtraLocations.length - 1].index + 1,
          name: '',
          loc_id: '',
          num_openings: 0
        };
        setListExtraLocations(prevListOptions => [...prevListOptions, newListOption]);
      }
    } else {
      window.alert("Max no. of locations is limited to 5")
      return
    }
  };

  const deleteAdditionalLocation = (idx) => {
    setListExtraLocations(prevLocations => {
      const newLocations = [...prevLocations];
      newLocations.splice(idx, 1);
      return newLocations;
    });
  };

  let serialNum = page*rowsPerPage+1
  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  const handleDateInputChnage = (e) => {
    const [key, value] = [e.target.name, e.target.value]

    if (e.target.name == 'validFrom') {
      setValidFrom(e.target.value)
    }
    if (e.target.name == 'validTill') {
      setValidTill(e.target.value)
    }

  }


  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal' style={{ overflow: "hidden" }}>
      <>
        <Sidebar active={1} />
        <div className="labs-main">
          <PlacementHeader breadCrumbs={[
            { name: "Career Services", link: "/" },
          ]}
            active={1}
          />
          <div className="labs-content">
            <div style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
              <Row>
                {/* Courses */}
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Training Location</p>
                  <Autocomplete
                    disableClearable
                    value={locationName}
                    onChange={(event, location) => {
                      locationChanged(location.name, location._id)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={locations}
                    disabled={locations.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Training Location"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Review Status</p>
                  <Autocomplete
                    disableClearable
                    value={reviewStatus}
                    onChange={(event, reviewStatus) => {
                      reviewStatusChanged(reviewStatus)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={reviewStatuses}
                    disabled={reviewStatuses.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Review Status"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px",paddingBottom: "4px" }}>Select Job Status</p>
                  <Autocomplete
                    disableClearable
                    value={jobStatus}
                    onChange={(event, jobStatus) => {
                      jobStatusChanged(jobStatus)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={jobStatuses}
                    disabled={jobStatuses.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Job Status"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} >
                  <div className="row" style={{display: "flex",flexDirection: "column"}}>
                    <p style={{ fontSize: '10px', margin: "0px",marginLeft: "10px" ,paddingBottom: "4px"}}>From</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validFrom"
                      value={validFrom}
                      required={true}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                    // min={this.state.currentFormattedDate}
                    // max={this.state.finalFormattedDate}
                    />
                  </div>

                </Col>
                <Col md={2} >
                <div className="row" style={{display: "flex",flexDirection: "column"}}>
                  <p style={{ fontSize: '10px', margin: "0px",marginLeft: "10px" ,paddingBottom: "4px"}}>To</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validTill"
                      value={validTill}
                      required={true}
                      disabled={validFrom == '' ? true : false}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                      min={validFrom}
                    // max={this.state.finalFormattedDate}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '2rem' }}>
                <Col md={8} className="labs-table-header">
                  <Col md={8} className="labs-table-header">
                    <p>{checkVisibility('43', 2) ? 'All Jobs' : 'Review Job Postings'}</p>
                  </Col>

                </Col>
                {checkVisibility('43', 2) && (
                  <>
                    <Col md={2}>
                      <button
                        onClick={() => setOpenCloneModal(true)}
                        className="job-clone-btn-2"
                        style={{ background: 'rgba(60, 72, 82, 0.25)', color: "#3C4852" }}
                      >

                        Clone A Job
                        <img style={{ marginLeft: '4px' }}
                          src="https://webcdn.imarticus.org/Placement/fa-regular_clone.svg"
                        />
                      </button>
                    </Col>
                    <Col md={2}>
                      <Button className="labs-table-btn-1"
                        onClick={() => (openJobCreate ? setOpenJobCreate(false) : setOpenJobCreate(true))}
                      >Create a Job +


                        <Modal className="jobs-editquesmodal" isOpen={openCloneModal} style={{ width: '25%' }} centered>
                          <ModalBody
                            className="thumbModal"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center',fontSize: "18px",fontWeight: "600" }}>
                              Select a Job ID to Clone{' '}
                            </div>
                            <li className='row'>
                              <div className="col-md-12">
                                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' ,fontSize: "16px",fontWeight: "600"}}>
                                  Job ID
                                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                                </h5>
                                <FormGroup>
                                  <Autocomplete
                                    style={{ width: '100%', marginTop: '20px' }}
                                    disableClearable
                                    value={jobToClone}
                                    onChange={(event, jobId) => {
                                      jobToCloneChanged(jobId)
                                    }}
                                    getOptionLabel={(batch) => {
                                      return batch.name || batch
                                    }}
                                    getOptionSelected={(option, test) => option.name == test}
                                    id="batchSelector"
                                    options={jobIdArray}
                                    disabled={jobIdArray.length === 0 ? true : false}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          label="Select Job Id"
                                          {...params}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </div>

                            </li>
                            <li className='row'>
                              <div className="col-md-12">
                                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',fontSize: "16px",fontWeight: "600" }}>
                                  Enter New Opportunity ID
                                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                                </h5>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    name="text"
                                    id="tempQuestion"
                                    value={newOppId}
                                    onChange={handleOppIdChange}
                                    style={{ width: '98%' }}
                                  />
                                  {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
                                </FormGroup>
                              </div>

                            </li>
                            <div style={{ justifyContent: "space-between", display: "flex", marginTop: "5px", marginLeft: "5px", marginRight: "5px" }}>
                              <div style={{ width: "48%" }}>
                                <Button
                                  style={{ width: "100%", borderRadius: "4px",fontSize: "16px",fontWeight: "600" }}
                                  onClick={() => handleCloneModalClose()}
                                  className="apply-success-btn"
                                  color="danger"
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div style={{ width: "48%" }}>
                                <Button
                                  onClick={() => cloneAJob()}
                                  style={{ background: "#014644", width: "100%", borderRadius: "4px",fontSize: "16px",fontWeight: "600" }}
                                >
                                  Clone
                                </Button>
                              </div>

                            </div>        


                          </ModalBody>
                        </Modal>


                        <Modal
                          isOpen={openSuccessModal}
                          style={{ width: '20%' }}
                          size={"xl"}
                          className="apply-sucess-modal"
                          centered
                        >
                          <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
                            <div>
                              <div className="prof-dtl-img ">
                              </div>
                              <p className="aply-success-txt">
                                Job has been successfully created!
                              </p>
                              <p className="prof-dtl-txt">
                                By continuing, you  can add Job description related details and more in next screens
                              </p>
                            </div>
                            <div style={{ justifyContent: "space-between", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
                              <div style={{ width: "48%" }}>
                                <Button
                                  style={{ width: "100%", borderRadius: "4px" }}
                                  onClick={() => {
                                    setOpenSuccessModal(false);
                                    // setSelectedJob('')
                                  }}
                                  className="apply-success-btn"
                                  color="danger"
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div style={{ width: "48%" }}>
                                <Link
                                  to={`/career-module/create-job-description/${createdJobId}`}
                                // className="as-dl-sidebar-lg link"
                                >
                                  <Button
                                    // onClick={handleButtonClick}
                                    style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                                  >
                                    Continue
                                  </Button>
                                </Link>
                              </div>

                            </div>
                          </ModalBody>
                        </Modal>

                        <Modal
                          isOpen={openJobCloseModal}
                          style={{ width: '20%' }}
                          size={"xl"}
                          className="apply-sucess-modal"
                          centered
                        >
                          <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
                            <div>
                              <div className="prof-dtl-img ">
                              </div>
                              <p className="aply-success-txt">
                                Do you wish to cancel this job creation?
                              </p>
                              <p className="prof-dtl-txt">
                              If you click yes, this job will not be created and the entered data will not be saved.
                              </p>
                            </div>
                            <div style={{ justifyContent: "space-between", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
                              <div style={{ width: "48%" }}>
                                <Button
                                  style={{ width: "100%", borderRadius: "4px" }}
                                  onClick={() => {
                                    setOpenJobCloseModal(false);
                                  }}
                                  className="apply-success-btn"
                                  color="danger"
                                >
                                  Go Back
                                </Button>
                              </div>
                              <div style={{ width: "48%" }}>
                                  <Button
                                    onClick={() => handleModalClose()
                                    }
                                    style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                                  >
                                    Yes
                                  </Button>
                              </div>

                            </div>
                          </ModalBody>
                        </Modal>

                        <Modal
                          isOpen={openCancelModal}
                          size={"md"}
                          style={{ width: '25%' }}
                          centered
                        >
                          <ModalBody style={{ maxHeight: "400px", overflowY: "scroll" }}>
                            <div>
                              <p className="aply-success-txt ">
                                Are you sure you want to delete/cancel this job ?
                              </p>
                            </div>
                          </ModalBody>
                          <ModalFooter >
                            <Button
                              // color="success"
                              onClick={() => {
                                setOpenCancelModal(false);
                                setSelectedJob('')
                              }}
                              className="apply-success-btn"
                              color="danger"
                            >
                              No
                            </Button>
                            <Button
                              onClick={() => deleteSelectedJob(selectedJob)}
                              // onClick={() => setOpenCancelModal(false)}
                              // className="apply-danger-btn"
                              color="success"
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <Modal
                          isOpen={openJobCancelModal}
                          size={"md"}
                          style={{ width: '50%' }}
                          centered
                        >
                          <ModalHeader>
                          <div style={{ display: 'flex', alignItems: 'center',fontSize: "20px",fontWeight: "600" }}>
                            Cancel Job
                          </div>
                          </ModalHeader>
                          <ModalBody style={{ maxHeight: "400px"}}>
                          <li className="row">
                            <div className="col-md-12">
                              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' , fontSize: "16px",fontWeight: "600"}}>
                                Select Job ID
                                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                              </h5>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="text"
                                  id="opportunityID"
                                  value={selectedJobDetail && selectedJobDetail.job_code}
                                  // onChange={(event, oppID) => {
                                  //   setJobCancelRemarks(event.target.value)
                                  // }}
                                  style={{ width: '98%' }}
                                  disabled
                                />
                              </FormGroup>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' , fontSize: "16px",fontWeight: "600"}}>
                                Remarks
                                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                              </h5>
                              <FormGroup>
                                <Input
                                  type="textarea"
                                  name="text"
                                  rows="5"
                                  id="opportunityID"
                                  value={jobCancelRemarks}
                                  onChange={(event, oppID) => {
                                    setJobCancelRemarks(event.target.value)
                                  }}
                                  style={{ width: '98%' }}
                                />
                              </FormGroup>
                            </div>
                          </li>
                          </ModalBody>
                          <ModalFooter >
                            <Button
                              // color="success"
                              onClick={() => {
                                setOpenJobCancelModal(false);
                                setSelectedJob('')
                                setSelectedJobDetail({})
                              }}
                              className="apply-success-btn"
                              color="danger"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => deleteSelectedJob(selectedJob)}
                              color="success"
                            >
                              Done
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </div>
            <Row style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between', marginLeft: "0px" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: '999' }}>
                <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                  <Select
                    options={searchOptions}
                    placeholder="Select Search By "
                    value={selectedSearchOptions}
                    onChange={(event) => {
                      setSelectedSearchOptions(event)
                    }}
                    isSearchable={true}
                  />

                </div>
                <div className="btch-search">
                  <input
                    placeholder="Search Jobs"
                    value={applicationSearchValue}
                    onChange={(e) => setApplicationSearchValue(e.target.value)}
                    className="form-control as-dl-search"
                    ref={(input) =>
                      input && searchJobKey && input.focus()
                    }
                  />
                </div>
                <Col md={2}>
                  <button
                    onClick={() => fetchSearchedJobs()}
                    className="labs-table-btn-3"
                  >
                    Search
                  </button>
                </Col>
                <Col md={1}>
                </Col>
              </div>
            </Row>
            {data.length > 0 ? (
              <Row style={{ marginTop: '1rem', marginLeft: "0px" }}>
                <TableContainer component={Paper} style={{ height: '600px' }}>
                  <Table aria-label="custom pagination table" id="batch-data-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="btch-dt-chk sticky-header-both-zero"
                          scope="col"
                        >
                          S No.
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-first"
                          scope="col"
                        >
                          Opportunity ID
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-second"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Job ID
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-third"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Created By
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Company Name
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Name
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Email ID
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Mobile No.
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Training Center
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Created On
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Job Status
                          {hoverJobStatus ? <div className="showJobPopupSkill3">
                            <p style={{ zIndex: '999' }}>Pending: Job is under draft and not published </p>
                            <p style={{ zIndex: '999' }}>Live: The Job is published and taking applicants</p>
                            <p style={{ zIndex: '999' }}>Hiring Completed: No. of job openings equals no. of hiring</p>
                            <p style={{ zIndex: '999' }}>Cancelled: The job is cancelled</p>
                          </div> : ''}
                          <img src='https://webcdn.imarticus.org/webinar/Vector1.svg' style={{ objectFit: "contain", marginLeft: '5px', zIndex: '999' }} onMouseEnter={() => setHoverJobStatus(true)} onMouseLeave={() => setHoverJobStatus(false)} />
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Review Status
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Relevancy
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Remarks
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Action
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Update Job Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.map((dt, key) => {
                          let trainingLocations = [];
                          if (Array.isArray(dt.training_locations)) {
                            if (Array.isArray(dt.training_locations[0])) {
                              trainingLocations = dt.training_locations.flat();
                            } else {
                              trainingLocations = dt.training_locations;
                            }
                          }
                          const locationData = trainingLocations.map(location => {
                            // console.log({location})
                            const dropdownItem = allLocations.find(item => String(item._id) === String(location.loc_id));
                            // console.log({dropdownItem})
                            if (!dropdownItem) return null; // Skip if dropdown item not found
                            return {
                              name: dropdownItem.name,
                              loc_id: location.loc_id,
                              num_openings: location.loc_num
                            };
                          }).filter(item => item !== null);
                          // console.log({locationData})
                          return (
                            <TableRow>
                              <TableCell
                                className="btch-dt-chk sticky-first"
                                scope="row"
                              >{serialNum++}</TableCell>
                              <TableCell
                                className="btch-dt-td sticky-second"
                                scope="row"
                              >{dt.opportunity_id}</TableCell>
                              <TableCell
                                className="btch-dt-td sticky-third"
                                scope="row"
                              >{dt.job_code}</TableCell>
                              <TableCell
                                className="btch-dt-td sticky-fourth"
                                scope="row"
                              >{dt.creatorName}</TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {dt.company_name}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {dt.hr_name}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {dt.hr_email}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                +{dt.hr_ccod}{dt.hr_mobile}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {locationData.map(location => (
                                  <div key={location.loc_id}>
                                    {location.name}: {location.num_openings}
                                    <br />
                                  </div>
                                ))}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {moment(dt.createdAt).format("DD-MM-YYYY HH:mm")}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {JOB_STATUS[dt.job_status]}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {/* {REVIEW_STATUS[dt.review_status] } */}
                                {'-'}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {/* {JOB_RELEVANCY[dt.job_relevancy]} */}
                                {'-'}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {/* {dt.act} */}
                                {'-'}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                                {dt.job_status > 2 ? (
                                  <div className='row' style={{justifyContent: 'center'}}>
                                    <Link to={`/career-module/create-job-description/${dt._id}`} className="as-dl-sidebar-lg link">
                                      <img
                                        src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                        className="as-dl-logo-job"
                                      />
                                    </Link>
                                    {/* <Link to="/" className="as-dl-sidebar-lg link">
                                    <img
                                      src="https://webcdn.imarticus.org/Demo/material-symbols_history.svg"
                                      className="as-dl-logo-job"
                                    />
                                  </Link> */}
                                    {checkVisibility('43', 2) && (
                                      <div className="as-dl-sidebar-lg link" onClick={() => handleClick(dt._id)}>
                                        <img
                                          src="https://webcdn.imarticus.org/Demo/Group5557.svg"
                                          className="as-dl-logo-job"
                                          alt="Delete Job"
                                        />
                                      </div>
                                    )}
                                  </div>

                                ) : (
                                  <div className='row' style={{justifyContent: 'center'}}>
                                    <Link to={`/career-module/create-job-description/${dt._id}`} className="as-dl-sidebar-lg link">
                                      <img
                                        src="https://webcdn.imarticus.org/Demo/Group5555.svg"
                                        className="as-dl-logo-job"
                                      />
                                    </Link>
                                    {/* <Link to="/" className="as-dl-sidebar-lg link">
                                    <img
                                      src="https://webcdn.imarticus.org/Demo/material-symbols_history.svg"
                                      className="as-dl-logo-job"
                                    />
                                  </Link> */}
                                    {
                                      checkVisibility('43', 2) && (
                                        <div className="as-dl-sidebar-lg link" onClick={() => handleClick(dt._id)}>
                                          <img
                                            src="https://webcdn.imarticus.org/Demo/Group5557.svg"
                                            className="as-dl-logo-job"
                                            alt="Delete Job"
                                          />
                                        </div>
                                      )}
                                  </div>
                                )}
                              </TableCell>
                              <TableCell className="btch-dt-td" scope="row">
                              {
                                (checkVisibility('43', 2) && dt.job_status >= 3) ? (
                                <Button className="labs-table-btn-1"
                                  onClick={() => {setOpenJobUpdate(true)
                                    if(dt.job_status > 3){
                                      setChangedJobStatus({_id: dt.job_status, name: JOB_STATUS[dt.job_status]})
                                    }
                                    setSelectedJob(dt._id)
                                    console.log(dt.drive_end_date)
                                    if(dt.drive_end_date != ""){
                                      const dateString = dt.drive_end_date.split('T')[0]
                                      setDriveEndDate(dateString)
                                    }
                                  }}
                                >Update Job Status
                                </Button>
                                ) : ('-')}
                              </TableCell>
                            </TableRow>
                          )
                        })}

                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginRight: '256px' }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Row>

            ) :
              <div className="job-not-found">
                <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                <div style={{ marginBottom: '1rem' }}>
                  <br></br>
                  <h5>It's empty here!</h5>
                </div>
                <div>
                  <p style={{ marginLeft: '1rem' }}>First create a job by clicking on</p>
                  <p>"Create a Job" to view jobs here</p>
                </div>
              </div>
            }
            <Modal isOpen={openJobCreate}>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center',fontSize: "20px",fontWeight: "600" }}>
                  Create a Job{' '}
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600"}}>
                      Enter Opportunity ID
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="opportunityID"
                        value={opportunityId}
                        onChange={handleCreateOppIdChange}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                    {errorMessage && <FormText color="danger">{errorMessage}</FormText>}
                    {/* <p>opportunity id already exist</p> */}
                  </div>
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' , fontSize: "16px",fontWeight: "600"}}>
                      Enter Company Name
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Autocomplete

                        disableClearable
                        value={companyName}
                        onChange={(event, location) => {
                          selectedCompanyChanged(location.name, location._id, 0)
                        }}
                        getOptionLabel={(batch) => {
                          return batch.name || batch
                        }}
                        getOptionSelected={(option, test) => option.name == test}
                        id="batchSelector"
                        options={companyDropdown}
                        disabled={companyDropdown.length === 0 ? true : false}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )
                        }}
                      />
                    </FormGroup>
                    <p>Can't find your company? Go to <a href="/career-services/placement-companies">Placement Companies</a> to add new companies</p>
                  </div>
                </li>
                <li className='row'>
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Selected Company's Short Code Name
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="courseShortCode"
                        value={companyShortCode}
                        onChange={(event, oppID) => {
                          setCompanyShortCode(event.target.value)
                        }}
                        style={{ width: '98%' }}
                        disabled
                      />
                    </FormGroup>
                  </div>

                </li>
                <li className="row">
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Enter Company HR Name
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="companyHRName"
                        value={hrname}
                        onChange={(event, oppID) => {
                          setHrname(event.target.value)
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Enter Company HR Email ID
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        type="text"
                        name="text"
                        id="companyHREmail"
                        value={hremail}
                        onChange={(event, oppID) => {
                          setHremail(event.target.value)
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Enter Company HR Mobile No.
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <div className='row'>
                        <div style={{ width: '100px', paddingRight: '12px', paddingLeft: '12px' }}>
                          <Input
                            name="mobileCountryCode"
                            placeholder="Country Code"
                            type="select"
                            value={hrccod}
                            onChange={(event, oppID) => {
                              setHrccod(event.target.value)
                            }}
                          >
                            {Object.keys(COUNTRY_CODES).map((cc) => {
                              return (
                                <option key={cc} value={cc}>
                                  +{cc}
                                </option>
                              )
                            })}
                          </Input>
                        </div>
                        <Input
                          type="text"
                          name="text"
                          id="companyHRMobile"
                          value={hrmobile}
                          onChange={(event, oppID) => {
                            setHrmobile(event.target.value)
                          }}
                          style={{ width: '75%' }}
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Select Number of Open Positions
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        name="text"
                        type="number"
                        id="totalOpenPositions"
                        value={totalPositions}
                        onChange={(event, oppID) => {
                          setTotalPositions(event.target.value)
                        }}
                        style={{ width: '98%' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Enter Opportunity Won Date
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <input
                        type="datetime-local"
                        className="labs-create-new-form-date_input-2"
                        name="oppWonDate"
                        value={oppWonDate}
                        required={true}
                        onChange={(event, oppID) => {
                          setOppWonDate(event.target.value)
                        }}
                      // min={this.state.currentFormattedDate}
                      // max={this.state.finalFormattedDate}
                      />
                    </FormGroup>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-6">
                    {hoverImage ? <div className="showJobPopupSkill">
                      Select Training location & assign open positions
                    </div> : ''}
                    <div className='row'>
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginLeft: '12px', marginTop: '1rem', marginRight: '10px', fontSize: "16px",fontWeight: "600" }}>
                        Select Training Location
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <img src='https://webcdn.imarticus.org/webinar/Vector1.svg' style={{ objectFit: "contain" }} onMouseEnter={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)} />
                    </div>
                    <FormGroup>
                      <div style={{ width: "98%" }}>
                        <Autocomplete
                          disableClearable
                          value={listExtraLocations && locationDropdown.find(location => location._id == listExtraLocations[0].id)?.name || ""}
                          onChange={(event, location) => {
                            dropdownlocationChanged(location.name, location._id, 0)
                          }}
                          getOptionLabel={(batch) => {
                            return batch.name || batch
                          }}
                          getOptionSelected={(option, test) => option.name == test}
                          id="batchSelector"
                          options={locationDropdown}
                          disabled={locationDropdown.length === 0 ? true : false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Total No. of Openings
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    <FormGroup>
                      <Input
                        name="text"
                        type="number"
                        id="numOpenPosition1"
                        value={listExtraLocations && listExtraLocations.length > 0 ? listExtraLocations[0].num_openings : 0}
                        onChange={(event, oppID) => {
                          dropdownNumOpeningsChanged(event.target.value, 0)
                        }}
                        style={{ width: '98%', height: '52px' }}
                      />
                    </FormGroup>
                  </div>
                </li>
                {listExtraLocations.slice(1).map((item, index) => (
                  <li className="row" key={index}>
                    <div className="col-md-6">
                      <div className='row'>
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginLeft: '12px', marginTop: '1rem', marginRight: '10px', fontSize: "16px",fontWeight: "600" }}>
                          Select Training Location
                        </h5>
                      </div>
                      <div style={{ width: "98%" }}>
                        <Autocomplete
                          disableClearable
                          value={listExtraLocations && locationDropdown.find(location => location._id == listExtraLocations[index + 1].id)?.name || ""}
                          onChange={(event, location) => {
                            dropdownlocationChanged(location.name, location._id, index + 1)
                          }}
                          getOptionLabel={(batch) => {
                            return batch.name || batch
                          }}
                          getOptionSelected={(option, test) => option.name == test}
                          id={item.id}
                          options={locationDropdown}
                          disabled={locationDropdown.length === 0 ? true : false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                        Total No. of Openings
                      </h5>
                      <FormGroup style={{ display: "flex", justifyContent: "space-between" }}>
                        <Input
                          name="text"
                          type="number"
                          id={item.id}
                          value={listExtraLocations && listExtraLocations[index + 1].num_openings}
                          onChange={(event, oppID) => {
                            dropdownNumOpeningsChanged(event.target.value, index + 1)
                          }}
                          style={{ width: '90%', height: '52px' }}
                        />
                        <div className='darken-on-hover' style={{ width: "32px", height: "32px", background: "red", marginTop: "5px" ,borderRadius: "4px"}} onClick={() => deleteAdditionalLocation(index + 1)} >
                          <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{ objectFit: "contain" }} />
                        </div>
                      </FormGroup>

                    </div>
                  </li>
                ))}
                <li className='row'>
                  <div className='col-md-12'>
                    <button
                      className='addques-newoption row'
                      onClick={addAdditionalLocation}
                    >
                      <span
                        className=''
                      >
                        +
                      </span>
                      <span
                        className=''
                      >
                        Add More Training Locations
                      </span>
                    </button>
                  </div>
                </li>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{ background: "#DC3545" }}
                  onClick={() => setOpenJobCloseModal(true)
                  }
                >
                  Cancel
                </Button>
                <Button
                  style={{ background: "#014644" }}
                  onClick={() => handleJobCreation()}
                >
                  Create
                </Button>

              </ModalFooter>
            </Modal>
          </div>
        </div>
        <Modal style={{width: "40%"}} isOpen={openJobUpdate} centered>
              <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center',fontSize: "20px",fontWeight: "600" }}>
                  Update Job{' '}
                </div>
              </ModalHeader>
              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <li className="row">
                <div className="col-md-12">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem', fontSize: "16px",fontWeight: "600" }}>
                      Change Drive End Date
                    </h5>
                    <FormGroup>
                      <input
                        type="date"
                        className="labs-create-new-form-date_input-2"
                        name="oppWonDate"
                        value={driveEndDate}
                        required={true}
                        onChange={(event, oppID) => {
                          console.log({drive_end:event.target.value })
                          setDriveEndDate(event.target.value)
                        }}
                      // min={this.state.currentFormattedDate}
                      // max={this.state.finalFormattedDate}
                      />
                    </FormGroup>
                  </div>
                  
                </li>
                <li className="row">
                <div className="col-md-12">
                    <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' , fontSize: "16px",fontWeight: "600"}}>
                      Change Job Status
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </h5>
                    
                    <FormGroup>
                      <Autocomplete
                        disableClearable
                        value={changedJobStatus && changedJobStatus.name}
                        onChange={(event, location) => {
                          console.log({changedStatus:location})
                          setChangedJobStatus(location)
                        }}
                        getOptionLabel={(batch) => {
                          return batch.name || batch
                        }}
                        getOptionSelected={(option, test) => option.name == test}
                        id="batchSelector"
                        options={jobStatusDropdown}
                        disabled={jobStatusDropdown.length === 0 ? true : false}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )
                        }}
                      />
                    </FormGroup>
                  </div>
                </li>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{ background: "#DC3545" }}
                  onClick={() => {
                    setOpenJobUpdate(false)
                    setDriveEndDate('')
                    setSelectedJob('')
                    setChangedJobStatus({})
                  }
                  }
                >
                  Cancel
                </Button>
                <Button
                  style={{ background: "#014644" }}
                  onClick={() => {
                    handleJobStatusChange(selectedJob)
                    // setOpenJobUpdate(false)
                  }
                }
                >
                  Update
                </Button>

              </ModalFooter>
            </Modal>
      </>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(JobPostings)
