export const LABS_STATUS = {
  1: 'Pending',
  2: 'Processing',
  3: 'Machine Created',
  4: 'IP Allocated',
  5: 'System Setup Started',
  6: 'Setup Complete',
  7: 'Shutdown',
  8: 'Snoozed',
  9: 'Active',
  10: 'Destroyed',
  11: 'Failed',
  12: 'Failed: Orphan',
  13: 'Failed: Backup Possible',
}
export const LABS_REQUEST_STATUS = {
  1: 'Active',
  2: 'Inactive',
}

export const LABS_SERVER = {
  1: 'AWS',
}

export const LABS_OS = {
  1: 'Ubuntu',
  2: 'Windows',
}

export const LABS_TYPES = {
  1: 'Image Id Based',
  2: 'Ansible Script Based',
}

export const EMAIL_PROVIDER_TYPE = {
  1: 'AWS',
}

export const EMAIL_STATUS = {
  1: 'Sent',
  2: 'Delivered',
  3: 'Failed',
  4: 'Bounced',
}

export const EMAIL_PROVIDER_STATUS = {
  1: 'Sent',
  2: 'Rendering Failure',
  3: 'Rejected',
  4: 'Delivered',
  5: 'Bounced',
  6: 'Complaint',
  7: 'Delivery Delay',
  8: 'Subscription',
}

export const GRADUATION_STATUS = {
  Uninitiated: 1,
  Initiated: 2,
  Seeded: 3,
  Ripened: 4,
  Graduated: 5,
  ALL: 'ALL',
}
export const CAN_CRT_CRS = {
  COURSE_ADMIN: 'Course Admin',
  COURSE_ADMIN_B2B: 'Course Admin B2B',
}
export const SALES_LEVELS = [
  'Advisor',
  'Sales Head',
  'Business Head',
  'Finance Head',
  'Super Admin',
]

export const GRADUATION_STATUS_VALUES = {
  1: 'Uninitiated',
  2: 'Initiated',
  3: 'Seeded',
  4: 'Ripened',
  5: 'Graduated',
  ALL: 'ALL',
}

export const ACTIVITY_STATUS_VALUES = {
  1: 'New',
  2: 'Active',
  3: 'Inactive',
  4: 'Dead',
  ALL: 'ALL',
}

export const ACTIVITY_STATUS = {
  New: 1,
  Active: 2,
  Inactive: 3,
  Dead: 4,
  ALL: 'ALL',
}

export const CODING_ASSIGNMENT_TYPE = 4

export const COMPILER_EXECUTION_STATUS = {
  11: 'Compilation Error',
  12: 'Runtime Error',
  13: 'Time Limit Exceeded',
  14: 'Wrong Answer',
  15: 'Accepted',
  17: 'Memory limit exceeded',
  19: 'Illegal System Call',
  20: 'Internal Error',
}

export const COMPILER_EXECUTION_STATUS_VALUE = {
  COMPILATION_ERROR: 11,
  RUN_TIME_ERROR: 12,
  TIME_LIMIT_EXCEEDED: 13,
  WRONG_ANSWER: 14,
  ACCEPTED: 15,
  MEMORY_LIMIT_EXCEEDED: 17,
  ILLEGAL_sYSTEM_CALL: 18,
  INTERNAL_ERROR: 19,
}

export const LANGUAGE_MODES = {
  C: 'c_cpp',
  'C++': 'c_cpp',
  'C++14': 'c_cpp',
  'C++ 4.3.2': 'c_cpp',
  Java: 'java',
  'JavaScript (rhino)': 'javascript',
  'JavaScript (spidermonkey)': 'javascript',
  'JavaScript(Node.js)': 'javascript',
  PHP: 'php',
  Python: 'python',
  'Python (Pypy)': 'python',
  'Python 3': 'python',
  R: 'r',
  Ruby: 'ruby',
}
export const TEST_TYPES = {
  1: 'GENERAL',
  2: 'COMPREHENSION',
  3: 'MULTIPLE_CORRECT',
  4: 'MATCHING',
  5: 'ASSERTION_REASON',
  6: 'SUBJECTIVE',
  7: 'FILL_BLANKS',
  8: 'TRUE_FALSE',
}
export const POPOVER_TYPE = {
  ERROR: 1,
  SUCCESS: 2,
};
export const SIDEBARS = {
  HOME_SIDEBAR: 1,
  GROUP_SIDEBAR: 2,
};
export const POPOVER_HIDETIME = 3000;
export const URLS = {
  SEND_FEEDBACK_URL:
    "mailto:support@imarticus.com?subject=Feedback from Imarticus Web",
  HELP_LEARNING_URL: "https://imarticus.org/contact/",
  DEFAULT_PPIC_URL: "https://cdn.pegasus.imarticus.org/images/Profile-01.svg",
  DEFAULT_GPIC_URL: "https://cdn.pegasus.imarticus.org/images/groups-01-01.svg",
};
export const QUESTION_TYPES = {
  1: 'single mcq',
  3: 'Subjective',
  5: 'weighted_mcq',
}
export const PUBLISH_TYPE = {
  1: 'PUBLISHED',
  2: 'SELECTIVELY PUBLISHED',
  3: 'UNPUBLISHED',
}

export const placementSidebarMap = {
  JOB_POSTINGS: "Job Postings",
  PENDING_JOB_REVIEW: "Pending Job Reviews",
  APPLICATIONS: "Application Status",
  EXCEPTION_APPROVAL: "Exception Approval Requests",
  RESUME_REVIEWS: "Resume Reviews",
};

export const JOB_STATUS = {
    1:'PENDING',
    2:'NOT_PUBLISHED',
    3:'LIVE',
    4:'HIRING_COMPLETE',
    5:'CLOSED',
  }

export const REVIEW_STATUS = {
    1:'UNDER_REVIEW',
    2:'APPROVED',
    3:'REJECTED',
    4:'FLAGGED',
  }

export const JOB_RELEVANCY ={
    1:'RELEVANT',
    2:'PARTIAL_RELEVANT',
    3:'NOT_RELEVANT',
  }

export const STATIC_ROUNDS ={
    1:'Eligible',
    2:'Applied',
    3:'In Progress',
    4:'Selected',
    6:'Rejected',
    7: 'Internal Reject',
    8: 'External Reject'
  }  

export const OFFER_STATUS ={
    0:'Pending',
    1:'Approved',
    2:'Rejected',
  }  

export  const BOOLEAN_DROPDOWN = [
    { label: "Yes", value: 'true' },
    { label: "No", value: 'false' },
];
  
export  const GENDER_DROPDOWN = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];  

export const BOARD_DROPDOWN = [
  { label: '', value: '0' },
  { label: "CBSE", value: "CBSE" },
  { label: "ICSE", value: "ICSE" },
  { label: "IB", value: "IB" },
  { label: "State Board", value: "State Board" },
]

export const BACHELORS_DROPDOWN = [
  { label: "Bachelor of Commerce (BCOM)", value: "Bachelor of Commerce (BCOM)" },
  { label: "Bachelor of Business Management (BBM)", value: "Bachelor of Business Management (BBM)" },
  { label: "Bachelor of Arts (BA)", value: "Bachelor of Arts (BA)" },
  { label: "Bachelor of Science (BSC)", value: "Bachelor of Science (BSC)" },
  { label: "Bachelor of Economics (BEconomics)", value: "Bachelor of Economics (BEconomics)" },
  { label: "Bachelor of Business Administration (BBA)", value: "Bachelor of Business Administration (BBA)" },
  { label: "Bachelor of Technology (B.Tech)", value: "Bachelor of Technology (B.Tech)" },
  { label: "Bachelor of Engineering (BE)", value: "Bachelor of Engineering (BE)" },
  { label: "Bachelor of Computer Applications (BCA)", value: "Bachelor of Computer Applications (BCA)" },
  { label: "Others", value: "Others" },
]

export const MASTERS_DROPDOWN = [
  { label: "Masters of Commerce (MCOM)", value: "Masters of Commerce (MCOM)" },
  { label: "Masters of Bussiness Management (MBM)", value: "Masters of Bussiness Management (MBM)" },
  { label: "Masters of Arts (MA)", value: "Masters of Arts (MA)" },
  { label: "Masters of Science (MSC)", value: "Masters of Science (MSC)" },
  { label: "Masters of Economics (MEconomics)", value: "Masters of Economics (MEconomics)" },
  { label: "Masters of Business Administration (MBA)", value: "Masters of Business Administration (MBA)" },
  { label: "Masters of Technology (M.Tech)", value: "Masters of Technology (M.Tech)" },
  { label: "Masters of Engineering (ME)", value: "Masters of Engineering (ME)" },
  { label: "Masters of Computer Applications (MCA)", value: "Masters of Computer Applications (MCA)" },
  { label: "Others", value: "Others" },,
]

export const PASSING_YEAR = [
  { value: '2005', label: '2005' },
  { value: '2006', label: '2006' },
  { value: '2007', label: '2007' },
  { value: '2008', label: '2008' },
  { value: '2009', label: '2009' },
  { value: '2010', label: '2010' },
  { value: '2011', label: '2011' },
  { value: '2012', label: '2012' },
  { value: '2013', label: '2013' },
  { value: '2014', label: '2014' },
  { value: '2015', label: '2015' },
  { value: '2016', label: '2016' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
]

export const SKILL_CONDITION = [
  { value: 1, label: 'Greater Than (>)' },
  { value: 2, label: 'Less Than (<)' },
  { value: 3, label: 'Equals To (=)' },
]

export const FILTER_CONDITION = [
  { value: 1, label: 'Greater Than (>)' },
  { value: 2, label: 'Less Than (<)' },
  { value: 3, label: 'Equals To (=)' },
  { value: 4, label: 'Greater Than Equals To (>=)' },
  { value: 5, label: 'Less Than Equals To (<=)' },
]

export const JOIN_CONDITION = [
  { value: 1, label: 'AND' },
  { value: 2, label: 'OR' },
]

export const PERIODICITY = [
  { value: 'h', label: 'Hourly' },
  { value: 'd', label: 'Daily' },
  { value: 'w', label: 'Weekly' },
]

export const PLACEMENT_ELIGIBILITY = {
  0:'INELIGIBLE',
  1:'ELIGIBLE',
  2:'EXCEPTION',
  3:'PLACED',
}

export const PLACEMENT_STATUS = {
  0:'NOT PLACED',
  1:'PLACED',
  2:'SELF PLACED',
}

export const PORTAL_STATUS = {
 0: 'NOT ASSIGNED',
 1: 'ASSIGNED',
 2: 'UNASSIGNED',
}


export const JOB_EXPERIENCE = [
  { value: '0', label: 'Fresher' },
  { value: '1', label: '1 Yrs' },
  { value: '2', label: '2 Yrs' },
  { value: '3', label: '3 Yrs' },
  { value: '4', label: '4 Yrs' },
  { value: '5', label: '5 Yrs' },
  { value: '6', label: '6 Yrs' },
  { value: '7', label: '7 Yrs' },
  { value: '8', label: '8 Yrs' },
  { value: '9', label: '9 Yrs' },
  { value: '10', label: '10 Yrs' },
  { value: '11', label: '11 Yrs' },
  { value: '12', label: '12 Yrs' },
]

export const MAX_PPT_FILE_SIZE = 100 * 1024 * 1024
// export const valid_hosts = [
//   { id: 'nikhar.dalal@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'aasiya.dilnasheen@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'akshata.chavan@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'alaknanda.nagare@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'ananya.sharma@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'anil.gupta@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'anjali.berwal@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'anshul.aggarwal@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'brundha.vishnuprakash@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'jinal.boricha@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'jyoti.gohil@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'khaleeq.kuchikar@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'kiran.khamitkar@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'mehul.raja@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'mohammed.malik@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'mohana.kumari@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'monika.mali@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'nidhi.bagla@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'nitin.kumar@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'rohit.sharma@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'sadaf.khan@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'shashank.singh@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'sherish.shaikh@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'siddharth.mehta@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'steve.phillips@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'surbhi.sharma@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer11@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer12@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer15@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer1@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer2@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer3@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer4@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer5@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer6@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer7@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'trainer8@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'uday.bhasker@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'utkarsha.desai@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'ved.jha@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'vikrant.tapaskar@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'ayesha.ansari@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'sandip.nagargoje@imarticus.com',
//     parent: 'nikhar.dalal@imarticus.com',
//   },
//   { id: 'ahamed.khalid@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'priyanka.sharma@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'joy.bhalshankar@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'krishna.giri@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'aarti.adhav@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   { id: 'imran.syed@imarticus.com', parent: 'nikhar.dalal@imarticus.com' },
//   {
//     id: 'sarang.corptrainer@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'apurva.sheth@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'edwardsaldahna02@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   { id: 'miti.shah@imarticus.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   {
//     id: 'nidhi.sharma@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'priya.pathare@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   { id: 'sarangsurya@outlook.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   {
//     id: 'Shaunak.ghose@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'suresh.rao@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'vikas.singh@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   { id: 'raj.goyal@imarticus.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   { id: 'mitishah906@gmail.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   { id: 'gauravbish29@gmail.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   {
//     id: 'vikassingh4219@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'vikassinghtraining@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'Vikassingh5219@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'owais.shaikh@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'shaunakghose22@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'kulin.dedhia@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'shaunak.fst.icfai@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   { id: 'thisissarangs@gmail.com', parent: 'sarang.suryavanshi@imarticus.com' },
//   {
//     id: 'shantanu.kamble@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'shaunak.imarticus@gmail.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'rajesh.chandrashekar@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
//   {
//     id: 'corporate.delivery@imarticus.com',
//     parent: 'sarang.suryavanshi@imarticus.com',
//   },
// ]
let index = 0
if (process.env.REACT_APP_BUILD_ENV == 'testing') index = 1
if (process.env.REACT_APP_BUILD_ENV == 'staging') index = 2
if (process.env.REACT_APP_BUILD_ENV == 'production') index = 3
export const EXAM_DASHBOARD_URL = [
  'http://localhost:3011/#/',
  'https://examdasht.pegasust.imarticus.org/#/',
  'https://examdashs.pegasuss.imarticus.org/#/',
  'https://examdash.pegasus.imarticus.org/#/',
][index]

export const COURSE_PAGES_URL = [
  'http://localhost:3017',
  'https://coursest.pegasust.imarticus.org/',
  'https://coursess.pegasuss.imarticus.org/',
  'https://courses.pegasus.imarticus.org/',
][index]

export const HOME_PAGE_URL = [
  'http://localhost:7015/',
  'https://pegasust.imarticus.org/',
  'https://pegasuss.imarticus.org/',
  'https://pegasus.imarticus.org/',
][index]

export const PATH = '/'
export const DOMAIN = [
  'localhost',
  '.imarticus.org',
  '.imarticus.org',
  '.imarticus.org',
][index]
export const COUNTRY_CODES = {
  93: 'AF',
  355: 'AL',
  213: 'DZ',
  684: 'AS',
  376: 'AD',
  244: 'AO',
  1264: 'AI',
  672: 'AQ',
  1268: 'AG',
  54: 'AR',
  374: 'AM',
  297: 'AW',
  61: 'AU',
  43: 'AT',
  994: 'AZ',
  1242: 'BS',
  973: 'BH',
  880: 'BD',
  1246: 'BB',
  375: 'BY',
  32: 'BE',
  501: 'BZ',
  229: 'BJ',
  1441: 'BM',
  975: 'BT',
  591: 'BO',
  387: 'BA',
  267: 'BW',
  55: 'BR',
  246: 'IO',
  673: 'BN',
  359: 'BG',
  226: 'BF',
  257: 'BI',
  855: 'KH',
  237: 'CM',
  1: 'CA',
  238: 'CV',
  1345: 'KY',
  236: 'CF',
  235: 'TD',
  56: 'CL',
  86: 'CN',
  61: 'CX',
  891: 'CC',
  57: 'CO',
  269: 'KM',
  242: 'CG',
  243: 'CD',
  682: 'CK',
  506: 'CR',
  385: 'HR',
  53: 'CU',
  357: 'CY',
  420: 'CZ',
  45: 'DK',
  253: 'DJ',
  1767: 'DM',
  809: 'DO',
  593: 'EC',
  20: 'EG',
  503: 'SV',
  240: 'GQ',
  291: 'ER',
  372: 'EE',
  251: 'ET',
  500: 'FK',
  298: 'FO',
  679: 'FJ',
  358: 'FI',
  33: 'FR',
  594: 'GF',
  689: 'PF',
  241: 'GA',
  220: 'GM',
  995: 'GE',
  49: 'DE',
  233: 'GH',
  350: 'GI',
  44: 'GBR',
  30: 'GR',
  299: 'GL',
  1473: 'GD',
  590: 'GP',
  1: 'GU',
  502: 'GT',
  44: 'GG',
  224: 'GN',
  245: 'GW',
  592: 'GY',
  509: 'HT',
  504: 'HN',
  852: 'HK',
  36: 'HU',
  354: 'IS',
  91: 'IN',
  62: 'ID',
  98: 'IR',
  964: 'IQ',
  353: 'IE',
  44: 'IM',
  972: 'IL',
  39: 'IT',
  225: 'CIV',
  1876: 'JM',
  81: 'JP',
  962: 'JO',
  7: 'KZ',
  254: 'KE',
  686: 'KI',
  850: 'PRK',
  82: 'KOR',
  965: 'KW',
  996: 'KG',
  856: 'LA',
  371: 'LV',
  961: 'LB',
  266: 'LS',
  231: 'LR',
  218: 'LY',
  423: 'LI',
  370: 'LT',
  352: 'LU',
  853: 'MO',
  389: 'MK',
  261: 'MG',
  265: 'MW',
  60: 'MY',
  960: 'MV',
  223: 'ML',
  356: 'MT',
  692: 'MH',
  596: 'MQ',
  222: 'MR',
  230: 'MU',
  269: 'YT',
  52: 'MX',
  691: 'FM',
  373: 'MD',
  377: 'MC',
  976: 'MN',
  382: 'ME',
  1664: 'MS',
  212: 'MA',
  258: 'MZ',
  95: 'MM',
  264: 'NA',
  674: 'NR',
  977: 'NP',
  31: 'NL',
  599: 'AN',
  687: 'NC',
  64: 'NZ',
  505: 'NI',
  227: 'NE',
  234: 'NG',
  683: 'NU',
  672: 'NF',
  670: 'MP',
  47: 'NO',
  968: 'OM',
  92: 'PK',
  680: 'PW',
  970: 'PS',
  507: 'PA',
  675: 'PG',
  595: 'PY',
  51: 'PE',
  63: 'PH',
  64: 'PN',
  48: 'PL',
  351: 'PT',
  1787: 'PR',
  974: 'QA',
  262: 'RE',
  40: 'RO',
  7: 'RU',
  250: 'RW',
  590: 'BL',
  290: 'SH',
  1869: 'KN',
  1758: 'LC',
  508: 'PM',
  1784: 'VC',
  684: 'WS',
  378: 'SM',
  239: 'ST',
  966: 'SA',
  221: 'SN',
  381: 'RS',
  248: 'SC',
  232: 'SL',
  65: 'SG',
  421: 'SK',
  386: 'SI',
  677: 'SB',
  252: 'SO',
  27: 'ZA',
  500: 'GS',
  211: 'SS',
  34: 'ES',
  94: 'LK',
  249: 'SD',
  597: 'SR',
  268: 'SZ',
  46: 'SE',
  41: 'CH',
  963: 'SY',
  886: 'TW',
  992: 'TJ',
  255: 'TZ',
  66: 'TH',
  670: 'TL',
  228: 'TG',
  690: 'TK',
  676: 'TO',
  1868: 'TT',
  216: 'TN',
  90: 'TR',
  993: 'TM',
  1649: 'TC',
  688: 'TV',
  256: 'UG',
  380: 'UA',
  971: 'AE',
  598: 'UY',
  246: 'UM',
  1: 'US',
  998: 'UZ',
  678: 'VU',
  379: 'VA',
  58: 'VE',
  84: 'VN',
  1284: 'VG',
  1340: 'VI',
  681: 'WF',
  212: 'EH',
  967: 'YE',
  260: 'ZM',
  263: 'ZW',
}

export const AUTHENTICATION_COOKIES = [
  'at',
  'rt',
  'secret',
  'aid',
  'pid',
  'isLoggedIn',
  'crs_tkn',
  'cid',
  'uid',
  's_id',
  'user',
  'profile',
  'ifTokenSent',
]

export const VIMEO_UPLOAD = {
  UPLOAD_SIZE_LIMIT: 20*103809024, // 99 MB
  SUPPORTED_FILE_TYPES: [
    'video/mp4',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-msvideo',
    'video/x-flv',
    'video/avi',
    '',
    'application/octet-stream',
  ],
}
export const TIER_COLOR_CODE = ['#3E86A5', '#6F87BF', '#9D7075', '#E27770']
