import React, { useEffect, useState } from 'react'
import '../../LabsManagement.css'
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'
import { Col, Row } from 'reactstrap'
import { Table } from 'react-bootstrap'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment-timezone'
import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import { Loader } from 'semantic-ui-react'
const configs = require('../../../../config/configs.js')

const LabsSession = () => {
  const [requests, setRequests] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const timezone = 'Asia/Calcutta'

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getRequests = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          admin_aid: cookies.get('aid'),
          page,
          rowsPerPage,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getAllSessions`,
          data,
          config
        )

        if (res.data.data) {
          setRequests(res.data.data.labsSessions)
          setTotalCount(parseInt(res.data.data.totalLabsSessions))
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    getRequests()
  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={4} />
      <div className="labs-main">
        <TopBar />
        <div className="labs-content">
          <Row>
            <Col md={10} className="labs-table-header">
              Labs Session Management
            </Col>

            <Col md={2}>
              <button
                onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                className="labs-table-btn-2"
              >
                Refresh
              </button>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="labs-table-req-head">
                  <TableCell>Lab Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Machine ID</TableCell>
                  <TableCell>Machine Start Time</TableCell>
                  <TableCell>Machine End Time</TableCell>
                  <TableCell>Last Seen</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                </TableHead>
                <tbody>
                  {requests
                    ? requests.map((elem, i) => {
                        return (
                          <tr key={i}>
                            <td>{elem.lab_name}</td>
                            <td>{elem.username_guac}</td>
                            <td>{elem.machine_id}</td>
                            <td>
                              {moment
                                .utc(elem.machine_start_time)
                                .tz(timezone)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                            <td>
                              {elem.machine_end_time
                                ? moment
                                    .utc(elem.machine_end_time)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')
                                : 'N/A'}
                            </td>
                            <td>
                              {elem.last_seen_user
                                ? moment
                                    .utc(elem.last_seen_user)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')
                                : 'N/A'}
                            </td>
                            <td>{elem.price ? `$` + elem.price : 'N/A'}</td>
                            <td>
                              {' '}
                              <span
                                className={`labs-${
                                  elem.is_machine_active ? 'Active' : 'Inactive'
                                }`}
                              >
                                {elem.is_machine_active ? 'Active' : 'Inactive'}
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </Table>
            </TableContainer>

            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LabsSession
