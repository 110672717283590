import React, { useEffect, useState ,Fragment} from 'react'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import Sidebar3 from './Components/Sidebar3'
import './PlacementPortal.scss'
import momentjs from 'moment-timezone'
import '../../Components/style/assignmentDeadline.css'
import TooltipItem from '../ManageGradebookAndCertification/PreviewGradebook/TooltipItem.js'
import { saveAs } from 'file-saver';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import PDFReader from "../../Components/PDFReader/PDFReader";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { COUNTRY_CODES, GENDER_DROPDOWN, BOARD_DROPDOWN, BACHELORS_DROPDOWN, MASTERS_DROPDOWN, PLACEMENT_ELIGIBILITY, PASSING_YEAR, JOB_EXPERIENCE, BOOLEAN_DROPDOWN, SKILL_CONDITION,FILTER_CONDITION, JOIN_CONDITION, PERIODICITY } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import { history } from '../..'
import GradebookModal from '../../Components/GradebookModal/GradebookModal.jsx'
import {
  savePlacementCriteria,
  fetchFilteredCandidates,
  finalizeLearners,
  publishJob,
  updatedEndTime
} from '../../actions/jobAction.js'
const configs = require('../../config/configs.js')
const timezones = momentjs.tz.names()



const AddJobRolloutFilters = () => {
  const [requests, setRequests] = useState([])
  const [openFilterChoice, setOpenFilterChoice] = useState(false)
  const [filterForProduct, setFilterForProduct] = useState({})
  const [filterData, setFilterData] = useState({})
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isAddFilterCollapsed,setIsAddFilterCollapsed] = useState(false)
  const [isJobReminderCollapsed,setIsJobReminderCollapsed] = useState(false)
  const [isAppDateCollapsed,setIsAppDateCollapsed] = useState(false)
  const [resumeURL, setResumeURL] = useState("");
  const [numRule, setNumRule] = useState(0)
  const [rules, setRules] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [eligibleProducts, setEligibleProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedBatches, setSelectedBatches] = useState([])
  const [originalBatches, setOriginalBatches] = useState([])
  const [isJobPublished, setIsJobPublished] = useState(false)
  const [candidatesFetched, setCandidatesFetched] = useState(false)
  const [candidatesRequested, setCandidatesRequested] = useState(false)
  const [eligibleSelected, setEligibleSelected] = useState(true)
  const [ineligibleSelected, setIneligibleSelected] = useState(false)
  const [placedSelected, setPlacedSelected] = useState(false)
  const [numPlacedLearners, setNumPlacedLearners] = useState(0)
  const [numEligibleLearners, setNumEligibleLearners] = useState(0)
  const [numIneligibleLearners, setNumIneligibleLearners] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [addedInfo, setAddedInfo] = useState('')
  const [periodVal, setPeriodVal] = useState(0)
  const [totalLearners, setTotalLearners] = useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [placedCandidates, setPlacedCandidates] = useState([])
  const [eligibleCandidates, setEligibleCandidates] = useState([])
  const [ineligibleCandidates, setIneligibleCandidates] = useState([])
  const [data, setData] = useState([])
  const [batches, setBatches] = useState([])
  const [createdCriteriaId, setCreatedCriteriaId] = useState('')
  const [eligibleSkills, setEligibleSkills] = useState([])
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]
  const [eligibleArray, setEligibleArray] = useState([])
  const [ineligibleArray, setIneligibleArray] = useState([])
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [selectedLearner, setSelectedLearner] = useState({})
  const [openResumeView, setOpenResumeView] = useState(false)
  const [searchNameKey, setSearchNameKey] = useState('')
  const [showPrevFetched,setShowPrevFetched] = useState(true)
  const [selectedStudents, setSelectedStudents] = useState([])
  const [formdata, setFormdata] = useState({
    valid_from: '',
    valid_till: '',
    timezone: 'Asia/Kolkata',
  })
  const [openPublishModal,setOpenPublishModal] = useState(false)
  const [openChangeEndDateModal,setOpenChangeEndDateModal] = useState(false)
  const [openSuccessModal,setOpenSuccessModal] = useState(false)
  const [openGradebookModal, setOpenGradebookModal] = useState(false)
  const [grddata, setGrdData] = useState({})
  const [enableCategory, setEnableCategory] = useState({})
  const [elecNameMap, setElecNameMap] = useState({})
  const [adata, setAdata] = useState({})
  const [grade, setGrade] = useState({})
  const [grades, setGrades] = useState()
  const [assignmentData, setAssignmentData] = useState()
  const [quizData, setQuizData] = useState([])
  const [loading, setLoading] = useState(false)
  const [gradebookLoaded, setGradebookLoaded] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [studentGradebook, setStudentGradebook] = useState([])
  const [collapseRow, setCollapseRow] = useState({})
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [grdPlsStatus, setGrdPlsStatus] = useState({})
  const [showWeightage, setShowWeightage] = useState(false)
  const [showPassMarks, setShowPassMarks] = useState(false)
  const [showPlcStatus, setShowPlcStatus] = useState(false)
  const [placementEnable, setPlacementEnable] = useState(false)
  const [columnSpan, setColumnSpan] = useState(false)
  const [gradebookType, setGradebookType] = useState(1)
  const [isElec, setIsElec] = useState(false)
  const [gradebookSkillTags, setGradebookSkillTags] = useState({})
  const [skillNameMap, setskillNameMap] = useState({})
  const [skillTagPresent, setSkillTagPresent] = useState(false)

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    'Congratulations! You are eligible for Placement Criteria'
  )
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    'You are not eligible for the Placement.  '
  )
  const [isAllEligibleSelected, setIsAllEligibleSelected] = useState(false)
  const [isAllIneligibleSelected, setIsAllIneligibleSelected] = useState(false)
  const [plcPassStatusText, setPlcPassStatusText] = useState('PASS')
  const [plcFailStatusText, setPlcFailStatusText] = useState('FAIL')
  const [showContentStatus, setShowContentStatus] = useState(false)
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();

  let serialNum = 1
  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getEligibleProducts = () => {
      const cookies = new Cookies()
      setIsLoading(true)
      return new Promise((resolve, reject) => {
        let data = {
          jobid: jobid,
          aid: cookies.get('aid'),
        }
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_placementfilter_products`,
          method: 'POST',
          data: data,
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            console.log(res.data.data)
            let tmp_data =
              res.data.data && res.data.data.productDetails
                ? res.data.data.productDetails
                : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                  job_status: tmp_data[d].job_status,
                })
              }
            }
            setEligibleProducts(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getEligibleProducts()
  }, [refresh, page, rowsPerPage])

  useEffect (() => {
    setNumEligibleLearners(eligibleCandidates.length)
    setNumIneligibleLearners(ineligibleCandidates.length)
  }, [eligibleCandidates]
  )


  const finalizeLearnersForCourse = async (
  ) => {

    if(eligibleArray.length == 0 && ineligibleArray.length === 0){
      window.alert("Please select learners to give job access")
    }
    setIsLoading(true)
    let resp = await finalizeLearners(
      selectedProduct._id,
      selectedBatches,
      eligibleArray,
      ineligibleArray,
      filterData,
      rules,
      jobid,
      eligibleCandidates,
      ineligibleCandidates,
      placedCandidates
    )
    if (resp.success) {
      window.alert('Successfully finalised candidates')
      setIsAllEligibleSelected(false)
      setIsAllIneligibleSelected(false)
      ineligibleArray.forEach((aid ) => {
        // const index = ineligibleCandidates.findIndex(candidate => candidate.aid === aid);
        // if (index !== -1) {
        //   const [removedCandidate] = ineligibleCandidates.splice(index, 1);
        //   eligibleCandidates.push(removedCandidate);
        // }
      });
      setIsLoading(false)
      setRefresh(!refresh)
    } else {
      window.alert(resp.message)
      // props.batchChanged(batchName, cid)
      setIsLoading(false)
    }
  }

  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const handleEligibleResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = eligibleCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ eligibleCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const handleIneligibleResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = ineligibleCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ ineligibleCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const handlePlacedResumeModalOpen = (aid) => {
    console.log({ aid })

    const selectedResume = placedCandidates.find((item) => String(item.aid) === String(aid));
    console.log({ placedCandidates })
    console.log({ selectedResume })
    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.resumes_list);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.resumes_list);
    }
    setOpenResumeView(true)
  };

  const publishJobForLearners = async (
  ) => {
    setIsLoading(true)
    let resp = await publishJob(
      // selectedProduct._id,
      jobid,
      formdata,
      selectedPeriod,
      periodVal
    )
    if (resp.success) {
      // window.alert('Job successfully published and learners will be notified')
      setOpenPublishModal(false)
      setOpenSuccessModal(true)
      setIsLoading(false)
      setRefresh(!refresh)
      // history.push(`/career-module/preview-job-description/${jobid}`)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const updateEndTimeForLearners = async (
  ) => {
    setIsLoading(true)
    let resp = await updatedEndTime(
      // selectedProduct._id,
      jobid,
      formdata
    )
    if (resp.success) {
      // window.alert('Job successfully published and learners will be notified')
      setOpenChangeEndDateModal(false)
      // setOpenSuccessModal(true)
      setIsLoading(false)
      setRefresh(!refresh)
      // history.push(`/career-module/preview-job-description/${jobid}`)
    } else {
      window.alert(resp.message)
      setIsLoading(false)
    }
  }

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const handleTotalGradeClick = (cid,aid) => {
    const tmp_data = ineligibleCandidates.find(item => item.aid === aid);

    console.log({tmp_data})
    if(tmp_data){
      console.log("reached here")
      setGrdData(tmp_data.gradeData.grddata)
      setEnableCategory(tmp_data.gradeData.enableCategory)
      setElecNameMap(tmp_data.gradeData.elecNameMap)
      setGrade(tmp_data.gradeData.tgrade)
      setAdata(tmp_data.gradeData.adata)

      if (tmp_data.courseData && tmp_data.gradeData.grddata) {
        console.log("reached here as well")
        setShowContentStatus(tmp_data.courseData.show_content_status)
        setIsElec(tmp_data.courseData.elec_level_index)
        setShowPassMarks(tmp_data.courseData.show_pass_marks)
        setShowWeightage(tmp_data.courseData.show_weightage)
        setGrdPlsStatus(tmp_data.gradeData.grddata.plcStaus)
        let pls_status = tmp_data.courseData.plac_type ? tmp_data.courseData.plac_type.gradebook_show_plcmnt_stts:false
        setShowPlcStatus(pls_status)

        let passStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_text
          : "Congratulations! You are eligible for Placement Criteria";
        let failStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_text
          : "You are not eligible for the Placement.  ";
        let plcpassStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_pass_status_text
          : "PASS";
        let plcfailStatus = tmp_data.courseData.plac_type
          ? tmp_data.courseData.plac_type.plc_fail_status_text
          : "FAIL";

        setPlcPassStatusText(plcpassStatus);
        setPlcFailStatusText(plcfailStatus);
        setShowPlcFailStatus(failStatus);
        setShowPlcPassStatus(passStatus);
        setPlacementEnable(tmp_data.courseData.plcmnt_enable)
        setGrdTotalPerc(tmp_data.gradeData.grddata.grd_total_perc);
        setStudentGradebook([...tmp_data.gradeData.grddata.placementGradebookCategory])

        setOpenGradebookModal(true)
      }
    }
  }

  const allEligibleStudentSelect = async () => {
    // setTotalCheckboxes(0)
    const checkboxes = document.querySelectorAll('.btch-dt-inp')

    if (isAllEligibleSelected) {
      setIsAllEligibleSelected(false)
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      })
      setEligibleArray([])
    } else {
      setIsAllEligibleSelected(true)
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          checkbox.checked = true
        }
      })
      let elgArray = []
      eligibleCandidates.forEach(candidate => {
        elgArray.push(candidate.aid);
      });
      setEligibleArray(elgArray)
    }

  }

  const allIneligibleStudentSelect = async () => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible')

    if (isAllIneligibleSelected) {
      setIsAllIneligibleSelected(false)
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      })
      setEligibleArray([])
    } else {
      setIsAllIneligibleSelected(true)
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          checkbox.checked = true
        }
      })
      let inelgArray = []
      ineligibleCandidates.forEach(candidate => {
        inelgArray.push(candidate.aid);
      });
      setIneligibleArray(inelgArray)
    }

  }

  const singleEligibleStudentSelect = async (aid) => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp') // Select all checkboxes with the specified class name
    // setTotalCheckboxes(props.totalStudents - props.totalUnsubStudents)
    checkboxes.forEach((checkbox) => {
      if (checkbox.id == aid) {
        if (checkbox.checked) {
          // setCheckedCheckboxes((pre) => pre + 1)
          if (eligibleArray.indexOf(aid) === -1) {
            setEligibleArray((prev) => {
              prev.push(aid)
              return [...prev]
            })
          }
        } else {
          // setCheckedCheckboxes((pre) => pre - 1)
          setEligibleArray((prev) => prev.filter((item) => item !== aid))
        }
      }
    })
    console.log("Eligible Array is ", eligibleArray)
  }


  const singleIneligibleStudentSelect = async (aid) => {
    const checkboxes = document.querySelectorAll('.btch-dt-inp-ineligible') // Select all checkboxes with the specified class name
    // setTotalCheckboxes(props.totalStudents - props.totalUnsubStudents)
    checkboxes.forEach((checkbox) => {
      if (checkbox.id == aid) {
        if (checkbox.checked) {
          // setCheckedCheckboxes((pre) => pre + 1)
          if (ineligibleArray.indexOf(aid) === -1) {
            setIneligibleArray((prev) => {
              prev.push(aid)
              return [...prev]
            })
          }
        } else {
          // setCheckedCheckboxes((pre) => pre - 1)
          setIneligibleArray((prev) => prev.filter((item) => item !== aid))
        }
      }
    })
    // console.log("Sub to Unsub array is ", subToUnsubArray)
  }

  const handleEligibleLearners = () => {
    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(true);
    setIneligibleSelected(false);
    setPlacedSelected(false)
  };
  const handleIneligibleLearners = () => {
    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(false);
    setIneligibleSelected(true);
    setPlacedSelected(false);
  };
  const handlePlacedLearners = () => {
    setSearchNameKey("")
    setIsAllEligibleSelected(false)
    setIsAllIneligibleSelected(false)
    setEligibleSelected(false);
    setIneligibleSelected(false);
    setPlacedSelected(true)
  };

  useEffect(() => {
    console.log({ formdata })
  }, [formdata])

  const getJobSkillData = (crs_pg_id) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_product_skilltags`,
        method: 'POST',
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { selectedProducts: Array.isArray(crs_pg_id) ? crs_pg_id : [crs_pg_id] },
      }
      Axios(getData)
        .then((res) => {
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data
              ? res.data.data
              : []
          let result = []
          setEligibleSkills(tmp_data.skillTags)
          // const selectedSkillsObj = [];
          // if(tmp_data.skillTags.length > 0){
          //   alreadyAddedSkills.forEach(index => {
          //     const gender = tmp_data.skillTags.find(gender => gender.value == index);
          //     if (gender) {
          //       selectedSkillsObj.push(gender);
          //     }
          //   });
          //   setSelectedSkills(selectedSkillsObj)
          // }
          // setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleBulkDownload = () => {
      if (selectedLearner && selectedLearner.resumes_list) {
        console.log("here")
        selectedLearner.resumes_list.forEach((resume) => {
          let count = 1
          if (resume.resume_type === 2) {
            fetch(resume.resume_url)
              .then((response) => response.blob())
              .then((blob) => {
                saveAs(blob, `${selectedLearner.aid}_${count}.pdf`);
              })
              .catch((error) => console.error('Error downloading resume:', error));
          }
          count++
        });
      }
  };

  const getAllBatches = (crs_pg_id) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelCourse/ls_btch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : []
          let result = []
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].btch_name) {
              result.push({
                // name: tmp_data[d].btch_name,
                // _id: tmp_data[d]._id,
                label: tmp_data[d].btch_name,
                value: tmp_data[d]._id,
              })
            }
          }
          if (!result.length) {
            alert('No batches found at this location')
          }
          // result.unshift({ name: '', _id: null })
          setBatches(result)
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  function convertUnixToDatetimeLocal(unixTimestamp) {
    const date = new Date(unixTimestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const getJobFilterData = (crs_pg_id) => {
    const cookies = new Cookies()
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      let data = {
        jobid: jobid,
        aid: cookies.get('aid'),
        crs_pg_id: crs_pg_id
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_placementfilter_data`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data
              ? res.data.data
              : []
          let result = []
          let filterData = {};

          for (let key in tmp_data.combinedFilterFields) {
            if (tmp_data.combinedFilterFields.hasOwnProperty(key)) {

              if (!['notice_period', 'grad_grade', 'matric_grade', 'inter_grade', 'total_exp'].includes(key)) {
                filterData[key] = {
                  key: tmp_data.combinedFilterFields[key],
                  selected: false,
                  type: 1,
                  condition: 1,
                  value: []
                };
              } else {
                if (!['grad_grade', 'matric_grade', 'inter_grade'].includes(key)) {
                if(key == 'total_exp'){
                  filterData[key] = {
                    key: tmp_data.combinedFilterFields[key],
                    selected: true,
                    type: 2,
                    condition: 1,
                    value: 0
                  };
                }else{
                  filterData[key] = {
                    key: tmp_data.combinedFilterFields[key],
                    selected: false,
                    type: 2,
                    condition: 1,
                    value: 0
                  };
                }
                }else{
                  filterData[key] = {
                    key: tmp_data.combinedFilterFields[key],
                    selected: false,
                    type: 3,
                    condition_cgpa: 1,
                    condition_perc: 1,
                    value_cgpa: 0,
                    value_perc: 0
                  };
                }
                
              }

            }
          }
          console.log({ filterData })
          setFilterForProduct(tmp_data.combinedFilterFields)
          setFilterData(filterData)
          if (tmp_data.placementJobFilter && Object.keys(tmp_data.placementJobFilter).length !== 0) {

            const selectedAid = tmp_data.placementJobFilter.selected_aid;
            setSelectedStudents(selectedAid)
            setFilterData(tmp_data.placementJobFilter.filterData)
            setRules(tmp_data.placementJobFilter.rules)
            setSelectedBatches(tmp_data.placementJobFilter.selected_batches)
            setOriginalBatches(tmp_data.placementJobFilter.selected_batches)
            // const updatedBatches = batches.map(batch => {
            //   const isFixed = selectedBatches.some(selectedBatch => selectedBatch.value === batch.value);
            //   return {
            //     ...batch,
            //     isFixed: isFixed ? true : batch.isFixed
            //   };
            // });
            // setBatches(updatedBatches);
            setPeriodVal(tmp_data.placementJobFilter?.reminder_val)
            setSelectedPeriod(tmp_data.placementJobFilter?.reminder_freq)

            const stimLocal = convertUnixToDatetimeLocal(tmp_data.placementJobFilter?.stim)
            const etimLocal = convertUnixToDatetimeLocal(tmp_data.placementJobFilter?.etim)
            setFormdata({
              ...formdata,
              valid_from: stimLocal,
              valid_till: etimLocal
            });
            const eligibleCandidates = [...tmp_data.placementJobFilter.eligible_learners];
            const ineligibleCandidates = [...tmp_data.placementJobFilter.noneligible_learners];

            selectedAid.forEach(aid => {
              // const index = ineligibleCandidates.findIndex(candidate => candidate.aid === aid);

              // if (index !== -1) {
              //   const [candidate] = ineligibleCandidates.splice(index, 1);

              //   eligibleCandidates.push(candidate);

              //   singleEligibleStudentSelect(aid);
              // }
            });

            setEligibleCandidates(eligibleCandidates);
            setIneligibleCandidates(ineligibleCandidates);

            setNumEligibleLearners(eligibleCandidates.length)
            setNumIneligibleLearners(ineligibleCandidates.length)
            setTotalLearners(eligibleCandidates.length + ineligibleCandidates.length)
            setCandidatesFetched(true)
            setCandidatesRequested(true)

          }

          setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const productChanged = (product) => {
    setSelectedProduct(product)
    getJobFilterData(product._id)
    getAllBatches(product._id)
    getJobSkillData(product._id)

    if (product.job_status > 2) {
      setIsJobPublished(true)
    }
    setEligibleCandidates([])
    setIneligibleCandidates([])
    setPlacedCandidates([])

  }

  const fetchJobApplicants = () => {
    console.log("candidates fetched")
    // setOpenJobCreate(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleModelChange = (model) => {
    setAddedInfo(model);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const singleFilterSelect = (key) => {
    setFilterData((prevFilterForProduct) => {
      if (prevFilterForProduct[key]) {
        return {
          ...prevFilterForProduct,
          [key]: {
            ...prevFilterForProduct[key],
            selected: !prevFilterForProduct[key].selected
          }
        };
      }
      return prevFilterForProduct;
    });
  };


  const handleSelectedPeriodChange = (e) => {
    setSelectedPeriod(e.value);
  }

  const updateFormdata = (e) => {
    console.log({ formdata })
    console.log('updateFormadata is running')
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
    // setMaxValidTill(new Date(props.finalCourseAccessDate))
  }

  const handleFilterChange = (key, value) => {
    console.log({ key })
    console.log({ value })
    setFilterData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value: value,
      },
    }));
  };

  const handleDualFilterChange = (key, valkey, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [valkey]: value,
      },
    }));
  };

  const handleTypeThreeFilterChange = (key, valkey, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [valkey]: value,
      },
    }));
  };

  const deleteRule = (index) => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    setRules(prevRules => {
      const updatedRules = prevRules.filter(rule => rule.index !== index);
      
      const reindexedRules = updatedRules.map((rule, newIndex) => ({
        ...rule,
        index: newIndex
      }));
  
      return reindexedRules;
    });
  };

  const deleteSubRule = (index,subruleIndex) => {
    setRules(prevRules => {
      const updatedRules = prevRules.map(rule => {
        if (rule.index === index) {
          return {
            ...rule,
            subrules: []
          };
        }
        return rule;
      });
      const reindexedRules = updatedRules.map((rule, newIndex) => ({
        ...rule,
        index: newIndex
      }));
  
      return reindexedRules;
    });
  };

  const handleRuleChange = (num) => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    const newRule = {
      index: num - 1,
      key: "",
      value: 0,
      type: "",
      condition: "",
      subrules: []
    };

    setRules(prevRules => [...prevRules, newRule]);
    console.log({ newRule });
  };

  const handleSubruleAddition = (key, value) => {
    const rule = rules[key];
    if (rule.subrules.length >= 1) {
      window.alert("Number of subrules cannot exceed 1");
      return;
    }
    const newSubRule = {
      key: "",
      value: 0,
      type: "",
      condition: "",
      join: 0
    };
    const updatedRules = rules.map((rule, index) => {
      if (index === key) {
        return {
          ...rule,
          subrules: [...rule.subrules, newSubRule]
        };
      }
      return rule;
    });
    console.log({ rules })
    console.log({ key })
    console.log({ updatedRules })
    setRules(updatedRules);
  };

  const handleSelectedBatchChange = (value, action) => {
    console.log({ value })
    console.log({ action })
    if (action.action !== "remove-value" && action.action !== "clear") {
      const selectedBatch = value.map(option => option.value);
      setSelectedBatches(selectedBatch);
    }else if(action.action === "remove-value"){
      const selectedBatch = value.map(option => option.value);
      const allOriginalExist = originalBatches.every(batch => selectedBatch.includes(batch));

      if (allOriginalExist) {
        console.log(true);
        setSelectedBatches(selectedBatch);
      }
    }

    // console.log({selectedBatch})
    // let allSelectedBatch = selectedBatches
    // selectedBatch.forEach(option => {
    //   console.log({allSelectedBatch})
    //     if (!selectedBatches.includes(option.value)) {
    //       allSelectedBatch.push(option.value);
    //     }
    // });
    // console.log({allSelectedBatch})
    // console.log({selectedBatch})

  }

  const handleRuleKeyChange = (event, index, field) => {
    console.log(event.value)

    setRules(prevRules => {
      const updatedRules = [...prevRules];
      console.log({ updatedRules })
      updatedRules[index][field] = event.value;
      return updatedRules;
    });
  };

  const handleRuleValueChange = (event, index, field) => {
    console.log(event.target.valueAsNumber)

    setRules(prevRules => {
      const updatedRules = [...prevRules];
      console.log({ updatedRules })
      updatedRules[index][field] = event.target.valueAsNumber;
      return updatedRules;
    });
  };

  const handleSubRuleKeyChange = (event, index, subruleIndex, field) => {
    // console.log({value})
    setRules(prevRules => {
      const updatedRules = [...prevRules];
      updatedRules[index].subrules[subruleIndex][field] = event.value;
      return updatedRules;
    });
  };

  const handleSubRuleValueChange = (event, index, subruleIndex, field) => {
    // console.log({value})
    setRules(prevRules => {
      const updatedRules = [...prevRules];
      updatedRules[index].subrules[subruleIndex][field] = event.target.valueAsNumber;
      return updatedRules;
    });
  };

  const handleAdditionalInformationChange = (e) => {
    setAddedInfo(e.target.value);
  }
  const handleFilterSelection = () => {
    if (Object.keys(selectedProduct).length < 1) {
      window.alert("Please select a product first")
      return
    }
    openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true)
  }

  const fetchFilteredCandidatesDraft = async () => {
    setIsLoading(true)
    setCandidatesRequested(true)
    setCandidatesFetched(false)
    let crs_pg_id = selectedProduct._id
    console.log({ jobid })
    fetchFilteredCandidates({
      crs_pg_id,
      selectedBatches,
      job_id: jobid,
      filterData,
      rules
    }).then((res) => {
      setIsLoading(false)
      console.log({ success: res })
      if (res.status != 200) {
        if (res.message) {
          window.alert(res.message);
        } else {
          window.alert('Something went wrong. Please try again.');
        }
      } else {
        // window.alert('Success')
        setCandidatesFetched(true)
        if (res) {
          console.log(res)
          setTotalLearners(res.learnerCount != null ? res.learnerCount : 0);
          // setEligibleCandidates(eligibleCandidates => {
          //   const newEligibleCandidates = res.eligibleCandidates.filter(candidate =>
          //     !eligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
          //   );
          //   console.log({ newEligibleCandidates })
          //   console.log({ eligibleCandidates })

          //   let len1 = newEligibleCandidates.length
          //   let len2 = eligibleCandidates.length
          //   setNumEligibleLearners(len1 + len2)
          //   return [
          //     ...eligibleCandidates,
          //     ...newEligibleCandidates
          //   ];
          // });
          setEligibleCandidates(res.eligibleCandidates)
          setIneligibleCandidates(res.ineligibleCandidates)
          // setIneligibleCandidates(ineligibleCandidates => {
          //   console.log({ ineligibleCandidates })
          //   console.log({ eligibleCandidates })
          //   const newIneligibleCandidates = res.ineligibleCandidates.filter(candidate =>
          //     !ineligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
          //   );
          //   console.log({ newIneligibleCandidates })

          //   const newIneligibleCandidates2 = ineligibleCandidates.filter(candidate =>
          //     !res.eligibleCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
          //   );
          //   console.log({ newIneligibleCandidates2 })

          //   let len1 = newIneligibleCandidates.length
          //   let len2 = newIneligibleCandidates2.length

          //   setNumIneligibleLearners(len1 + len2);

          //   console.log({ ineligibleCandidates })
          //   return [
          //     ...newIneligibleCandidates,
          //     ...newIneligibleCandidates2
          //   ];
          // })

          // Update placed candidates
          // setPlacedCandidates(prevPlacedCandidates => [
          //   ...prevPlacedCandidates,
          //   ...res.placedCandidates.filter(candidate =>
          //     !prevPlacedCandidates.some(prevCandidate => prevCandidate.aid === candidate.aid)
          //   )
          // ]);
          setPlacedCandidates(res.placedCandidates)

          // Update the counts
          setNumEligibleLearners(res.eligibleCandidates.length);
          setNumIneligibleLearners(res.ineligibleCandidates.length)
          setNumPlacedLearners(res.placedCandidates.length)
          // setNumPlacedLearners(prevNumPlacedLearners =>
          //   prevNumPlacedLearners + res.placedCandidates.length
          // );
          setShowPrevFetched(false)
          // setData()
        }

      }
    })
      .catch(error => {
        console.error('An error occurred:', error);
      });
    // load()
    setIsLoading(false)
  }
  const toggleAddFilter = () => {
    setIsAddFilterCollapsed(!isAddFilterCollapsed);
  };
  const toggleJobFreq = () => {
    setIsJobReminderCollapsed(!isJobReminderCollapsed);
  };
  const toggleAppStart = () => {
    setIsAppDateCollapsed(!isAppDateCollapsed);
  };

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
      <>
        <div className="jobs-main">
          <TopBar2
            heading={'Add Job rollout Filters'}
          />
          <div>
            <Sidebar3 active={1} jobid={jobid} />
          </div>
          <div>
            <div className="jobs-header">
              <Row>
                <MiniHeader breadCrumbs={[
                  { name: "Career Services", link: "/career-services" },
                  { name: "Job Postings", link: "/career-services" },
                ]}
                  active={2}
                />
              </Row>
            </div>
            <div className="jobs-content">
              <div className="adques-all" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div className='addcrit-content'>
                  <li className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Autocomplete
                          style={{ width: '100%' }}
                          disableClearable
                          value={selectedProduct.name}
                          onChange={(event, product) => {
                            productChanged(product)
                          }}
                          getOptionLabel={(batch) => {
                            return batch.name || batch
                          }}
                          getOptionSelected={(option, test) => option.name == test}
                          id="batchSelector"
                          options={eligibleProducts}
                          disabled={eligibleProducts.length === 0 ? true : false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                label="Select Product"
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                      </FormGroup>
                    </div>
                  </li>
                </div>
              </div>
              <div className="adques-all" >
                <div className="adques-header row">
                  <Col md={4} className="jobs-table-header-3">
                  <div>
                    <p style={{ marginTop: '10px' }}>Add Filters</p>
                  </div>
                  <div className='jobs-filter-add-container'> 
                    <Button className="jobs-table-btn-5"
                      onClick={handleFilterSelection}
                      disabled={isJobPublished}
                    >Add Filter +
                    </Button>
                  </div>  
                  </Col>
                  <Col md={7}>
                  </Col>
                  <Col md={1}>
                    <img src={isAddFilterCollapsed ? 'https://webcdn.imarticus.org/Placement/chevron-up.svg' : 'https://webcdn.imarticus.org/Placement/chevron-down.svg'} style={{ objectFit: "contain", marginTop: '10px', marginLeft: '5px'}}
                    onClick={toggleAddFilter}
                    />
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                {!isAddFilterCollapsed && (
                <div className='addcrit-content'>
                  {Object.keys(filterData).length > 0 && filterData.gender && filterData.gender.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Gender
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={GENDER_DROPDOWN}
                            placeholder="Select Gender"
                            value={
                              filterData.gender.value
                                ? GENDER_DROPDOWN.filter(option => filterData.gender.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('gender', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.total_exp && filterData.total_exp.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Total Experience
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          {/* <Select
                            options={JOB_EXPERIENCE}
                            placeholder="Select Experience"
                            value={
                              filterData.total_exp.value
                                ? JOB_EXPERIENCE.filter(option => filterData.total_exp.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('total_exp', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          /> */}
                          <div className='row'>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select Condition"
                                value={
                                  filterData.total_exp.condition
                                    ? FILTER_CONDITION.filter(option => filterData.total_exp.condition == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('total_exp', 'condition', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData.total_exp.value}
                                onChange={(event) => {
                                  handleDualFilterChange('total_exp', 'value', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.year && filterData.year.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Graduation Pass Out Year
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={PASSING_YEAR}
                            placeholder="Select Graduation Year"
                            value={
                              filterData.year.value
                                ? PASSING_YEAR.filter(option => filterData.year.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('year', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.notice_period && filterData.notice_period.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Notice Period
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className='row'>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select Condition"
                                value={
                                  filterData.notice_period.condition
                                    ? FILTER_CONDITION.filter(option => filterData.notice_period.condition == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('notice_period', 'condition', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-6">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData.notice_period.value}
                                onChange={(event) => {
                                  handleDualFilterChange('notice_period', 'value', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData?.matric_grade && filterData?.matric_grade.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          10th CGPA & Percentage
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>CGPA</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select CGPA Condition"
                                value={
                                  filterData?.matric_grade?.condition_cgpa
                                    ? FILTER_CONDITION.filter(option => filterData?.matric_grade?.condition_cgpa == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('matric_grade', 'condition_cgpa', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.matric_grade?.value}
                                onChange={(event) => {
                                  handleDualFilterChange('matric_grade', 'value_cgpa', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>Percentage</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select Percentage Condition"
                                value={
                                  filterData?.matric_grade?.condition_perc
                                    ? FILTER_CONDITION.filter(option => filterData?.matric_grade?.condition_perc == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('matric_grade', 'condition_perc', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.matric_grade?.value_perc}
                                onChange={(event) => {
                                  handleDualFilterChange('matric_grade', 'value_perc', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData?.inter_grade && filterData?.inter_grade?.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          12th CGPA & Percentage
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>CGPA</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select CGPA Condition"
                                value={
                                  filterData?.inter_grade?.condition_cgpa
                                    ? FILTER_CONDITION.filter(option => filterData?.inter_grade?.condition_cgpa == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('inter_grade', 'condition_cgpa', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.inter_grade?.value_cgpa}
                                onChange={(event) => {
                                  handleDualFilterChange('inter_grade', 'value_cgpa', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>Percentage</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select Percentage Condition"
                                value={
                                  filterData?.inter_grade?.condition_perc
                                    ? FILTER_CONDITION.filter(option => filterData?.inter_grade?.condition_perc == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('inter_grade', 'condition_perc', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.inter_grade?.value_perc}
                                onChange={(event) => {
                                  handleDualFilterChange('inter_grade', 'value_perc', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData?.grad_grade && filterData?.grad_grade?.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Graduation CGPA & Percentage
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>CGPA</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select CGPA Condition"
                                value={
                                  filterData?.grad_grade?.condition_cgpa
                                    ? FILTER_CONDITION.filter(option => filterData?.grad_grade?.condition_cgpa == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('grad_grade', 'condition_cgpa', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.grad_grade?.value_cgpa}
                                onChange={(event) => {
                                  handleDualFilterChange('grad_grade', 'value_cgpa', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-2">
                              <h6>Percentage</h6>
                            </div>
                            <div className="col-md-6">
                              <Select
                                options={FILTER_CONDITION}
                                placeholder="Select Percentage Condition"
                                value={
                                  filterData?.grad_grade?.condition_perc
                                    ? FILTER_CONDITION.filter(option => filterData?.grad_grade?.condition_perc == option.value)
                                    : []
                                }
                                onChange={(event) => {
                                  console.log({ event })
                                  // const selectedValues = event.map((option) => option.value);
                                  handleDualFilterChange('grad_grade', 'condition_perc', event.value);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="col-md-4">
                              <Input
                                name="text"
                                type="number"
                                id="totalOpenPositions"
                                min="0"
                                max="200"
                                value={filterData?.grad_grade?.value_perc}
                                onChange={(event) => {
                                  handleDualFilterChange('grad_grade', 'value_perc', event.target.valueAsNumber);
                                }}
                                style={{ width: '98%' }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.night_shift && filterData.night_shift.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Open for Night Shift/Rotational
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={BOOLEAN_DROPDOWN}
                            placeholder="Select Condition"
                            value={
                              filterData.night_shift.value
                                ? BOOLEAN_DROPDOWN.filter(option => filterData.night_shift.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('night_shift', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.intern && filterData.intern.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Open for Internship
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={BOOLEAN_DROPDOWN}
                            placeholder="Select Condition"
                            value={
                              filterData.intern.value
                                ? BOOLEAN_DROPDOWN.filter(option => filterData.intern.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('intern', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>

                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.contract && filterData.contract.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Open for Contractual Roles
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={BOOLEAN_DROPDOWN}
                            placeholder="Select Condition"
                            value={
                              filterData.contract.value
                                ? BOOLEAN_DROPDOWN.filter(option => filterData.contract.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('contract', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>
                    </li>
                  )}
                  {Object.keys(filterData).length > 0 && filterData.relocate && filterData.relocate.selected && (
                    <li className="row">
                      <div className="col-md-8">
                        <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                          Relocation
                          <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                        </h5>
                        <FormGroup>
                          <Select
                            options={BOOLEAN_DROPDOWN}
                            placeholder="Select Condition"
                            value={
                              filterData.relocate.value
                                ? BOOLEAN_DROPDOWN.filter(option => filterData.relocate.value.includes(option.value))
                                : []
                            }
                            onChange={(event) => {
                              const selectedValues = event.map((option) => option.value);
                              handleFilterChange('relocate', selectedValues);
                            }}
                            isSearchable={true}
                            isMulti
                          />
                        </FormGroup>
                      </div>
                    </li>
                  )}
                </div>
                )}
              </div>
              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div className="adques-header row">
                  {hoverImage ? <div className="showJobPopupSkill3">
                    Find learners by skill proficiency score
                  </div> : ''}
                  <Col md={2} >
                    <Button className="jobs-table-btn-5"
                      onClick={() => handleRuleChange(numRule + 1)}
                      disabled={isJobPublished}
                    >Add Rule +
                    </Button>
                    <img src='https://webcdn.imarticus.org/webinar/Vector1.svg' style={{ objectFit: "contain", marginLeft: '5px' }} onMouseEnter={() => setHoverImage(true)} onMouseLeave={() => setHoverImage(false)} />
                  </Col>
                  <Col md={7} className="jobs-table-header-2">
                  </Col>
                  <Col md={2}>
                    {/* <Button className="jobs-table-btn-6"
                    // onClick={() => (openJobCreate ? setOpenJobCreate(false) : setOpenJobCreate(true))}
                  >Add Condition +
                  </Button> */}
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                <div className='addcrit-content'>
                  {rules.length >= 1 && (
                    rules.map((rule, index) => {
                      return (
                        <div key={index}>
                          <li className="row">
                            <div className="col-md-9">
                              <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                                Rule {index + 1}:
                                <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                              </h5>
                              <FormGroup>
                                <div className='row'>
                                  <div className="col-md-3">
                                    <Select
                                      options={eligibleSkills}
                                      placeholder="Select Skill Tag"
                                      value={eligibleSkills.find(skill => skill.value === rule.key)} // Update to reflect the current rule data
                                      onChange={(event) => {
                                        handleRuleKeyChange(event, index, 'key')
                                      }}
                                      isSearchable={true}
                                    // isMulti
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Select
                                      options={SKILL_CONDITION}
                                      placeholder="Select Condition"
                                      value={SKILL_CONDITION.find(skill => skill.value === rule.condition)} // Update to reflect the current rule data
                                      onChange={(event) => {
                                        handleRuleKeyChange(event, index, 'condition')
                                      }}
                                      isSearchable={true}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Input
                                      name="text"
                                      type="number"
                                      id="totalOpenPositions"
                                      value={rule.value}
                                      onChange={(event) => {
                                        handleRuleValueChange(event, index, 'value')
                                      }}
                                      style={{ width: '98%' }}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className='col-md-3'>
                            {!isJobPublished && (
                              <Button className="jobs-table-btn-88"
                              color="danger"
                                onClick={() => deleteRule(index)}
                              >Delete Rule
                              </Button>
                            )}
                            </div>
                          </li>
                          {rule.subrules.map((subrule, subruleIndex) => (
                            <li key={subruleIndex} className="row">
                              <div className="col-md-9">
                                <FormGroup>
                                  <div className='row'>
                                    <div className="col-md-3">
                                      <Select
                                        options={JOIN_CONDITION}
                                        placeholder="Select Join Condition"
                                        value={JOIN_CONDITION.find(skill => skill.value === subrule.join)} // Update to reflect the current subrule data
                                        onChange={(event) => {
                                          handleSubRuleKeyChange(event, index, subruleIndex, 'join')
                                        }}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Select
                                        options={eligibleSkills}
                                        placeholder="Select Skill Tag"
                                        value={eligibleSkills.find(skill => skill.value === subrule.key)}// Update to reflect the current subrule data
                                        onChange={(event) => {
                                          handleSubRuleKeyChange(event, index, subruleIndex, 'key')
                                        }}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Select
                                        options={SKILL_CONDITION}
                                        placeholder="Select Condition"
                                        value={SKILL_CONDITION.find(skill => skill.value === subrule.condition)} // Update to reflect the current subrule data
                                        onChange={(event) => {
                                          handleSubRuleKeyChange(event, index, subruleIndex, 'condition')
                                        }}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Input
                                        name="text"
                                        type="number"
                                        id="totalOpenPositions"
                                        value={subrule.value}
                                        onChange={(event) => {
                                          handleSubRuleValueChange(event, index, subruleIndex, 'value')
                                        }}
                                        style={{ width: '98%' }}
                                      />
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className='col-md-1'>
                                {!isJobPublished && (
                                  <div className= 'darken-on-hover' style={{width:"32px", height:"32px", background:"red"}} onClick={() => deleteSubRule(index,subruleIndex)}>
                                    <img src='https://webcdn.imarticus.org/fsd-pro/Group938.svg' style={{objectFit:"contain"}} />    
                                  </div>  
                                )}
                              </div>
                            </li>
                          ))}
                          <li className='row'>
                            <div className='col-md-8'>
                              <button
                                className='addfilter-newrule row'
                                onClick={(event) => {
                                  // const selectedValues = event.map((option) => option.value);
                                  handleSubruleAddition(index);
                                }}
                                disabled={isJobPublished}
                              >
                                <span className=''>
                                  +&nbsp;
                                </span>
                                <span className=''>
                                  Add More Skills
                                </span>
                              </button>
                            </div>
                          </li>
                        </div>
                      );
                    })
                  )}

                </div>
              </div>
              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div style={{ marginTop: '2rem' }}>
                </div>
                <div className='addcrit-content'>
                  <li className="row">
                    <div className="col-md-12">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select Batches
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <div className='row'>
                          <div className="col-md-12">
                            <Select
                              closeMenuOnSelect={false}
                              options={batches}
                              placeholder="Select Batches"
                              value={batches
                                .filter(batch => selectedBatches.includes(batch.value))
                                .map(batch => ({ ...batch }))
                              }
                              onChange={handleSelectedBatchChange}
                              isSearchable={true}
                              isMulti
                              // isClearable={batches.some((v) => v.isFixed)}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </li>
                  <div className='addcrit-footer'>
                    <Row className="adques-footer">
                      <Col md={2}>
                      </Col>
                      <Col md={5}></Col>
                      <Col md={2}>
                      </Col>
                      <Col md={3}>
                        <Button
                          className="jobs-table-btn-5"
                          onClick={fetchFilteredCandidatesDraft}
                        // disabled={isJobPublished}
                        >
                          Add Applicants
                        </Button>
                        <p>
                          Note: Data is delayed by upto 30 minutes
                        </p>
                      </Col>
                    </Row>
                  </div>

                </div>
              </div>

              {candidatesRequested && (
                <div className="adques-all" style={{ marginTop: '20px' }}>
                  <div className="adques-header row">
                    <Col md={9} className="jobs-table-header-2">
                      <p style={{ marginBottom: '10px' }}>Add Applicants</p>
                    </Col>
                    <Col md={2}>
                    </Col>
                  </div>
                  {!candidatesFetched ? (<Loader active inline />) :
                    (
                      <>
                        <div style={{ marginTop: '2rem' }}>
                          <Row style={{ marginLeft: '0px' }}>
                            <Col md={3}>Total Learners</Col>
                            <Col md={3}>Total Eligible Learners</Col>
                            <Col md={3}>Total Non Eligible Learners</Col>
                            <Col md={3}>Total Excluded Learners</Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                value={totalLearners}
                                style={{ width: '98%' }}
                                disabled
                              /></Col>
                            <Col md={3}>
                              <Input
                                type="number"
                                name="text"
                                id="courseShortCode"
                                value={numEligibleLearners}
                                style={{ width: '98%' }}
                                disabled
                              /></Col>
                            <Col md={3}><Input
                              type="number"
                              name="text"
                              id="courseShortCode"
                              value={numIneligibleLearners}
                              style={{ width: '98%' }}
                              disabled
                            /></Col>
                            <Col md={3}><Input
                              type="number"
                              name="text"
                              id="courseShortCode"
                              value={totalLearners-numEligibleLearners-numIneligibleLearners}
                              style={{ width: '98%' }}
                              disabled
                            /></Col>
                          </Row>

                        </div>
                        <div style={{ marginTop: '2rem' }}>
                          <Row >
                            <Col md={12}>
                              <div className="toggle-container">
                                <button
                                  className={`job-dt-tog-btn2  ${!eligibleSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handleEligibleLearners}
                                >
                                  {showPrevFetched ? (
                                    <span
                                      className={` ${!eligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                    >
                                    Previously Added Eligible Learners
                                    </span>
                                  ) : (
                                  <span
                                    className={` ${!eligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                  >
                                    Eligible Learners
                                  </span>)}
                                </button>
                                <button
                                  className={`job-dt-tog-btn2  ${!ineligibleSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handleIneligibleLearners}
                                >
                                  {/* <span
                                    className={` ${!ineligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'
                                      }`}
                                  >
                                    Non-Eligible Learners
                                  </span> */}
                                  {showPrevFetched ? (
                                    <span
                                      className={` ${!ineligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                    >
                                    Previously Added Non-Eligible Learners
                                    </span>
                                  ) : (
                                  <span
                                    className={` ${!ineligibleSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'}`}
                                  >
                                    Non-Eligible Learners
                                  </span>)}
                                </button>
                                <button
                                  className={`job-dt-tog-btn2  ${!placedSelected ? 'unsubscribed' : 'subscribed'
                                    }`}
                                  onClick={handlePlacedLearners}
                                >
                                  <span
                                    className={` ${!placedSelected ? 'btch-toggle-unchecked-2' : 'btch-toggle-2'
                                      }`}
                                  >
                                    Placed Learners
                                  </span>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {eligibleSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Eligible Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {eligibleCandidates.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px' }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='btch-dt-inp sticky-header-both-zero-1'
                                            scope="col">
                                            <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllEligibleSelected}
                                              onChange={allEligibleStudentSelect}
                                            /> 
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1"
                                            scope="col"
                                          >
                                            Student Name
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Course Completion %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Student Tier
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Job Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {eligibleCandidates &&
                                          eligibleCandidates
                                            .filter(dt => searchNameKey === "" || dt.name.includes(searchNameKey))
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3"
                                                    scope="row">
                                                    <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp"
                                                      id={dt.aid}
                                                      onClick={() => {
                                                        singleEligibleStudentSelect(dt.aid)
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-second-1"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-third-1"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-fourth-1"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td"
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    <a href="/head" className="resume-link"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEligibleResumeModalOpen(dt.aid);
                                                      }}>
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {'Eligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  <span 
                                                  onClick={() => {handleTotalGradeClick(dt.crs_pg_id,dt.aid)}} 
                                                  className="resume-link"
                                                  style={{ cursor: 'pointer' }}>
                                                    {dt.total_grade ? dt.total_grade + '%' : '-'}
                                                  </span>
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.comp_perc}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.student_tier}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {selectedStudents.includes(dt.aid) ? "Yes" : "No"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_applied}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <div style={{ marginRight: '256px' }}>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div>
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        {ineligibleSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Non-Eligible Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {ineligibleCandidates.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px' }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='btch-dt-inp-ineligible sticky-header-both-zero-1'
                                            scope="col">
                                            <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllIneligibleSelected}
                                              onChange={allIneligibleStudentSelect}
                                            /> 
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1"
                                            scope="col"
                                          >
                                            Student Name
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Course Completion %
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Student Tier
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Job Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {ineligibleCandidates &&
                                          ineligibleCandidates
                                            .filter(dt => searchNameKey === "" || dt.name.includes(searchNameKey))
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3"
                                                    scope="row">
                                                    <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp-ineligible"
                                                      id={dt.aid}
                                                      onClick={() => {
                                                        singleIneligibleStudentSelect(dt.aid)
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-second-1"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-third-1"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-fourth-1"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td"
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    <a href="/head" className="resume-link"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handleIneligibleResumeModalOpen(dt.aid);
                                                    }}
                                                    >
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {'Ineligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.comp_perc == 100 ? 'Course Completed' : 'In Training'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  <span 
                                                  onClick={() => {handleTotalGradeClick(dt.crs_pg_id,dt.aid)}} 
                                                  className="resume-link"
                                                  style={{ cursor: 'pointer' }}>
                                                    {dt.total_grade ? dt.total_grade + '%' : '-'}
                                                  </span>
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.comp_perc}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.student_tier}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {selectedStudents.includes(dt.aid) ? "Yes" : "No"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_applied}
                                                    {/* {PLACEMENT_ELIGIBILITY[1]} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <div style={{ marginRight: '256px' }}>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div>
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        {placedSelected && (
                          <>
                            <Row style={{ marginLeft: '0px' }}>
                              <Col md={12}>Total Placed Learners</Col>
                              <Col md={4}>
                                <Input
                                  type="text"
                                  name="text"
                                  id="courseShortCode"
                                  value={searchNameKey}
                                  onChange={(e) => setSearchNameKey(e.target.value)}
                                  style={{ width: '98%' }}
                                  placeholder="Search"
                                /></Col>
                            </Row>

                            <div className='addcrit-content'>
                              {placedSelected.length >= 0 ? (
                                <Row style={{ marginTop: '1rem' }}>
                                  <TableContainer component={Paper} style={{ height: '600px' }}>
                                    <Table aria-label="custom pagination table" id="batch-data-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className='btch-dt-inp-ineligible sticky-header-both-zero-1'
                                            scope="col">
                                            {/* <Input
                                              type="checkbox"
                                              className="btch-dt-inp-2"
                                              id="selectAllCheckbox"
                                              checked={isAllIneligibleSelected}
                                              onChange={allIneligibleStudentSelect}
                                            />  */}
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-chk sticky-header-both-first-1"
                                            scope="col"
                                          >
                                            S No.
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-second-1"
                                            scope="col"
                                          >
                                            Student Name
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header-both-third-1"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Training Location
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Batch Name
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            className="btch-dt-th sticky-header"
                                            scope="col"
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Product
                                            <span
                                              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            >
                                            </span>
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Resume Approved
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Status
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            User Type
                                          </TableCell>
                                          {/* <TableCell className="btch-dt-th sticky-header" scope="col">
                                            Gradebook %
                                          </TableCell> */}
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Shared
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Applied
                                          </TableCell>
                                          <TableCell className="btch-dt-th sticky-header" scope="col">
                                            No. of Jobs Rejects
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {placedCandidates &&
                                          placedCandidates
                                            .filter(dt => searchNameKey === "" || dt.name.includes(searchNameKey))
                                            .map((dt, key) => {
                                              return (
                                                <TableRow>
                                                  <TableCell className="sticky-first-1 btch-dt-chk-3"
                                                    scope="row">
                                                    {/* <Input
                                                      type="checkbox"
                                                      className="btch-dt-inp-ineligible"
                                                      id={dt.aid}
                                                      onClick={() => {
                                                        singleIneligibleStudentSelect(dt.aid)
                                                      }}
                                                    /> */}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-second-1"
                                                    scope="row"
                                                  >{serialNum++}</TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-third-1"
                                                    scope="row"
                                                  >
                                                    {dt.name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td sticky-fourth-1"
                                                    scope="row"
                                                  >
                                                    {dt.loc_name}
                                                  </TableCell>
                                                  <TableCell
                                                    className="btch-dt-td"
                                                    scope="row"
                                                  >
                                                    {dt.batch_name}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.course_name || "-"}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    <a href="/head" className="resume-link"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      handlePlacedResumeModalOpen(dt.aid);
                                                    }}
                                                    >
                                                      View Resume
                                                    </a>
                                                    {/* {dt.profile_completion} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {'Ineligible'}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    In Training
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {/* <span 
                                                  onClick={() => {handleTotalGradeClick(dt.crs_pg_id,dt.aid)}} 
                                                  className="resume-link"
                                                  style={{ cursor: 'pointer' }}>
                                                    {dt.total_grade ? dt.total_grade + '%' : '-'}
                                                  </span> */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                  {dt.num_opportunities}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_applied}
                                                    {/* {PLACEMENT_ELIGIBILITY[1]} */}
                                                  </TableCell>
                                                  <TableCell className="btch-dt-td" scope="row">
                                                    {dt.num_rejects}
                                                  </TableCell>

                                                </TableRow>
                                              )
                                            })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  <div style={{ marginRight: '256px' }}>
                                    <TablePagination
                                      rowsPerPageOptions={[10, 25, 50]}
                                      component="div"
                                      count={totalCount}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onPageChange={handleChangePage}
                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                  </div>
                                </Row>
                              ) :
                                <div className="job-not-found">
                                  <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                                  <div style={{ marginBottom: '1rem' }}>
                                    <br></br>
                                    <h5>It's empty here!</h5>
                                  </div>
                                  <div>
                                    <p style={{ marginLeft: '1rem' }}>No Eligible Learners Found</p>
                                    {/* <p>"Create a Job" to view jobs here</p> */}
                                  </div>
                                </div>
                              }
                            </div>
                          </>
                        )}
                        <div className='addcrit-footer'>
                          <Row className="adques-footer">
                            <Col md={2} className="jobs-table-header-2">
                            </Col>
                            <Col md={5}></Col>
                            <Col md={2}>
                            </Col>
                            <Col md={2}>
                              <Button
                                className="jobs-table-btn-7"
                                onClick={() => {
                                  finalizeLearnersForCourse()
                                }}
                              >
                                Finalize Applicants
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                </div>
              )}
              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div className="adques-header row" style={{paddingTop: "8px"}}>
                  <Col md={9} className="jobs-table-header-3">
                    <p style={{ marginBottom: '10px' }}>Set Job Reminder Frequency</p>
                  </Col>
                  <Col md={2}>
                  </Col>
                  <Col md={1}>
                    <img src={isJobReminderCollapsed ? 'https://webcdn.imarticus.org/Placement/chevron-up.svg' : 'https://webcdn.imarticus.org/Placement/chevron-down.svg'} style={{ objectFit: "contain", marginTop: '10px', marginLeft: '5px'}}
                    onClick={toggleJobFreq}
                    />
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                {!isJobReminderCollapsed && (
                <div className='addcrit-content'>
                  <li className="row">
                    <div className="col-md-6">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select Periodicity
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <Select
                          options={PERIODICITY}
                          placeholder="Select Period"
                          value={PERIODICITY.find(period => period.value === selectedPeriod)}
                          onChange={(event) => {
                            handleSelectedPeriodChange(event)
                          }}
                          isSearchable={true}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select No. (hours,days,weeks)
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <Input
                          type="number"
                          name="text"
                          id="courseShortCode"
                          value={periodVal}
                          max="1000"
                          min="0"
                          onChange={(event, oppID) => {
                            const value = event.target.value;
                            if (/^\d+$/.test(value) && parseInt(value, 10) > 0) {
                              setPeriodVal(value);
                            }
                          }}
                          style={{ 
                            width: '98%'
                           }}
                        //   disabled={companyName !== ""}
                        />
                      </FormGroup>
                    </div>
                  </li>
                  <li className="row">
                    <div className="col-md-12">
                      <p htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Note: These reminder will be only sent till application ends or once the learner has applied for the job
                      </p>
                    </div>
                  </li>
                </div>
                )}
              </div>
              
              <div className="adques-all" style={{ marginTop: '20px' }}>
                <div className="adques-header row" style={{paddingTop: "8px"}}>
                  <Col md={9} className="jobs-table-header-3">
                    <p style={{ marginBottom: '10px' }}>Select Application Start Date & End Date</p>
                  </Col>
                  <Col md={2}>
                  </Col>
                  <Col md={1}>
                    <img src={isAppDateCollapsed ? 'https://webcdn.imarticus.org/Placement/chevron-up.svg' : 'https://webcdn.imarticus.org/Placement/chevron-down.svg'} style={{ objectFit: "contain", marginTop: '10px', marginLeft: '5px'}}
                    onClick={toggleAppStart}
                    />
                  </Col>
                </div>
                <div style={{ marginTop: '2rem' }}>
                </div>
                {!isAppDateCollapsed && (
                <div className='addcrit-content'>
                  <li className="row">
                    <div className="col-md-12">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select Timezone
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <div className="labs-create-new-form">
                          <div>Timezone </div>
                          <select
                            className="labs-create-new-form-text_input"
                            required={true}
                            name="timezone"
                            readOnly={isJobPublished}
                            value={formdata.timezone}
                            onChange={updateFormdata}
                          >
                            <option value="Asia/Calcutta">Asia/Calcutta</option>
                            {timezones
                              ? timezones.map((elem, i) => {
                                return (
                                  <option value={elem} key={i}>
                                    {elem}
                                  </option>
                                )
                              })
                              : null}
                          </select>
                        </div>
                      </FormGroup>
                    </div>
                  </li>
                  <li className='row'>
                    <div className="col-md-6">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select Start Date & Time
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <input
                          type="datetime-local"
                          className="labs-create-new-form-text_input"
                          name="valid_from"
                          value={formdata.valid_from}
                          required={true}
                          onChange={updateFormdata}
                          readOnly={isJobPublished}
                        // min={currentFormattedDate}
                        // max={finalFormattedDate}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        Select End Date & Time
                        <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                      </h5>
                      <FormGroup>
                        <input
                          type="datetime-local"
                          className="labs-create-new-form-text_input"
                          name="valid_till"
                          value={formdata.valid_till}
                          required={true}
                          onChange={updateFormdata}
                          min={formdata.valid_from}
                          readOnly={isJobPublished}
                        // max={finalFormattedDate}
                        />
                      </FormGroup>
                    </div>
                  </li>
                  <div className='addcrit-footer'>

                    <Row className="adques-footer">
                      <Col md={2} >
                      </Col>
                      <Col md={5}></Col>
                      <Col md={2}>
                      </Col>
                      <Col md={3}>
                      {isJobPublished ? (
                        <Button
                          className="jobs-table-btn-5"
                          // onClick={() => {
                          //   publishJobForLearners()
                          // }}
                          onClick={() => {
                            setOpenChangeEndDateModal(true)
                          }}
                          // disabled={isJobPublished}
                        >
                          Update End Time 
                        </Button>
                        ) : (
                        <Button
                          className="jobs-table-btn-5"
                          // onClick={() => {
                          //   publishJobForLearners()
                          // }}
                          onClick={() => {
                            setOpenPublishModal(true)
                          }}
                          disabled={isJobPublished}
                        >
                          Publish Job
                        </Button>)}
                        
                      </Col>
                    </Row>
                  </div>

                </div>
                )}
              </div>
            </div>
          </div>

        </div>

        <Modal className="jobs-show-filter-modal" isOpen={openFilterChoice} centered>
          <ModalHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Add Filters{' '}
            </div>
          </ModalHeader>
          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
              <div className="col-md-12">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem',fontSize: "16px",fontWeight:"600" }}>
                  Select Filters
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <br></br>
                <div className='job-fltr-modal'>
                  <FormGroup>
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.gender && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.gender.selected}
                              onClick={() => {
                                singleFilterSelect('gender')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Gender</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.total_exp && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.total_exp.selected}
                              // onClick={() => {
                              //   singleFilterSelect('total_exp')
                              // }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Total Experience</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.year && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.year.selected}
                              onClick={() => {
                                singleFilterSelect('year')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Graduation Pass Out Year</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.notice_period && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.notice_period.selected}
                              onClick={() => {
                                singleFilterSelect('notice_period')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Notice Period</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.grad_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData?.grad_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('grad_grade')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Graduation Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.matric_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData?.matric_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('matric_grade')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>10th Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct?.inter_grade && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData?.inter_grade?.selected}
                              onClick={() => {
                                singleFilterSelect('inter_grade')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>12th Percentage</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.night_shift && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.night_shift.selected}
                              onClick={() => {
                                singleFilterSelect('night_shift')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Open for Night Shifts</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.intern && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.intern.selected}
                              onClick={() => {
                                singleFilterSelect('intern')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Open for Internship? (for PGA only)</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {Object.keys(filterForProduct).length > 0 && filterForProduct.contract && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.contract.selected}
                              onClick={() => {
                                singleFilterSelect('contract')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Open for Contractual Roles</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                    {Object.keys(filterForProduct).length > 0 && filterForProduct.relocate && (
                      <div>
                        <div className='row'>
                          <div className="col-md-1" style={{display:"flex",justifyContent:"flex-end"}}>
                            <Input
                              type="checkbox"
                              className="job-dt-inp"
                              // id={dt.aid}
                              checked={filterData.relocate.selected}
                              onClick={() => {
                                singleFilterSelect('relocate')
                              }}
                            />
                          </div>
                          <div className="col-md-11">
                            <h5 style={{ fontSize: "16px",fontWeight:"400" }}>Relocation</h5>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
            </li>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => (openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true))}
            >
              Close
            </Button>
            <Button
              style={{ background: "#014644", borderRadius: "4px" }}
              onClick={() => (openFilterChoice ? setOpenFilterChoice(false) : setOpenFilterChoice(true))}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal className="jobs-viewresumemodal" isOpen={openResumeView} centered>
          {/* <ModalHeader>
            <li className="row">
              <div className="col-md-8">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
                </div>
              </div>
              {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
                <div className='res-dl-btn col-md-4 '>
                  Download Resumes
                </div>)}
            </li>
          </ModalHeader> */}

          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
            <div className="viewres-txt">
              <div style={{ display: 'flex', alignItems: 'center' , fontSize: '20px', fontWeight: '700', marginLeft:"32px"}}>
              {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
              </div>
              {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
              <div className='res-dl-btn ' style={{marginRight:"32px"}} onClick={ () => {handleBulkDownload()}}>
                  Download Resumes
              </div>
            )}
            </div>
            
            </li>
            {selectedLearner && selectedLearnerResume && selectedLearnerResume.length == 0 && (
              <div>No resume found</div>
            )}
            {selectedLearnerResume
              .filter((file) => file.resume_type === 2)
              .map((file, index) => (
                <div key={index}>
                  <div className="resume-div drop-zone">
                    <li className="row">
                      <div className="col-md-2" style={{display:"flex",justifyContent:"center"}}>
                        <img
                          className="resume-icon-2"
                          src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                        />
                      </div>
                      <div className="col-md-4">
                        <h5 className="resume-heading">
                          Resume {index + 1}
                        </h5>
                      </div>
                      <div className='col-md-3'>
                        <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="col-md-1">
                      </div>
                      <div className="col-md-2">
                        <img
                          src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                          className="as-dl-logo-job-2"
                          onClick={() => previewResumeClick(file.resume_url)}
                        />
                      </div>
                    </li>
                  </div>
                  <br />
                </div>
              ))}

            {selectedLearnerResume
              .filter((file) => file.resume_type === 1)
              .map((file, index) => (
                <div key={index}>
                  <div className="resume-div drop-zone">
                    <li className="row">
                      <div className="col-md-2" style={{display:"flex",justifyContent:"center"}}>
                        <img
                          className="resume-icon-2"
                          src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                        />
                      </div>
                      <div className="col-md-4">
                        <h5 className="resume-heading">
                          Video Resume {index + 1}
                        </h5>
                      </div>
                      <div className='col-md-3'>
                        <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                      </div>
                      <div className="col-md-1">
                      </div>
                      <div className="col-md-2">
                        <a
                          href={file.resume_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                            className="as-dl-logo-job-2"
                            alt="View Resume"
                          />
                        </a>
                      </div>
                    </li>
                  </div>
                  <br />
                </div>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => handleResumeModalClose()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={togglePDFDoc} className="resume-preview-modal" size={"md"} style={{ minwidth: "400px" }} centered >
          <ModalHeader
            className="resume-prev"
            style={{ paddingRight: "7px", width: "100%" }}
          >
            <div className="row" style={{ justifyContent: "space-between" ,marginLeft: "12px",marginRight: "12px" }}>
              <div>Resume Preview </div>
              <div>
                <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  style={{ paddingRight: "7px" }}
                  onClick={() => setTogglePDFDoc(false)}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalBody
            style={{
              minHeight: "500px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* <PDFReader url={resumeURL}></PDFReader> */}
            <iframe
            src={resumeURL}
            style={{ width: "100%", height: "100%", border: "none" ,minHeight: "750px"}}
            frameBorder="0"
          />
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
        </Modal>
        <Modal
          style={{ height: 'fit-content', margin: 'auto', marginTop: 'auto' }}
          isOpen={openGradebookModal}
          centered
        >
          <ModalHeader>Gradebook</ModalHeader>
          <ModalBody
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >


          {studentGradebook &&
            enableCategory &&
            studentGradebook.map((grd, index) => {
              return (
                <div key={index}>
                  {grd.length == 0 ? (
                    <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                      <div
                        className="row no-margin gradeBlock"
                        style={{
                          display: "block",
                          width: "100vw",
                          paddingTop: "8vh",
                          flex: "0 1 100vw",
                          maxWidth: "100vw",
                        }}
                      >
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                          </h2>

                          <span className="my-grade">
                            {!grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                            alt="Empty Gradebook"
                          />
                          <br />
                          <br />
                          <span>
                            There are no quizzes and assignments to show at this
                            moment.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                      <div
                        className="row no-margin gradeBlock gradeBlockPadding"
                        style={{ display: "block" }}
                      >
                       
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                      
                          </h2>

                          <span className="my-grade">
                            {!isElec && !grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />

                        <div className="flexRow">
                          <table
                            className="table table-bordered gradeTooltip"
                            style={{ minWidth: "700px" }}
                          >
                            <thead className="th-bg-clr">
                              <tr>
                                <th className="t-center th-fnt">Category</th>
                                <th className="t-center th-fnt">
                                  Marks Scored
                                </th>
                                <th className="t-center th-fnt">
                                  Total Marks{" "}
                                </th>
                                {showPlcStatus && placementEnable && (
                                  <th className="t-center th-fnt">
                                    Placement Eligibility Cutoff &nbsp;
                                    <TooltipItem
                                      style={{ backgroundColor: "none" }}
                                      id={"namePopup1" + index}
                                      batch="Sum of scores of all the assessment  should be greater than or equal to the Placement cut off score to be eligible for placement."
                                    />
                                  </th>
                                )}
                                {showPassMarks && !placementEnable && (
                                  <th className="t-center th-fnt">
                                    Passing Marks
                                  </th>
                                )}
                                {showPassMarks &&
                                  placementEnable &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Assigned Weightage %
                                  </th>
                                )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Weighted % Scored
                                  </th>
                                )}
                                {placementEnable && showPlcStatus && (
                                  <th className="t-center th-fnt">
                                    Placement Status
                                  </th>
                                )}
                                {!placementEnable && showContentStatus && (
                                  <th className="t-center th-fnt">Status</th>
                                )}
                                {placementEnable &&
                                  showContentStatus &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">Status</th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {grd.catg &&
                                grd.catg.map((category, index) => {
                                  return (
                                    <Fragment key={`${index}${category.name}`}>
                                      {category.dids &&
                                        category.dids.length > 0 && (
                                          <>
                                            {" "}
                                            <tr
                                              style={{ cursor: "pointer" }}
                                              // onClick={() =>
                                              //   handleClick(
                                              //     index,
                                              //     `${index}${category.name}`
                                              //   )
                                              // }
                                            >
                                              <td className="bg-white-clr tr-fnt">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  {" "}
                                                  <div>
                                                    {category.name
                                                      ? category.name
                                                      : "-"}
                                                  </div>{" "}
                                                  <div>
                                                    {" "}
                                                    <img
                                                      src={
                                                        collapseRow[
                                                          `${index}${category.name}`
                                                        ]
                                                          ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                          : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                      }
                                                    ></img>
                                                  </div>
                                                </div>

                                              </td>

                                              <td className="bg-white-clr tr-fnt">
                                                {category.marks_scored || category.marks_scored == 0
                                                  ? category.marks_scored
                                                  : "-"}
                                              </td>
                                              <td className="bg-white-clr tr-fnt">
                                                {category.total_marks || category.total_marks == 0
                                                  ? category.total_marks
                                                  : "-"}
                                              </td>
                                              {showPlcStatus &&
                                                placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.plac_cutoff || category.plac_cutoff == 0
                                                      ? category.plac_cutoff
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                !placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.pass_marks || category.pass_marks == 0
                                                      ? category.pass_marks
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                placementEnable &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    -
                                                  </td>
                                                )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weight || category.weight == 0
                                                    ? category.weight
                                                    : "-"}
                                                </td>
                                              )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weightedPView || category.weightedPView == 0
                                                    ? category.weightedPView
                                                    : "-"}
                                                </td>
                                              )}

                                              {placementEnable &&
                                                showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.status
                                                      ? category.status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {!placementEnable &&
                                                showContentStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {placementEnable &&
                                                showContentStatus &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                            </tr>
                                            {collapseRow[
                                              `${index}${category.name}`
                                            ] && category.dids ? (
                                              <>
                                                <tr>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    My Assessments
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Marks Scored
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Total Marks
                                                  </td>
                                                  {showPassMarks && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Pass Marks
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assigned Weight%
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Weighted % Scored
                                                    </td>
                                                  )}

                                                  {showContentStatus && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assessment Status
                                                    </td>
                                                  )}
                                                </tr>
                                                {category.dids.map((did) => {
                                                  return (
                                                    <tr>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.name
                                                          ? did.name
                                                          : "-"}
                                                      </td>
                                                      {/* <td className="bg-blue-clr tr-fnt">
                                                  {did.marks ? did.marks:'-'}
                                                </td> */}
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {/* {did.status
                                                          ? did.status === 1
                                                            ? `${did.marks}`
                                                            : did.status === 2
                                                              ? "Under Evaluation"
                                                              : "Absent"
                                                          : did.marks !== "-"
                                                            ? `${did.marks}`
                                                            : "Absent"} */}
                                                        {did.marks || did.marks == 0
                                                          ? did.marks
                                                          : "-"}
                                                      </td>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.total_marks || did.total_marks == 0
                                                          ? did.total_marks
                                                          : "-"}
                                                      </td>

                                                      {showPassMarks && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.pasmrk || did.pasmrk == 0
                                                            ? did.pasmrk
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weight
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weightedPView
                                                            : "-"}
                                                        </td>
                                                      )}

                                                      {showContentStatus && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.showStatus
                                                            ? did.showStatus
                                                            : "-"}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  );
                                                })}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}
                              {/* Render assignment data */}

                              <tr>
                                <th
                                  className="t-center th-fnt"
                                  colSpan={columnSpan} // ----------------------
                                  style={{ textAlign: "left" }}
                                >
                                  Total Grade (%)
                                </th>
                                <th
                                  className="t-center th-fnt total"
                                  colSpan="1"
                                  style={{ position: "relative" }}
                                >
                                  {grdTotalPerc[grd.elec_id]
                                    ? grdTotalPerc[grd.elec_id].toFixed(2)
                                    : grdTotalPerc["FOUNDATION"]
                                    ? grdTotalPerc["FOUNDATION"].toFixed(2)
                                    : "-"}
                                  % &nbsp;&nbsp;
                                  {totalWeightAssignedP < 100 && (
                                    <TooltipItem
                                      id="namePopupGrade2"
                                      batch=" You will be able to see your final
                                 Grade for the course once all your
                                 assessment scores have been
                                 published."
                                    />
                                  )}
                                </th>
                              </tr>
                              {showPlcStatus &&
                                placementEnable &&
                                allAssessmentPublished && (
                                  <>
                                    {" "}
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage &&
                                          // showPassMarks &&
                                          // placementEnable
                                          //   ? 5
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 4
                                          //   : placementEnable
                                          //   ? 3
                                          //   : 2
                                          columnSpan
                                        } // ----------------------
                                        style={{
                                          textAlign: "left",
                                          weight: 60,
                                          size: "20px",
                                        }}
                                      >
                                        Placement Status
                                      </th>
                                      <th
                                        className="t-center th-fnt total"
                                        colSpan="1"
                                        style={{ position: "relative" }}
                                      >
                                        {/* {grdPlsStatus[grd.elec_id]
                                    ? (grdPlsStatus[grd.elec_id] ? 'PASS' :'FAIL')
                                    : grdPlsStatus["FOUNDATION"]
                                    ? (grdPlsStatus["FOUNDATION"]?'PASS' :'FAIL')
                                    : "-"} */}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}{" "}
                                      </th>
                                    </tr>
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage && showPassMarks
                                          //   ? 6
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 5
                                          //   : 4
                                          columnSpan + 1
                                        } // ----------------------
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                      </th>
                                    </tr>
                                  </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                     
                   
                        
                    </div>
                  )}
                </div>
              );
            })}

            {grddata && grddata.contentElecMap &&
              !enableCategory &&
              Object.keys(grddata.contentElecMap).map((grd, index) => {
                return (
                  <div key={index}>
                    {grddata.contentElecMap[grd] && grddata.contentElecMap[grd].length == 0 ? (
                      <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                        <div
                          className="row no-margin gradeBlock"
                          style={{
                            display: 'block',
                            width: '100vw',
                            paddingTop: '8vh',
                            flex: '0 1 100vw',
                            maxWidth: '100vw',
                          }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: '#055646',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '24px',
                              }}
                            >
                              {/* {elecNameMap[grd] &&  <> <span style={{ borderBottom: "2px solid #055646" }}>
                           
                           </span>
                           </>} */}
                              <span className="my-grade">{'My Grades'}</span>
                            </h2>
                          </div>
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                              alt="Empty Gradebook"
                            />
                            <br />
                            <br />
                            <span>
                              There are no quizzes and assignments to show at this
                              moment.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>

                          <div
                            className="row no-margin gradeBlock gradeBlockPadding"
                            style={{ display: 'block' }}
                          >
                            <div className="col-12" style={{ padding: 0 }}>
                              <h2
                                style={{
                                  color: '#055646',
                                  fontWeight: 600,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                }}
                              >
                                <span
                                  style={{ borderBottom: '2px solid #055646' }}
                                >
                                  {isElec
                                    ? elecNameMap[grd]
                                      ? elecNameMap[grd]
                                      : 'Core Track'
                                    : ''}
                                </span>
                              </h2>

                              {!grddata.elec_enable && (
                                <span className="my-grade">{'My Grades'}</span>
                              )}
                            </div>
                            <hr />

                            <div className="flexRow">
                              <table
                                className="table table-bordered gradeTooltip"
                                style={{ minWidth: '700px' }}
                              >
                                <thead className="th-bg-clr">
                                  <tr>
                                    <th className="t-center th-fnt">
                                      Assessment/Quiz
                                    </th>
                                    <th className="t-center th-fnt">
                                      Actual Marks
                                    </th>
                                    <th className="t-center th-fnt">
                                      Total Marks
                                    </th>

                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Assigned Weightage%
                                      </th>
                                    )}
                                    {showPassMarks && (
                                      <th className="t-center th-fnt">
                                        Passing Marks
                                      </th>
                                    )}
                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Weighted % Scored

                                      </th>
                                    )}
                                    {showContentStatus && <th className="t-center th-fnt">  Assessment Status</th>}

                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Render assignment data */}
                                  {grddata.contentElecMap[grd].map((ag) => (
                                    <tr key={ag.nm}>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.name ? ag.name : '-'}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.marks || ag.marks == 0 ? ag.marks : "-"}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.total_marks || ag.total_marks == 0? ag.total_marks : ag.maxmrk}
                                      </td>

                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weight || ag.weight == 0 ? ag.weight : '-'}%
                                        </td>
                                      )}
                                      {showPassMarks && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.pasmrk || ag.pasmrk == 0 ? ag.pasmrk : '-'}
                                        </td>
                                      )}
                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weightedPView || ag.weightedPView == 0 ? ag.weightedPView : '-'}%
                                        </td>
                                      )}
                                      {showContentStatus && <td className="bg-white-clr tr-fnt">
                                      {ag.showStatus || ag.showStatus == 0 ? ag.showStatus : "-"}

                                      </td>}
                                    </tr>
                                  ))}

                                  <tr>
                                    <th
                                      className="t-center th-fnt"
                                      // colSpan={showWeightage ? "3" : "2"}
                                      colSpan={showContentStatus ?
                                        showWeightage && showPassMarks
                                          ? 6
                                          : showWeightage && !showPassMarks
                                            ? 5
                                            : !showWeightage && showPassMarks
                                              ? 4
                                              : 3 :
                                        showWeightage && showPassMarks
                                          ? 5
                                          : showWeightage && !showPassMarks
                                            ? 4
                                            : !showWeightage && showPassMarks
                                              ? 3 : 2
                                      }// ----------------------
                                      style={{ textAlign: 'left' }}
                                    >
                                      Total Grade (%)
                                    </th>
                                    <th
                                      className="t-center  total"
                                      colSpan="1"
                                      style={{ position: 'relative' }}
                                    >
                                       {grdTotalPerc[grd]
                                      ? grdTotalPerc[grd].toFixed(2)
                                      : "-"} %&nbsp;&nbsp;
                                      {/* {totalWeightAssignedP < 100 && (
                                        <TooltipItem
                                          id="namePopupGrade25"
                                          batch=" You will be able to see your final
                                    Grade for the course once all your
                                    assessment scores have been
                                    published."
                                        />
                                      )} */}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}

            <div>
              You will be able to see the final Grades for the course once all
              the assessment scores have been published.
            </div>

          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={(e) => { e.stopPropagation(); setOpenGradebookModal(false) }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openPublishModal}
          style={{ width: '20%' }}
          size={"xl"}
          className="apply-sucess-modal"
          centered
        >
          <ModalBody style={{ maxHeight: "800px"}}>
            <div>
              <div className="prof-dtl-img ">
              </div>
              <p className="aply-success-txt">
                Are you sure you want to Publish the Job?
              </p>
              <p className="prof-dtl-txt">
              </p>
            </div>
            <div style={{ justifyContent: "center", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
              <div style={{ width: "48%" }}>
                <Button
                  style={{ width: "100%", borderRadius: "4px" }}
                  onClick={() => {
                    setOpenPublishModal(false);
                    // setSelectedJob('')
                  }}
                  className="apply-success-btn"
                  color="danger"
                >
                  No
                </Button>
              </div>
              <div style={{ width: "48%" , marginLeft: "12px"}}>
                  <Button
                    onClick={() => {
                            publishJobForLearners()
                          }}
                    style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                  >
                    Yes
                  </Button>
              </div>

            </div>
          </ModalBody>
        </Modal>

        <Modal className="jobs-grantaccessmodal" isOpen={openChangeEndDateModal} centered>
          <ModalHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            Update Application End Date & Time
            </div>
          </ModalHeader>
          <ModalBody
            className="thumbModal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <li className="row">
              <div className="col-md-12">
                <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                New Application End Date & Time
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </h5>
                <FormGroup>
                  <input
                    type="datetime-local"
                    className="labs-create-new-form-text_input"
                    name="valid_till"
                    value={formdata.valid_till}
                    required={true}
                    onChange={updateFormdata}
                    min={formdata.valid_from}
                  />
                </FormGroup>
              </div>
            </li>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setOpenChangeEndDateModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={() => {
                updateEndTimeForLearners()
                // setOpenChangeEndDateModal(false);
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={openSuccessModal}
          style={{ width: '20%' }}
          size={"xl"}
          className="apply-sucess-modal"
          centered
        >
          <ModalBody style={{ maxHeight: "800px"}}>
            <div>
              <div className="prof-dtl-img ">
              </div>
              <p className="aply-success-txt">
              Job published successfully
              </p>
              <p className="prof-dtl-txt">
              Learners will be notified about the job to apply
              </p>
            </div>
            <div style={{ justifyContent: "center", display: "flex", marginTop: "30px", marginLeft: "20px", marginRight: "20px" }}>
              <div style={{ width: "48%" }}>
                  <Button
                    onClick={() => {
                            setOpenSuccessModal(false)
                            history.push(`/career-module/preview-job-description/${jobid}`)
                          }}
                    style={{ background: "#014644", width: "100%", borderRadius: "4px" }}
                  >
                    Done
                  </Button>
              </div>

            </div>
          </ModalBody>
        </Modal>
      </>
    </div>
  )

}


export default AddJobRolloutFilters