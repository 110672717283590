const initialstate = {
  certificatestatus: null,
}
export default function (state = initialstate, action) {
  switch (action.type) {
    case 'FETCH_CERTIFICATION':
      console.log(action.payload)
      console.log('=====', action.payload.data)
      return {
        ...state,
        certificatestatus: action.payload.data.crtf_enable,
        batchEndDate: action.payload.data.end_date || '',
      }
    case 'ENABLE_CERTIFICATION':
      return {
        ...state,
        certificatestatus: true,
      }
    case 'DISABLE_CERTIFICATION':
      return {
        ...state,
        certificatestatus: false,
      }
    default: {
      return state
    }
  }
}
