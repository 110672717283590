import Axios from 'axios'
import Cookies from 'universal-cookie'
import {
  CODING_ASSIGNMENT_TYPE,
  AUTHENTICATION_COOKIES,
  PATH,
  DOMAIN,
} from '../constants/constant'

import { DateRange } from '@mui/icons-material'

const cookies = new Cookies()
const configs = require('../config/configs.js')

var BASE_URL = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL = configs.routes.BASE_URL_DEVELOPMENT;
var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_TEST_SERVER
//var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_DEVELOPMENT_TEST_SERVER;
var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY
// var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY_TESTING;;
var TEST_SERVER_SECRET = configs.routes.TEST_SERVER_SECRET
// var TEST_SERVER_SECRET = configs.routes.TEST_SERVER_SECRET_TESTING


export let createPlacementJob = ({ opportunityId,
    companyName,
    companyShortCode,
    hrname,
    hremail,
    hrccod,
    hrmobile,
    totalPositions,
    oppWonDate,
    driveEndDate,
    selectedlocationDropdown,
    selectedLocationNumPosition,
    locationArray}) => {
    return new Promise(async (resolve, reject) => {
      let statusCode
      let URL = BASE_URL + '/cfpanelPlacement/create_placement_job'
      let aid = cookies.get('aid')
      let pid = cookies.get('pid')
  
      Axios({
        method: 'post',
        url: URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
        data: { opportunityId,
            companyName,
            companyShortCode,
            hrname,
            hremail,
            hrccod,
            hrmobile,
            totalPositions,
            oppWonDate,
            driveEndDate,
            selectedlocationDropdown,
            selectedLocationNumPosition,
            locationArray,
            aid },
      })
        .then((res) => {
          console.log({ data: res.data })
          statusCode = res.status
          if (res.data.success) {
            console.log('createPlacementJob data!!', res.data)
            return resolve({ ...res.data.data, status: statusCode })
          } else {
            console.log('API call failed!', res.data)
            return resolve({ ...res.data, status: statusCode })
          }
        })
        .catch((err) => {
          console.log(err)
          return reject(err)
        })
    })
  }

  export let createPlacementCompany = ({
    companyName,
    companyShortCode}) => {
    return new Promise(async (resolve, reject) => {
      let statusCode
      let URL = BASE_URL + '/cfpanelPlacement/create_placement_company'
      let aid = cookies.get('aid')
      let pid = cookies.get('pid')
  
      Axios({
        method: 'post',
        url: URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
        data: {
            companyName,
            companyShortCode,
            aid },
      })
        .then((res) => {
          console.log({ data: res.data })
          statusCode = res.status
          if (res.data.success) {
            console.log('create_placement_company data!!', res.data)
            return resolve({ ...res.data.data, status: statusCode })
          } else {
            console.log('API call failed!', res.data)
            return resolve({ ...res.data, status: statusCode })
          }
        })
        .catch((err) => {
          console.log(err)
          return reject(err)
        })
    })
  }

export  const getAllJobs = (locationId,
  reviewStatus,
  jobStatus,
  validFrom,
  validTill,
  relevanceStatus,
  searchField,
  searchVal,
  page,
  rowsPerPage  
) => {
    return new Promise((resolve, reject) => {
      let getData = {
        url: `${
          configs.routes.BASE_URL_DEVELOPMENT
        }/cfpanelPlacement/get_all_placement_jobs`,
        method: 'POST',
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: { 
          locationId,
          reviewStatus,
          jobStatus,
          validFrom,
          validTill,
          relevanceStatus,
          searchField,
          searchVal,
          page,
          rowsPerPage  
        },
      }
      Axios(getData)
        .then((res) => {
          console.log({ data: res.data })
          let statusCode = res.status
          if (res.data.success) {
            console.log('getallJobs data!!', res.data)
            return resolve({ ...res.data, status: statusCode })
          } else {
            console.log('API call failed!', res.data)
            return resolve({ ...res.data, status: statusCode })
          }
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

  export  const getAllCompanies = (
    searchField,
    searchVal,
    page,
    rowsPerPage) => {
      return new Promise((resolve, reject) => {
        let getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_all_placement_companies`,
          method: 'POST',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: { 
            searchField,
            searchVal,
            page,
            rowsPerPage},
        }
        Axios(getData)
          .then((res) => {
            console.log({ data: res.data })
            let statusCode = res.status
            if (res.data.success) {
              console.log('getallJobs data!!', res.data)
              return resolve({ ...res.data, status: statusCode })
            } else {
              console.log('API call failed!', res.data)
              return resolve({ ...res.data, status: statusCode })
            }
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    }

  export  const uploadCompanyLogo = (file,
    companyShortCode) => {
      return new Promise((resolve, reject) => {
        let fd = new FormData()
        if (file != undefined) fd.set('file', file)
        if (companyShortCode != undefined) fd.set('companyShortCode', companyShortCode)
        let getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/upload_company_logo`,
          method: 'POST',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
          data: fd,
        }
        Axios(getData)
          .then((res) => {
            console.log({ data: res.data })
            let statusCode = res.status
            if (res.data.success) {
              console.log('getallJobs data!!', res.data)
              return resolve({ ...res.data, status: statusCode })
            } else {
              console.log('API call failed!', res.data)
              return resolve({ ...res.data, status: statusCode })
            }
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    }
  export let savePlacementJobDescription = ({   
    jobid,
    descid,
    company_website,
    selected_domain,
    job_title,
    selected_job_type,
    selected_mode,
    num_openings,
    ctc_type,
    ctc_amount,
    ctc_range_min,
    ctc_range_max,
    job_location,
    bond_included,
    bond_duration,
    bond_additional_info,
    interview_mode,
    num_rounds,
    rounds_order,
    job_desc_status,
    roles_info,
    company_logo}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelPlacement/save_placement_jobdescription'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { 
        jobid,
        descid,
        company_website,
        selected_domain,
        job_title,
        selected_job_type,
        selected_mode,
        num_openings,
        ctc_type,
        ctc_amount,
        ctc_range_min,
        ctc_range_max,
        job_location,
        bond_included,
        bond_duration,
        bond_additional_info,
        interview_mode,
        num_rounds,
        rounds_order,
        job_desc_status,
        roles_info,
        company_logo,
        aid 
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('savePlacementCriteria data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
  
  export let savePlacementCriteria = ({ jobid,
      criteriaid,
      products,
      skillTags,
      gradYear,
      gender,
      workex,
      shiftTime,
      baseLoc,
      additionalInfo}) => {
    return new Promise(async (resolve, reject) => {
      let statusCode
      let URL = BASE_URL + '/cfpanelPlacement/save_placement_criteria'
      let aid = cookies.get('aid')
      let pid = cookies.get('pid')
  
      Axios({
        method: 'post',
        url: URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
        data: { jobid,
          criteriaid,
          products,
          skillTags,
          gradYear,
          gender,
          workex,
          shiftTime,
          baseLoc,
          additionalInfo,
            aid },
      })
        .then((res) => {
          console.log({ data: res.data })
          statusCode = res.status
          if (res.data.success) {
            console.log('savePlacementCriteria data!!', res.data)
            return resolve({ ...res.data.data, status: statusCode })
          } else {
            console.log('API call failed!', res.data)
            return resolve({ ...res.data, status: statusCode })
          }
        })
        .catch((err) => {
          console.log(err)
          return reject(err)
        })
    })
  }

  export let saveAddedQuestion = ({ jobid,
      question,
      options,
      isObjectiveQues}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelPlacement/save_added_question'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { jobid,
        question,
      options,
      isObjectiveQues,
          aid },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('savePlacementCriteria data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export let saveEditedQuestion = ({ 
  jobid,
  quesid,
  question,
  options,
  isObjectiveQues}) => {
    console.log({quesid:quesid})
return new Promise(async (resolve, reject) => {
let statusCode
let URL = BASE_URL + '/cfpanelPlacement/save_edited_question'
let aid = cookies.get('aid')
let pid = cookies.get('pid')

Axios({
  method: 'post',
  url: URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': cookies.get('at'),
  },
  data: { jobid,
    quesid,
    question,
    options,
    isObjectiveQues,
    aid },
})
  .then((res) => {
    console.log({ data: res.data })
    statusCode = res.status
    if (res.data.success) {
      console.log('save_edited_question data!!', res.data)
      return resolve({ ...res.data.data, status: statusCode })
    } else {
      console.log('API call failed!', res.data)
      return resolve({ ...res.data, status: statusCode })
    }
  })
  .catch((err) => {
    console.log(err)
    return reject(err)
  })
})
}

export let deleteJob = ({ jobid}) => {
  return new Promise(async (resolve, reject) => {
  let statusCode
  let URL = BASE_URL + '/cfpanelPlacement/delete_placementjob'
  let aid = cookies.get('aid')
  let pid = cookies.get('pid')
  
  Axios({
    method: 'post',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { jobid,
        aid },
  })
    .then((res) => {
      console.log({ data: res.data })
      statusCode = res.status
      if (res.data.success) {
        console.log('savePlacementCriteria data!!', res.data)
        return resolve({ ...res.data.data, status: statusCode })
      } else {
        console.log('API call failed!', res.data)
        return resolve({ ...res.data, status: statusCode })
      }
    })
    .catch((err) => {
      console.log(err)
      return reject(err)
    })
  })
  }

export let deleteAddedQuestion = ({ jobid,quesid}) => {
return new Promise(async (resolve, reject) => {
let statusCode
let URL = BASE_URL + '/cfpanelPlacement/delete_added_question'
let aid = cookies.get('aid')
let pid = cookies.get('pid')

Axios({
  method: 'post',
  url: URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': cookies.get('at'),
  },
  data: { jobid,quesid,
      aid },
})
  .then((res) => {
    console.log({ data: res.data })
    statusCode = res.status
    if (res.data.success) {
      console.log('savePlacementCriteria data!!', res.data)
      return resolve({ ...res.data.data, status: statusCode })
    } else {
      console.log('API call failed!', res.data)
      return resolve({ ...res.data, status: statusCode })
    }
  })
  .catch((err) => {
    console.log(err)
    return reject(err)
  })
})
}

export let fetchFilteredCandidates = ({   
  crs_pg_id,
  selectedBatches,
  job_id,
  filterData,
  rules,
  }) => {
return new Promise(async (resolve, reject) => {
  let statusCode
  let URL = BASE_URL + '/cfpanelPlacement/fetch_filtered_candidates'
  let aid = cookies.get('aid')
  let pid = cookies.get('pid')

  Axios({
    method: 'post',
    url: URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('at'),
    },
    data: { 
      crs_pg_id,
      selectedBatches,
      job_id,
      filterData,
      rules,
      aid 
    },
  })
    .then((res) => {
      console.log({ data: res.data })
      statusCode = res.status
      if (res.data.success) {
        console.log('fetch_filtered_candidates data!!', res.data)
        return resolve({ ...res.data.data, status: statusCode })
      } else {
        console.log('API call failed!', res.data)
        return resolve({ ...res.data, status: statusCode })
      }
    })
    .catch((err) => {
      console.log(err)
      return reject(err)
    })
})
}

export var changeLearnerStatus = (
  aid,
  job_id,
  job_app_id,
  selectedRound,
  remarks
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/change_learner_status',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        job_id,
        job_app_id,
        selectedRound,
        remarks
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('change_learner_status data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var sendJobApplyReminderToStudents = (
  job_id,
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/send_jobapply_reminder',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        job_id,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('change_learner_status data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var sendProfileCompletionReminderToStudents = (
  crs_pg_id,
  cid,
  reminderType
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/send_profilecompletion_reminder',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        cid,
        reminderType
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('change_learner_status data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export const uploadUserOfferLetter = (file,crs_pg_id,job_id,aid) => {
  return new Promise((resolve, reject) => {
    let fd = new FormData();
    if (file != undefined) fd.set("file", file);
    if (aid != undefined) fd.set("aid", aid);
    if (crs_pg_id != undefined) fd.set("crs_pg_id", crs_pg_id);
    if (job_id != undefined) fd.set("job_id", job_id);
    let getData = {
      url: BASE_URL + '/cfpanelPlacement/upload_offer_letter',
      method: "POST",
      headers: {
        "x-access-token": cookies.get("at"),
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      data: fd,
    };
    Axios(getData)
      .then((res) => {
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("upload_offer_letter data!!", res.data);
          return resolve({ ...res.data, status: statusCode });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export var updateOfferStatus = (
  job_id,
  aid,
  updateOfferStatus,
  ctcOffered,
  offerStatus
) => {
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/update_offer_status',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        job_id,
        student_aid:aid,
        updateOfferStatus,
        offer_status:offerStatus,
        package:ctcOffered
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('change_learner_status data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var bulkExtendAccess = (
  subArray,
  crs_pg_id,
  cid,
  formdata
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/bulk_extend_placement_access',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        subUsersArray: subArray,
        crs_pg_id,
        cid,
        formdata,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('bulk_extend_placement_access data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var revokeSingleLearnerAccess = (
  subArray,
  crs_pg_id
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/bulk_revoke_placement_access',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        subUsersArray: subArray,
        crs_pg_id,
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('bulk_revoke_placement_access data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var cloneJob = (
  job_code,
  new_opp_id,
  company_shortcode
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/clone_job',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        job_code,
        new_opp_id,
        company_shortcode
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('clone_job data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var finalizeLearners = (
  crs_pg_id,
  selectedBatches,
  eligibleArray,
  ineligibleArray,
  filterData,
  rules,
  jobid,
  eligibleCandidates,
  ineligibleCandidates,
  placedCandidates
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/finalize_learners',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        crs_pg_id,
        selectedBatches,
        eligibleArray,
        ineligibleArray,
        filterData,
        rules,
        jobid,
        eligibleCandidates,
        ineligibleCandidates,
        placedCandidates
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('finalize_learners data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var publishJob = (
  // crs_pg_id,
  jobid,
  formdata,
  selectedPeriod,
  periodVal
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/publish_job',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        // crs_pg_id,
        jobid,
        formdata,
        selectedPeriod,
        periodVal
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('publish_job data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export var updatedEndTime = (
  // crs_pg_id,
  jobid,
  formdata,
) => {
  console.log("hereeeeeeeeee")
  let status
  return new Promise((resolve, reject) => {
    return Axios({
      method: 'POST',
      url: BASE_URL + '/cfpanelPlacement/update_application_endtime',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        req_aid: cookies.get('aid'),
        // crs_pg_id,
        jobid,
        formdata
      },
    })
      .then((res) => {
        status = res.status
        return res.data
      })
      .then((data) => {
        console.log({ resData: data })
        if (data.success) {
          console.log('update_application_endtime data!!', data)
          return resolve({ ...data, status })
        } else {
          console.log('API call failed!', data)
          return resolve({ ...data, status })
        }
      })
      .catch((e) => {
        console.log(e)
        return resolve(e)
      })
  })
}

export let fetchPlacementCourse = (crs_pg_id, crs_id) => {
  return Axios({
    method: 'POST',
    url: BASE_URL + '/cfpanelPlacement/r_cr_placement',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'x-access-token': localStorage.getItem('at')
      'x-access-token': cookies.get('at'),
      // 'x-access-token': "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjViMDZhMTBlOTNiMDI3YTcwMzg2MDAyZiIsImNsIjoiVyIsImhjb2RlIjo2NTM0NTg5NzEzNDExODY3LCJpYXQiOjE1Mjk0MTU5NTEsImV4cCI6MTUyOTYzMTk1MX0.L7GAdc4e_WrgDCZr1mU8aVLxBYUs0CeBvs-R2mjimSI"
    },
    data: {
      crs_pg_id,
      crs_id,
    },
  })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        console.log('course data', data)
        return Promise.resolve(data)
      } else {
        console.log('API call failed!', data)
        return Promise.resolve(data)
      }
    })
    .catch((e) => {
      console.log(e)
      return Promise.resolve(e)
      // dispatch(errorFetchingData())
    })
}

export const getPlacementProfilePreview = (crs_pg_id,student_aid) => {
  // let aid = cookies.get("aid");
  console.log({ crs_pg_id });
  return new Promise(async (resolve, reject) => {
    let statusCode;
    // if (cookies.at || cookies.aid) headers["cookies"] = cookies;
    Axios({
      method: "post",
      url: BASE_URL + '/cfpanelPlacement/get_learnerprofile_preview',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        student_aid,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res);
        }
        console.log("getPlacementProfile::");
        console.log({ data: res.data });
        statusCode = res.status;
        if (res.data.success) {
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
  
  