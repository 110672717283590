import React, { useEffect, useState } from 'react'
import './LabsManagement.css'
import TopBar from './Components/TopBar'
import Sidebar from './Components/Sidebar'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { LABS_REQUEST_STATUS } from '../../constants/constant'
import { Loader } from 'semantic-ui-react'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import moment from 'moment'

const configs = require('../../config/configs.js')

const LabsManagement = () => {
  const [requests, setRequests] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getRequests = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          page,
          rowsPerPage,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getAllLabsRequests`,
          data,
          config
        )

        if (res.data.data) {
          setRequests(res.data.data.labsRequests)
          setTotalCount(parseInt(res.data.data.totalLabsRequest))
        }
      } catch (error) {
        console.error(error.message)
      }
      setIsLoading(false)
    }
    getRequests()
  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={1} />
      <div className="labs-main">
        <TopBar />
        <div className="labs-content">
          <Row>
            <Col md={8} className="labs-table-header">
              Labs Management
            </Col>

            <Col md={2}>
              <button
                onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                className="labs-table-btn-2"
              >
                Refresh
              </button>
            </Col>
            <Col md={2}>
              <Link to="/labs-management/createLabs">
                <button className="labs-table-btn-1">+ Create</button>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="labs-table-req-head">
                  <TableCell>Lab Name</TableCell>
                  <TableCell>Start Time </TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Creation Time</TableCell>
                  <TableCell>Deletion Time</TableCell>
                  {/* <TableCell>Server</TableCell> */}
                  {/* <TableCell>Region</TableCell> */}
                  {/* <TableCell>Ram</TableCell> */}
                  {/* <TableCell>Disk Size</TableCell> */}
                  {/* <TableCell>vCPU</TableCell> */}
                  <TableCell>Image</TableCell>
                  <TableCell>Machines</TableCell>
                  <TableCell>Time Zone</TableCell>
                  {/* <TableCell>TimeOut </TableCell> */}
                  <TableCell>Status</TableCell>
                </TableHead>
                <tbody>
                  {requests
                    ? requests.map((elem, i) => {
                        return (
                          <tr key={i} className="labs-table-req-body">
                            <td>
                              <Link to={`/labs-management/${elem._id}`}>
                                {elem.lab_name}
                              </Link>
                            </td>
                            <td>{minToTime(elem.activeperiod_start_time)}</td>
                            <td>{minToTime(elem.activeperiod_end_time)}</td>

                            <td>
                              {moment
                                .utc(elem.start_time)
                                .tz(elem.timezone)
                                .format('YYYY-MM-DD, HH:mm:ss')}
                            </td>
                            <td>
                              {moment
                                .utc(elem.end_time)
                                .tz(elem.timezone)
                                .format('YYYY-MM-DD, HH:mm:ss')}
                            </td>
                            {/* <td>{LABS_SERVER[elem.cloud_provider]}</td> */}
                            {/* <td>{elem.region}</td> */}
                            {/* <td>{elem.ram}</td> */}
                            {/* <td>{elem.disk_size && elem.disk_size + 'gb'}</td> */}
                            {/* <td>{elem.vcpu && elem.vcpu + 'vcpu'}</td> */}
                            <td>{elem.imageName}</td>
                            <td>{elem.no_of_machine}</td>
                            <td>{elem.timezone}</td>
                            {/* <td>{elem.time_out} min.</td> */}
                            <td>
                              <span
                                className={`labs-${
                                  LABS_REQUEST_STATUS[elem.status]
                                }`}
                              >
                                {LABS_REQUEST_STATUS[elem.status]}
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </Table>
            </TableContainer>

            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LabsManagement
