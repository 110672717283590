import React, { useEffect, useState } from 'react'
import TopBar2 from './Components/TopBar2'
import Select from "react-select"
import Sidebar2 from './Components/Sidebar2'
import { connect } from 'react-redux'   
import './PlacementPortal.scss'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    ModalBody,
    Label,
    FormGroup,
  } from 'reactstrap'
import { COUNTRY_CODES } from "../../constants/constant";
import MiniHeader from './Components/MiniHeader'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import { history } from '../..'  
const configs = require('../../config/configs.js')

const PreviewJobDescription = (props) => {
  const [requests, setRequests] = useState([])
  const [selectedlocationDropdown, setSelectedLocationDropdown] = useState('')
  const [locationDropdown, setLocationDropdown] = useState([])
  const [questions, setQuestions] = useState([])
  const [jobDescData, setJobDescData] = useState({})
  const [createdJobDescData, setCreatedJobDescData] = useState({})
  const [createdJob, setCreatedJob] = useState({})
  const [createdJobCriteriaData, setCreatedJobCriteriaData] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [companyShortCode, setCompanyShortCode] = useState('')
  const [allLocations, setAllLocations] = useState([])
  const [placementCriteria, setPlacementCriteria] = useState({})
  const [jobDomain, setJobDomain] = useState('')
  const [jobType, setJobType] = useState('')
  const [jobMode, setJobMode] = useState('')
  const [workex, setWorkex] = useState([])
  const [interviewMode, setInterviewMode] = useState('')
  const [jobLocationNames, setJobLocationNames] = useState([])
  const [rolesData, setRolesData] = useState('')
  const [addInfoData,setAddInfoData] = useState('')
  const [locationId, setLocationId] = useState('') 
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [openJobCreate, setOpenJobCreate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [addedSkills, setAddedSkills] = useState([])  
  const [reviewStatuses, setReviewStatuses] = useState(['ALL','UNDER_REVIEW',
    'APPROVED',
    'REJECTED',
    'FLAGGED',])
  const [jobStatus, setJobStatus] = useState('ALL')   
  const [jobStatuses, setJobStatuses] = useState(['ALL','PENDING',
    'NOT_PUBLISHED',
    'LIVE',
    'HIRING_COMPLETE',
    'CANCELLED',]) 
  const [data, setData] = useState([])
  const [selectedSearchOptions,setSelectedSearchOptions] = useState('')
  const pathname = window.location.pathname
  const jobid = pathname.split('/').slice(-1)[0]
  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getAllData = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${
            configs.routes.BASE_URL_DEVELOPMENT
          }/cfpanelPlacement/get_preview_job_description`,
          method: 'POST',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          data: { jobid },
        }
        Axios(getData)
          .then((res) => {
            let result = []
            if(res.data.data){
              let allQuestions = res.data.data.allQuestions
              setQuestions(allQuestions)
              let jobDescFields = res.data.data.jobDescFields
              let createdJobDescData = res.data?.data?.alreadyCreatedJobDescription?.[0] ?? {};

              let jobDomain = jobDescFields.domainList[createdJobDescData?.selected_domain?.length > 0 ? createdJobDescData.selected_domain[0] : 0]?.label ?? 'Unknown';

              let jobType = jobDescFields.jobTypeList[createdJobDescData?.selected_job_type?.length > 0 ? createdJobDescData.selected_job_type[0] : 0]?.label ?? 'Unknown';

              let jobMode = jobDescFields.modeList[createdJobDescData?.selected_mode?.length > 0 ? createdJobDescData.selected_mode[0] : 0]?.label ?? 'Unknown';

              let interviewMode = jobDescFields.interviewModeList[createdJobDescData?.interview_mode?.length > 0 ? createdJobDescData.interview_mode[0] : 0]?.label ?? 'Unknown';

              const locations = createdJobDescData?.job_location?.map((locationId) => {
                const locationIndex = createdJobDescData?.interview_mode?.length > 0 ? createdJobDescData.interview_mode[0] : 0;
                return jobDescFields.jobLocationList[locationId]?.label ?? 'Unknown';
              }) ?? [];
              let placementCriteria = res.data.data.placementCriteria;
              let createdCriteria = res.data.data.alreadyCreatedCriteria.length > 0
              ? res.data.data.alreadyCreatedCriteria[0]
              : {}; 

              if (createdCriteria) {
                const workex = createdCriteria.selected_experience.map((locationId, index) => {
                  return placementCriteria.experienceCriteria[locationId]?.label || "";
                });

                setWorkex(workex)
                setAddInfoData(createdCriteria.additional_info)
              }
              
              setRolesData(createdJobDescData?.roles_info ?? []);
              setJobType(jobType)
              setJobMode(jobMode)
              setJobDomain(jobDomain)
              setInterviewMode(interviewMode)
              setJobDescData(jobDescFields)
              setJobLocationNames(locations)
              
              setCreatedJobDescData(createdJobDescData)
              setCreatedJobCriteriaData(createdCriteria)
              setCreatedJob(res.data.data.job)
              let allLocations = res.data.data.allLocations
              setAllLocations(allLocations)
              setAddedSkills(res.data.data.addedSkillTags)
              setPlacementCriteria(res.data.data.placementCriteria)
            }
            console.log({jobDescData:jobDescData})
            // setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllData()
    
    const paragraph = document.querySelector('p[data-f-id="pbf"]');
    if (paragraph) {
      paragraph.style.opacity = '0';
    }
  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleModalClose = () => {

  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const dropdownlocationChanged = (locationName, locationId) => {
    setSelectedLocationDropdown(locationName)
    // setLocationId(locationId)
  }
  const jobStatusChanged = (jobStatus) => {
    setJobStatus(jobStatus)
  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  const checkVisibility = (resource,resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal'>
    <>
      
      <div className="jobs-main">
        <TopBar2
        heading={'Create a Job'}
        />
        <div>
          <Sidebar2 active={4} jobid={jobid}/>
        </div>
        <div>
        <div className="jobs-header">
          <Row style={{ height: '30px'}}>
          <MiniHeader  breadCrumbs={[
            { name: "Career Services", link: "/career-services" },
            { name: "Job Postings", link: "/career-services" },
            ]}
            active={1}
            />
          </Row>
        </div>
          <div className="jobs-content">
            <div className="adques-all">
              <div className="adques-header">
                <Col md={9} className="jobs-table-header-2">
                  <p style={{ marginBottom : '10px' }}>Job Preview</p>
                </Col>
              </div>
              <li className='row'>
                <div className="">
                  <div style={{ width: '150px', height: '150px', border: '1px solid #ddd', marginLeft: '30px', marginBottom: '1rem'}}>
                    <img src={createdJobDescData.company_logo ? createdJobDescData.company_logo : "https://webcdn.imarticus.org/Placement/Suitcase.svg"} alt="Company Logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </div>
                </div>
                <div className="companyTopRow">
                  <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                      {createdJobDescData.job_title}
                  </h5>
                  <p style={{ marginTop: '3rem' }}>{createdJob.company_name} | {allLocations.find(location => location._id === createdJobCriteriaData.base_location)?.name}  </p>
                  <a className ="companyWebsite" href={createdJobDescData.company_website}>{createdJobDescData.company_website}</a>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Skills :</p>
                </div>
                <div className="previewTagResponse">
                <div style={{ display: 'flex' }}>
                  {addedSkills.map((skill, index) => (
                    <p key={index} className="skillTagDisplay" style={{ marginRight: '10px' }}>{skill.name}</p>
                  ))}
                </div>
                </div>
              </li>
              {createdJobDescData.ctc_type === 1 ? (
                  <li className="row">
                    <div className="previewTag">
                      <p>Salary Range :</p>
                    </div>
                    <div className="previewTagResponse">
                      <div className="row" style={{ marginLeft: '0px' }}>
                        <span>&#8377;</span><p>{createdJobDescData.ctc_range_min}</p><p>-</p><span>&#8377;</span><p>{createdJobDescData.ctc_range_max}</p>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="row">
                    <div className="previewTag">
                      <p>Salary Package :</p>
                    </div>
                    <div className="previewTagResponse">
                      <div className="row" style={{ marginLeft: '0px' }}>
                        <span>&#8377;</span><p>{createdJobDescData.ctc_amount}</p>
                      </div>
                    </div>
                  </li>
                )}

              <li className="row">
                <div className = "previewTag">
                  <p>Domain :</p>     
                </div>
                <div className = "previewTagResponse">
                  <p>{jobDomain}</p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Job Type :</p>
                </div>
                <div className = "previewTagResponse">
                  <p>{jobType}</p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Job Mode :</p>
                </div>
                <div className = "previewTagResponse">
                  <p>{jobMode}</p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Job Location :</p>
                </div>
                <div className = "previewTagResponse">
                  <div className = "row" style={{ marginLeft: '0px' }}>
                  {jobLocationNames.map((location, index) => (
                    <React.Fragment key={index}>
                      <p>{location}</p>{index < jobLocationNames.length - 1 && <p>,</p>}
                    </React.Fragment>
                  ))}
                  </div>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Work Experience :</p>
                </div>
                <div className = "previewTagResponse">
                  <div className = "row" style={{ marginLeft: '0px' }}>
                  {workex.map((location, index) => (
                      <React.Fragment key={index}>
                        <p>{location}</p>{index < workex.length - 1 && <p>,</p>}
                      </React.Fragment>
                    ))}
                    </div>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Mode of Interview :</p>
                </div>
                <div className = "previewTagResponse">
                  <p>{interviewMode}</p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Roles & Responsibilities :</p>
                </div>
                <div className = "previewTagResponse2 resizeHTML">
                <p dangerouslySetInnerHTML={{ __html: rolesData }}></p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTag">
                  <p>Additional Information :</p>
                </div>
              </li>
              <li className="row">
                <div className = "previewTagResponse2 resizeHTML">
                  <p dangerouslySetInnerHTML={{ __html: addInfoData }}></p>
                </div>
              </li>
            </div>
            <div className="adques-all" style={{ marginTop : '20px' }}>
              <div className="adques-header">
                <Col md={8} className="jobs-table-header-2">
                  <p style={{ marginBottom : '10px' }}>Additional Questions</p>
                </Col>
              </div>
              {questions.map((question, index) => (
                  <li key={index} className='row'>
                    <div className="col-md-12 previewques">
                      <h5 className="modal-title" htmlFor="thumbImg" style={{ marginTop: '1rem' }}>
                        {question.text}
                      </h5>
                      {question.type === 2 ? (
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter your answer here"
                          style={{ width: '70%' }}
                          // value={...}
                          // onChange={...}
                        />
                      ) : (
                      <FormControl component="fieldset" className='ctc-radio' style={{ width: '100%'}}>
                        <RadioGroup
                          aria-label="ctcType"
                          value={0}
                          // onChange={handleCTCTypeChange}
                        >
                          {question.ans_options.map((option, optionIndex) => (
                            <FormControlLabel
                              key={optionIndex}
                              className="col-md-6 radiotext"
                              value={option.id}
                              control={<Radio />}
                              label={option.optiondata}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                    </div>
                  </li>
                ))}

              <div className='addcrit-footer'>
                <Row className="adques-footer footer-justify-content">
                  {/* <Col md={2} className="jobs-table-header-2">
                  </Col>
                  <Col md={5}></Col>
                  <Col md={1}>
                  </Col> */}
                  <Col md={4}> 
                  {checkVisibility('43', 2) && (
                  <Button
                    className="jobs-table-btn-5"
                    onClick={ () => {
                      history.push(`/career-module/job-rollout-filters/${jobid}`)
                    }}
                  >
                    Add Job Rollout Filter & Publish
                  </Button>
                  )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    </div>
  )

}


const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(PreviewJobDescription)